import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ImageManagerControl } from 'components/Controls'
import {
  profileEmployerCompanyBannerUploadPA,
  profileEmployerCompanyBannerRemovePA,
} from 'features/profile/store'
import { useUser } from 'features/user'
import { useNotify, useAsyncDispatch } from 'hooks'

export const ProfileBannerManager = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { showNotification } = useNotify()

  const { loading: uploading, onAsyncDispatch: onUploadDispatch } = useAsyncDispatch()

  const onUpload = async file => {
    try {
      const data = {
        company_banner: file,
      }

      await onUploadDispatch(profileEmployerCompanyBannerUploadPA.request, data)

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const { loading: removing, onAsyncDispatch: onRemoveDispatch } = useAsyncDispatch()

  const onRemove = async () => {
    try {
      await onRemoveDispatch(profileEmployerCompanyBannerRemovePA.request)
      showNotification({
        type: 'success',
        message: t('deleted_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <ImageManagerControl
      image={user?.employer_profile?.company_banner?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
      uploadLabel={t('upload_banner')}
      deleteLabel={t('delete_banner')}
    />
  )
}

ProfileBannerManager.propTypes = {}
