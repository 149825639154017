import { all, fork } from 'redux-saga/effects'

import LayoutSaga from './layout/saga'
import { authSaga } from 'features/auth/store/sagas'
import { userSaga } from 'features/user/store/sagas'
import { panelSaga } from 'features/panel/store/sagas'
import { staticSaga } from 'features/static/store/sagas'
import { profileSaga } from 'features/profile/store/sagas'
import { jobSaga } from 'features/job/store/sagas'
import { candidateSaga } from 'features/candidate/store/sagas'
import { employerSaga } from 'features/employer/store/sagas'
import { jobInviteSaga } from 'features/job-invite/store/sagas'
import { billingSaga } from 'features/billing/store/sagas'
import { mentorSaga } from 'features/mentor/store/sagas'
import { immigrateSaga } from 'features/immigrate/store/sagas'

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(authSaga),
    fork(userSaga),
    fork(panelSaga),
    fork(staticSaga),
    fork(profileSaga),
    fork(jobSaga),
    fork(candidateSaga),
    fork(employerSaga),
    fork(jobInviteSaga),
    fork(billingSaga),
    fork(mentorSaga),
    fork(immigrateSaga),
  ])
}
