import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { MegaTagTitle } from 'components/Elements'
import { CompleteProfileContainer } from 'features/profile/components/CompleteProfile'
import { useUser } from 'features/user'
import { Navigate } from 'react-router-dom'

export const ProfileComplete = () => {
  const { t } = useTranslation()

  const { isProfileComplete } = useUser()

  if (isProfileComplete()) {
    return <Navigate to={'/jobs'} />
  }

  return (
    <>
      <MegaTagTitle title={'create_profile'} />

      <div className="basic-pages">
        <Container>
          <Row className="justify-content-center h-100">
            <Col>
              <CompleteProfileContainer />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

ProfileComplete.propTypes = {}
