import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { MentorDetailsContainer } from 'features/mentor/components/Mentor/MentorDetails'

export const Mentor = props => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'mentor'} />

      <PageContent>
        <PageHeader back />

        <MentorDetailsContainer />
      </PageContent>
    </>
  )
}

Mentor.propTypes = {}
