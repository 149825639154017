import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAsyncDispatch, useNotify } from 'hooks'
import { jobSavedSubmitPA } from 'features/job/store'
import { Anchor } from 'components/Elements'
import classNames from 'classnames'

export const SaveControl = ({ id, saved = false, className = '', onSuccess }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async e => {
    e.preventDefault()

    try {
      const newStatus = !saved

      const data = await onAsyncDispatch(jobSavedSubmitPA.request, { id, saved: newStatus })

      onSuccess && onSuccess({ ...data })

      const message = newStatus ? t('job_saved_successfully') : t('job_removed_from_saved')
      showNotification({
        type: 'success',
        message: message,
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <Anchor
      to={`#`}
      className={className}
      disabled={loading}
      onClick={onSubmit}
      leftIcon={
        <i
          className={classNames('fa-bookmark', {
            fas: saved,
            far: !saved,
          })}
        />
      }
    >
      {t('save')}
    </Anchor>
  )
}

SaveControl.propTypes = {
  id: PropTypes.number,
  saved: PropTypes.bool,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
}
