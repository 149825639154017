import { combineReducers } from 'redux'

import data from './data/slice'
import employers from './employers/reducers'
import employer from './employer/reducers'

const employerReducer = combineReducers({
  data,
  employers,
  employer,
})

export default employerReducer
