import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from 'components/Elements'
import { ResetPasswordContainer } from 'features/auth/components'

export const ResetPassword = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'reset_password'} />

      <ResetPasswordContainer />
    </>
  )
}
