import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Table } from './Table'
import { JobInfo } from './JobInfo'
import { JobAction } from './JobAction'
import { JobPayRangeDisplay } from 'features/job/components/Elements'

export const JobsTable = ({ keys = [], items, ...props }) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState([])

  const columnConfig = {
    title: {
      accessorKey: 'title',
      id: 'title',
      header: t('job_title'),
      cell: info => <JobInfo data={info.row.original} />,
      __headerProps: {
        style: {
          width: '500px',
        },
      },
    },
    'pay-range': {
      accessorKey: 'pay_range',
      id: 'pay_range',
      header: t('pay_range'),
      cell: info => (
        <JobPayRangeDisplay
          data={info.row.original}
          className={'font-size-14 fw-medium text-nowrap'}
        />
      ),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: info => <JobAction data={info.row.original} onPostSuccess={props.onPostSuccess} />,
    },
  }

  useEffect(() => {
    let config = []

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <Table data={items} columns={columns} />
    </>
  )
}

JobsTable.propTypes = {
  keys: PropTypes.array,
  items: PropTypes.array,
  onPostSuccess: PropTypes.func,
}
