import React from 'react'
import { AuthLayout } from 'components/Layouts'
import { AuthGuard } from 'routes/components'
import {
  Login,
  RegisterStudent,
  RegisterEmployer,
  RegisterMentor,
  ForgotPassword,
  ResetPassword,
} from 'features/auth/routes'

//config
export const getAuthRoutes = isLoggedIn => {
  return [
    {
      path: '/*',
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        // {
        //   index: !isLoggedIn,
        //   element: 'Home page',
        // },
        {
          element: <AuthLayout />,
          children: [
            {
              path: `login`,
              element: <Login />,
            },
            {
              path: `register/student`,
              element: <RegisterStudent />,
            },
            {
              path: `register/employer`,
              element: <RegisterEmployer />,
            },
            {
              path: `register/mentor`,
              element: <RegisterMentor />,
            },
            {
              path: `forgot-password`,
              element: <ForgotPassword />,
            },
            {
              path: `reset-password/:token`,
              element: <ResetPassword />,
            },
          ],
        },
      ],
    },
  ]
}
