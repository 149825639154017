import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { Anchor, AvatarProvider } from 'components/Elements'

export const TitleBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { id, employer_profile } = data
  const { company_logo, company_name, industry } = employer_profile

  return (
    <div className={'d-flex'}>
      {company_logo && (
        <div className={'me-2'}>
          <Anchor to={`/employers/${id}`}>
            <AvatarProvider variant={'logo'} avatar={company_logo} />
          </Anchor>
        </div>
      )}

      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap gap-1'}>
          <h6 className={'mb-0'}>
            <Anchor
              to={`/employers/${id}`}
              color={'dark'}
              className={'text-decoration-underline fw-medium'}
            >
              {company_name}
            </Anchor>
          </h6>
        </div>
        {industry && (
          <div>
            <p className={'font-size-14 mb-0 fw-medium'}>{industry?.value}</p>
          </div>
        )}
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
}
