import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import { AvatarProvider } from 'components/Elements'
import s from './General.module.scss'

export const General = () => {
  const { t } = useTranslation()

  const { user, getName } = useUser()

  const {
    profile_status,
    profile_search_status,
    school,
    profile_study_program,
    profile_study_area,
    location_city,
    location_province,
  } = user?.student_profile

  const renderInfo = () => {
    let schoolValue = school?.value

    let study = ''

    if (profile_study_program) {
      study = profile_study_program?.value
    }

    if (profile_study_area) {
      let area = profile_study_area?.value
      if (profile_study_program) {
        area = `: ${area}`
      }
      study += area
    }

    let location =
      location_city && location_province
        ? `${location_city?.value}, ${location_province?.value}`
        : null

    let result = ''

    if (schoolValue) {
      result = `${schoolValue} · `
    }

    if (study) {
      result += `${study} · `
    }

    if (location) {
      result += location
    }

    return result ? <p className={'text-muted mb-0 fw-light'}>{result}</p> : null
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className={s.block}>
            <div className={s.avatarWrap}>
              <AvatarProvider avatar={user?.avatar} size={'large'} />
            </div>
            <div>
              <div>
                <h4 className={'mb-0'}>{getName()}</h4>
                {profile_status && <p className={'font-size-18 mb-1'}>{profile_status?.value}</p>}

                {profile_search_status && (
                  <p className={'font-size-18 mb-2'}>{profile_search_status?.value}</p>
                )}

                {renderInfo()}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

General.propTypes = {}
