import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DataShower } from 'components/Elements'
import { employerData } from 'features/employer/store'
import { EmployerDetailsContent } from './EmployerDetailsContent'
import { GeneralJobsContainer } from 'features/employer/components/EmployerJobs/BrowseJobs'

const EmployerDetailsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(employerData.getData({ id }))

    return () => {
      dispatch(employerData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFetched={!!props.data}
        isFailed={!!props.error}
        error={props.error}
      >
        <EmployerDetailsContent />

        <GeneralJobsContainer />
      </DataShower>
    </>
  )
}

EmployerDetailsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.employer.data
  return {
    data,
    loading,
    error,
  }
}

export const EmployerDetailsContainer = connect(mapStateToProps)(EmployerDetailsContainerComponent)
