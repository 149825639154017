import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'

import s from './Table.module.scss'
import { tariffHelper } from 'features/billing/helpers'
import { Tariff } from './Tariff'
import { TariffFeature } from './TariffFeature'

const tariffs = tariffHelper.general.getTariffs()
const features = tariffHelper.feature.getFeatures()

export const TariffsTable = ({ currentSubscription }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="table-responsive">
        <Table className={`align-middle table-striped ${s.tariffTable}`}>
          <thead>
            <tr>
              <th />
              {tariffs.map((item, i) => (
                <th key={i}>
                  <Tariff tariff={item} current={item.alias === currentSubscription} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, i) => (
              <tr key={i}>
                <td>{feature?.name}</td>

                {tariffs.map((item, j) => (
                  <td key={j}>
                    <TariffFeature value={item.features[feature.key]} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

TariffsTable.propTypes = {
  currentSubscription: PropTypes.string,
}
