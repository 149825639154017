import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMountedState } from 'react-use'
import { AvatarManagerControl } from 'features/profile/components/Controls'
import { profileAvatarUploadPA, profileAvatarRemovePA } from 'features/profile/store'
import { useUser } from 'features/user'
import { useNotify } from 'hooks'

export const ProfileAvatarManager = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMounted = useMountedState()

  const { user } = useUser()
  const { showNotification } = useNotify()

  const [uploading, setUploading] = useState(false)
  const [removing, setRemoving] = useState(false)

  const setUploadingStatus = status => {
    if (isMounted()) {
      setUploading(status)
    }
  }

  const onUpload = async file => {
    setUploadingStatus(true)

    try {
      const data = {
        avatar: file,
      }
      await dispatch(profileAvatarUploadPA.request(data))
      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
      setUploadingStatus(false)
    } catch (error) {
      setUploadingStatus(false)
      const message = error?.message || t('error')

      showNotification({
        type: 'error',
        message: message,
      })
    }
  }

  const setRemovingStatus = status => {
    if (isMounted()) {
      setRemoving(status)
    }
  }

  const onRemove = async () => {
    setRemovingStatus(true)

    try {
      await dispatch(profileAvatarRemovePA.request())
      showNotification({
        type: 'success',
        message: t('deleted_successfully'),
      })
      setRemovingStatus(false)
    } catch (error) {
      setRemovingStatus(false)
      const message = error?.message || t('error')

      showNotification({
        type: 'error',
        message: message,
      })
    }
  }

  return (
    <AvatarManagerControl
      image={user?.avatar?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
    />
  )
}

ProfileAvatarManager.propTypes = {}
