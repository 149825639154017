export const EXTERNAL_LINK = {
  SLACK_COMMUNITY:
    'https://join.slack.com/t/empowercareers/shared_invite/zt-koyilqup-ucdVfDjviRxc5PJKXPHj5A',
  MENTORS:
    'https://isempower.notion.site/isempower/6bcedbf8ed364825b0db3f0dce37add8?v=5d55f9a9dd134b809f02559c7c3a2a79',
  RESOURCES: 'https://isempower.com/student-resources/',
  BOOTCAMP: 'http://isempower.com/bootcamp/',
  ONLINE_COURSES: 'https://learn.isempower.com/pages/isempower-online-courses',
  BOOKING_CALL: 'https://meetings.hubspot.com/pat-chaisang/demo',
  SCHEDULE_LINK_EXAMPLE: 'https://www.trycoffeechats.com/',
}
