import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import s from './Work.module.scss'
import { WorkBlock } from 'features/student/components/Profile/InfoBlocks'
import { Attachments } from './Attachments'

export const Work = () => {
  const { t } = useTranslation()

  const { user, isMentor } = useUser()

  return (
    <>
      <Card>
        <CardBody>
          <Attachments data={user?.student_profile} />

          <div className={'py-3'} />

          <WorkBlock data={user?.student_profile} asMentor={isMentor()} />
        </CardBody>
      </Card>
    </>
  )
}

Work.propTypes = {}
