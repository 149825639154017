import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EditForm } from './EditForm'
import { profileStudentUpdatePA } from '../../../store'
import { useNotify } from 'hooks'
import { ProfileAvatarManager } from 'features/profile/components/ProfileAvatarManager'
import { useUser } from 'features/user'

export const StudentProfileEdit = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const { isMentor } = useUser()

  const onSubmit = async values => {
    await dispatch(profileStudentUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    navigate('/profile')
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          {!isMentor() && (
            <div className={'mb-3'}>
              <ProfileAvatarManager />
            </div>
          )}

          <EditForm onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  )
}

StudentProfileEdit.propTypes = {}
