import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { FormFieldController, Combobox } from 'components/FormElements'
import { useFetch } from 'hooks'
import { staticAPI } from 'features/static/api'

export const StudentProfileFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data, fetch } = useFetch()

  useEffect(() => {
    const request = staticAPI.getProfileSelects
    fetch(request, { includes: ['profile_statuses'] })
  }, [])

  const profileStatuses = useMemo(() => {
    if (data?.data) {
      return data?.data?.profile_statuses || []
    }

    return []
  }, [data])

  return (
    <Row>
      <Col md={12}>
        <FormFieldController
          name={'student_profile.profile_status_id'}
          control={control}
          defaultValue={null}
          component={Combobox}
          id={'student_profile.profile_status_id'}
          label={t('status')}
          placeholder={t('status')}
          options={profileStatuses}
          isSearchable={false}
          isDetermineValue
          normalize={option => (option !== null ? option.id : null)}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.id}
          maxMenuHeight={200}
          // menuPortalTarget={document.querySelector('body')}
        />
      </Col>
    </Row>
  )
}
