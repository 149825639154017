import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import { BasicInfoBlock } from 'features/student/components/Profile/InfoBlocks'
import { MentorInfo } from '../MentorInfo'

export const Basic = () => {
  const { t } = useTranslation()

  const { user, isMentor } = useUser()

  return (
    <>
      <Card>
        <CardBody>
          {isMentor() && (
            <div className={'mb-4'}>
              <MentorInfo data={{ ...user?.student_profile }} />
            </div>
          )}

          <BasicInfoBlock data={{ ...user?.student_profile }} />
        </CardBody>
      </Card>
    </>
  )
}

Basic.propTypes = {}
