import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle, DataShower } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { jobData } from 'features/job/store'
import { Header, Sidebar } from 'features/job/components/Job/JobDetails/Layout'
import { JobDetailsContent } from 'features/job/components/Job/JobDetails'

export const JobComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(jobData.getData({ id }))

    return () => {
      dispatch(jobData.cleanState({}))
    }
  }, [])

  return (
    <>
      <MegaTagTitle title={'job'} />

      <PageContent>
        <PageHeader back headerRight={props.data && <Header />} />

        <DataShower
          isFetched={!!props.data}
          isFailed={!!props.error}
          isLoading={props.loading}
          error={props.error}
        >
          <Row>
            <Col lg={8}>
              <JobDetailsContent />
            </Col>

            <Col lg={4}>
              <Sidebar />
            </Col>
          </Row>
        </DataShower>
      </PageContent>
    </>
  )
}

JobComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.job.data
  return {
    data,
    loading,
    error,
  }
}

export const Job = connect(mapStateToProps)(JobComponent)
