import { createPromiseAction } from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobInvitesAPI } from 'features/job-invite/api'
import {
  JOB_INVITE_INVITES_RECEIVED_SEE_REQUEST,
  JOB_INVITE_INVITES_RECEIVED_SEE_SUCCESS,
  JOB_INVITE_INVITES_RECEIVED_SEE_FAILED,
} from './actionTypes'

export const jobInviteInvitesReceivedSeePA = createPromiseAction(
  JOB_INVITE_INVITES_RECEIVED_SEE_REQUEST,
  JOB_INVITE_INVITES_RECEIVED_SEE_SUCCESS,
  JOB_INVITE_INVITES_RECEIVED_SEE_FAILED
)()

export function* seeReceivedInvites() {
  try {
    const response = yield call(jobInvitesAPI.seeAllReceivedInvites)

    const { data } = response

    yield put(jobInviteInvitesReceivedSeePA.success({}))

    return true
    // resolvePromiseAction(action, {})
  } catch (error) {
    throw error
    // rejectPromiseAction(action, error.response.data)
  }
}

export function* seeSaga() {
  yield takeEvery(jobInviteInvitesReceivedSeePA.request, seeReceivedInvites)
}
