import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Anchor, Modal } from 'components/Elements'
import { usePopup } from 'hooks'
import { JobApplicationCreate } from 'features/job/components/JobApplication'

export const ApplyControl = ({ applied = false, className = '', onSuccess, job }) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    close()

    onSuccess(data)
  }

  const onApply = e => {
    e.preventDefault()

    open()
  }

  return (
    <>
      <div>
        <Anchor to={`#`} className={className} disabled={applied} onClick={onApply}>
          {applied ? t('applied') : t('apply_now')}
        </Anchor>
      </div>

      <Modal
        visible={visible}
        centered
        onClose={close}
        title={t('please_confirm_your_info_up_to_date')}
        size={'lg'}
      >
        <JobApplicationCreate job={job} onSuccess={onSubmitSuccess} />
      </Modal>
    </>
  )
}

ApplyControl.propTypes = {
  invited: PropTypes.bool,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  job: PropTypes.object,
}
