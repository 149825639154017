import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useUser } from 'features/user/hooks'
import { Info } from './Info'

export const PersonalInfoBlock = () => {
  const { t } = useTranslation()

  const { user, getName } = useUser()

  const { avatar, student_profile } = user

  return (
    <>
      <p className={'mb-0 font-size-18 fw-normal'}>{t('personal_info')}</p>

      <div className={'mt-3'}>
        <Info name={getName()} avatar={avatar} profile={student_profile} />
      </div>
    </>
  )
}

PersonalInfoBlock.propTypes = {}
