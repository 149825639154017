import React from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useUser } from 'features/user'
import s from './SidebarContent.module.scss'
import { NavLink } from './NavLink'
import { APP_NAME } from 'consts/core'
import { BasicMenu } from './BasicMenu'
import { Menu } from './Menu'

export const SidebarContent = () => {
  const { t } = useTranslation()

  const { isStudent } = useUser()

  return (
    <div className={s.wrapper}>
      <PerfectScrollbar>
        <div className={'d-flex flex-column'}>{isStudent() ? <Menu /> : <BasicMenu />}</div>
      </PerfectScrollbar>
      <div className={s.navBottomBlock}>
        <div className={''}>
          <NavLink path={'/help'} icon={'bx bx-help-circle'} label={t('get_help')} />
        </div>
        <div className={s.footer}>
          {t('copyright')} {APP_NAME} {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}
