import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Candidate } from '../candidate'

export const CandidateRoot = () => {
  return (
    <Routes>
      <Route index element={<Candidate />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

CandidateRoot.propTypes = {}
