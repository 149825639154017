import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import s from './CandidateDetailsContent.module.scss'
import { TitleBlock } from './TitleBlock'
import { PropertiesBlock } from '../InfoBlocks'
import { ContactsBlock } from './ContactsBlock'
import { AttachmentsBlock } from './AttachmentsBlock'
import {
  BasicInfoBlock,
  EducationBlock,
  WorkBlock,
  SelfIdentificationBlock,
} from 'features/student/components/Profile/InfoBlocks'

const defaultFieldsConfig = {
  contacts: true,
  objectives: true,
  relocation: true,
  workAuthorizations: true,
}

export const CandidateDetailsContent = ({ asMentor = false, candidate = {}, fieldsConfig }) => {
  const { t } = useTranslation()

  fieldsConfig = {
    ...defaultFieldsConfig,
    ...fieldsConfig,
  }

  return (
    <Card>
      <CardBody>
        <div className={s.topBar}>
          <div className={s.titleBlock}>
            <TitleBlock data={candidate} config={fieldsConfig} />
          </div>

          {/*<div className={s.actionBar}>{null}</div>*/}
        </div>

        <AttachmentsBlock data={candidate} />

        <div className={'mt-1'}>
          <PropertiesBlock data={{ ...candidate?.student_profile }} />
        </div>

        {fieldsConfig.contacts && <ContactsBlock data={candidate} />}

        <hr />

        <div>
          <BasicInfoBlock
            data={{ ...candidate?.student_profile }}
            profileStatusLabel={profile_status => profile_status?.public_value}
            fieldsConfig={fieldsConfig}
          />
        </div>

        <div className={'pt-4'}>
          <WorkBlock data={{ ...candidate?.student_profile }} asMentor={asMentor} />
        </div>

        <div className={'pt-4 pb-4'}>
          <EducationBlock data={{ ...candidate?.student_profile }} />
        </div>

        <div className={'pt-4'}>
          <SelfIdentificationBlock data={{ ...candidate?.student_profile }} />
        </div>
      </CardBody>
    </Card>
  )
}

CandidateDetailsContent.propTypes = {
  candidate: PropTypes.object,
  asMentor: PropTypes.bool,
  fieldsConfig: PropTypes.object,
}
