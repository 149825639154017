import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './JobInfo.module.scss'
import { Anchor, Icon } from 'components/Elements'
import { DateUtils } from 'utils'
import { JOB_STATUS } from 'features/job/consts/general'
import { useUserPermissions } from 'features/user/hooks'
import { PaidJobBadge } from 'features/job/components/Elements'

const { ACTIVE } = JOB_STATUS

export const JobInfo = ({ data = {} }) => {
  const { t } = useTranslation()

  const { isJobRecommendationAllow } = useUserPermissions()

  const { id, title, created_at, posted_at, status, is_remote, job_type, profile_experience_year } =
    data

  const renderDate = () => {
    const label = status === ACTIVE ? t('posted_on') : t('created_on')
    const date = status === ACTIVE ? posted_at : created_at

    return (
      <>
        <span className={'text-muted'}>{label}:</span>{' '}
        {date && DateUtils.format(date, 'YYYY/MM/DD')}
      </>
    )
  }

  const renderSubHeader = () => {
    const date = renderDate()
    const expLevel = profile_experience_year?.value
    const jobType = job_type?.value

    return (
      <>
        {date} · {expLevel} · {jobType}
        {is_remote && ` · ${t('remote')}`}
        {/*{multiple_locations && ` · ${t('multiple_locations')}`}*/}
      </>
    )
  }

  const recommendationAllow = isJobRecommendationAllow(data)

  return (
    <div className={s.block}>
      <div className={s.header}>
        <div className={s.titleBlock}>
          <Anchor to={`/jobs/${id}`} className={'text-decoration-underline'} color={'black'}>
            <h5 className={'text-truncate mb-0'}>{title}</h5>
          </Anchor>
        </div>

        {data?.featured && <PaidJobBadge size={'xs'} />}
      </div>
      <div className={s.subHeader}>{renderSubHeader()}</div>

      {status === ACTIVE && (
        <div className={'mt-1 font-size-14'}>
          <span className={'text-muted'}>
            {t('recommended_candidates')}
            {': '}
          </span>

          {recommendationAllow ? (
            <Anchor to={`/jobs/${id}/candidates/recommendations`}>{t('view')}</Anchor>
          ) : (
            <Icon name={'blocked'} />
          )}
        </div>
      )}
    </div>
  )
}

JobInfo.propTypes = {
  data: PropTypes.object,
}
