import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { Anchor, AvatarProvider, Icon } from 'components/Elements'
import { DateUtils } from 'utils'

export const TitleBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { job, sender, created_at, seen } = data

  const { id: senderId, employer_profile } = sender
  const { company_logo, company_name } = employer_profile

  return (
    <div className={'d-flex'}>
      {company_logo && (
        <div className={'me-2'}>
          <Anchor to={`/employers/${senderId}`}>
            <AvatarProvider variant={'logo'} avatar={company_logo} />
          </Anchor>
        </div>
      )}

      <div className={'d-flex flex-column justify-content-center flex-grow-1'}>
        <div className={'d-flex flex-wrap gap-1'}>
          <p className={'mb-0'}>
            <Anchor
              to={`/employers/${senderId}`}
              color={'dark'}
              className={'text-decoration-underline fw-medium'}
            >
              {company_name}
            </Anchor>

            <span className={'text-lowercase'}>{` ${t('invited_you_to')} `}</span>

            <span className={'fw-medium'}>
              <Anchor
                to={`/jobs/${job?.id}`}
                color={'dark'}
                className={'text-decoration-underline'}
              >
                {job?.title}
              </Anchor>
            </span>
          </p>
        </div>
        <div>
          {
            <p className={'font-size-14 mb-0 fw-medium'}>
              {t('invited_on')}
              {': '}
              {DateUtils.format(created_at, 'YYYY/MM/DD')}
            </p>
          }
        </div>
      </div>

      {!seen && (
        <div className={'ms-2'}>
          <Icon className={'font-size-12 text-primary'} name={'circle'} />
        </div>
      )}
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
}
