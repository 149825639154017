import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { CheckboxField, Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const LocationFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getLocationProvinces, getGroupedLocations } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={name}
        control={control}
        component={Combobox}
        id={name}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      <Row>
        <Col sm={6} xl={4}>
          {renderSelectField({
            name: 'location_provinces',
            label: t('provinces'),
            options: getLocationProvinces(),
          })}
        </Col>

        <Col sm={6} xl={4}>
          {renderSelectField({
            name: 'location_cities',
            label: t('cities'),
            options: getGroupedLocations(),
            isGrouped: true,
          })}
        </Col>

        <Col sm={12} xl={4}>
          <div className={'d-flex flex-wrap gap-3'}>
            <FormFieldController
              name={'is_remote'}
              control={control}
              component={CheckboxField}
              id={'is_remote'}
              label={t('remote')}
              wrapClass={'mb-3 form-checkbox-control'}
            />

            <FormFieldController
              name={'multiple_locations'}
              control={control}
              component={CheckboxField}
              id={'multiple_locations'}
              label={t('multiple_locations')}
              wrapClass={'mb-3 form-checkbox-control'}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

LocationFormSection.propTypes = {}
