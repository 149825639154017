import _ from 'lodash'

import i18n from 'i18n'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'

export const getTariffs = () => {
  return [
    {
      name: i18n.t('tariff.free'),
      alias: BILLING_TARIFF_ALIAS.FREE,
      price: null,
      isTariffPlan: true,
      features: {
        candidate_search: true,
        candidate_invite: false,
        candidate_bulk_invite: false,
        candidate_recommendation: false,
        candidate_phone: false,
        post_candidate_placement_support: false,
        blind_hiring: false,
        job_posting: i18n.t('limited'),
        job_posting_assistance: false,
        targeted_email_promotion: false,
        company_profile: false,
        quarterly_nationwide_career_fair: false,
        dedicated_account_manager: false,
      },
    },
    {
      name: i18n.t('tariff.per_job'),
      alias: BILLING_TARIFF_ALIAS.PER_JOB,
      price: 399,
      isTariffPlan: false,
      features: {
        candidate_search: true,
        candidate_invite: false,
        candidate_bulk_invite: false,
        candidate_recommendation: i18n.t('job_only.count', { count: 1 }),
        candidate_phone: i18n.t('job_only.count', { count: 1 }),
        post_candidate_placement_support: false,
        blind_hiring: false,
        job_posting: i18n.t('limited'),
        job_posting_assistance: false,
        targeted_email_promotion: true,
        company_profile: false,
        quarterly_nationwide_career_fair: false,
        dedicated_account_manager: false,
      },
    },
    {
      name: i18n.t('tariff.premium'),
      alias: BILLING_TARIFF_ALIAS.PREMIUM,
      price: 500,
      isTariffPlan: true,
      features: {
        candidate_search: true,
        candidate_invite: i18n.t('unlimited'),
        candidate_bulk_invite: true,
        candidate_recommendation: i18n.t('job_per_month.count', { count: 5 }),
        candidate_phone: false,
        post_candidate_placement_support: true,
        blind_hiring: true,
        job_posting: i18n.t('unlimited'),
        job_posting_assistance: true,
        targeted_email_promotion: true,
        company_profile: true,
        quarterly_nationwide_career_fair: false,
        dedicated_account_manager: true,
      },
    },
    {
      name: i18n.t('tariff.enterprise'),
      alias: BILLING_TARIFF_ALIAS.ENTERPRISE,
      price: null,
      isTariffPlan: true,
      features: {
        candidate_search: true,
        candidate_invite: i18n.t('unlimited'),
        candidate_bulk_invite: true,
        candidate_recommendation: i18n.t('unlimited'),
        candidate_phone: true,
        post_candidate_placement_support: true,
        blind_hiring: true,
        job_posting: i18n.t('unlimited'),
        job_posting_assistance: true,
        targeted_email_promotion: true,
        company_profile: true,
        quarterly_nationwide_career_fair: true,
        dedicated_account_manager: true,
      },
    },
  ]
}

export const getTariff = alias => {
  const options = getTariffs()
  return _.find(options, { alias })
}

export const getTariffsPlans = () => {
  return getTariffs().filter(tariff => tariff.isTariffPlan)
}

export const isEnterprise = alias => {
  return alias === BILLING_TARIFF_ALIAS.ENTERPRISE
}
