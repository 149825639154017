import { createSlice } from '@reduxjs/toolkit'

import { CANDIDATES_FILTERS_INITIAL_VALUES } from 'features/candidate/consts/filters'

const initialState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,

  filters: {
    ...CANDIDATES_FILTERS_INITIAL_VALUES,
  },
}

const jobJobCandidatesRecommendationsListSlice = createSlice({
  name: 'jobJobCandidatesRecommendationsList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    changeFilter(state, action) {
      state.page = 1
      state.filters = { ...state.filters, ...action.payload }
    },
    resetFilter(state) {
      state.filters = initialState.filters
    },
    resetDataState(state) {
      return {
        ...state,
        list: [],
        loading: false,
        error: null,
        page: 1,
        meta: null,
      }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setPage
 * @property {function} updateListItem
 * @property {function} changeFilter
 * @property {function} resetFilter
 * @property {function} resetDataState
 * @property {function} cleanState
 */

export const jobJobCandidatesRecommendationsList = jobJobCandidatesRecommendationsListSlice.actions

export default jobJobCandidatesRecommendationsListSlice.reducer
