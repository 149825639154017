import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'

import { jobJobCandidatesRecommendationsList } from 'features/job/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'
import { GeneralCandidatesFilter } from 'features/candidate/components/Candidates/Filters'
import { usePanelJob } from 'features/job/hooks'
import { useUserSubscription } from 'features/user/hooks'
import { BILLING_SUBSCRIPTION_PERMISSION } from 'features/billing/consts/subscription'

const RecommendedCandidatesContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { job } = usePanelJob()

  const { isPermissionAllow } = useUserSubscription()

  useEffect(() => {
    return () => {
      dispatch(jobJobCandidatesRecommendationsList.resetDataState({}))
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { profile_certifications, ...restFilters } = props.filters

    let certificationsFilters = {}
    if (profile_certifications && profile_certifications.trim().length >= 2) {
      certificationsFilters = {
        profile_certifications: profile_certifications.trim(),
      }
    }

    let params = {
      page: props.page,
      for_job: job?.id,
      filters: {
        ...restFilters,
        ...certificationsFilters,
      },
      sort: [{ id: 'candidate_score', desc: true }],
    }

    dispatch(jobJobCandidatesRecommendationsList.getList({ id: job?.id, params }))
  }

  const onPageChange = item => {
    dispatch(jobJobCandidatesRecommendationsList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(jobJobCandidatesRecommendationsList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  const onInviteSuccess = data => {
    dispatch(jobJobCandidatesRecommendationsList.updateListItem(data))
  }

  const inviteBlocked = !isPermissionAllow(BILLING_SUBSCRIPTION_PERMISSION.JOB_INVITE)

  const advancedFilter = isPermissionAllow(
    BILLING_SUBSCRIPTION_PERMISSION.CANDIDATES_ADVANCED_SEARCH_FILTER
  )

  return (
    <>
      <h4 className="font-size-18">{t('recommended_candidates')}</h4>

      <div className={'mb-2'}>
        <GeneralCandidatesFilter
          defaultValues={props.filters}
          onFiltersChange={onFiltersChange}
          advancedFilter={advancedFilter}
        />
      </div>

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid
          items={props.list}
          jobInviteControlProps={{ onSuccess: onInviteSuccess, job, blocked: inviteBlocked }}
        />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_recommendations')}</NoDataTextMessage>
      )}
    </>
  )
}

RecommendedCandidatesContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } =
    state.job.job.candidates.recommendations.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const RecommendedCandidatesContainer = connect(mapStateToProps)(
  RecommendedCandidatesContainerComponent
)
