import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SaveControl as SaveActionControl } from 'features/job/components/JobActionControls'

export const SaveControl = ({ job = {}, onSuccess }) => {
  const { t } = useTranslation()

  const { id, saved } = job

  const onSavedSuccess = data => {
    onSuccess({ id, ...data })
  }

  return (
    <>
      <SaveActionControl
        saved={saved}
        id={id}
        onSuccess={onSavedSuccess}
        className={'font-size-14 fw-medium'}
      />
    </>
  )
}

SaveControl.propTypes = {
  job: PropTypes.object,
  onSuccess: PropTypes.func,
}
