import * as Yup from 'yup'

import { formHelper } from 'features/profile/helpers'

const {
  avatarValidation,
  basicProfileValidation,
  educationWorkValidation,
  selfIdentificationValidation,
} = formHelper.studentProfile

export const validationSchema = [
  Yup.object().shape({
    ...avatarValidation,

    student_profile: Yup.object().shape({
      ...basicProfileValidation,
    }),
  }),

  Yup.object().shape({
    student_profile: Yup.object().shape({
      ...educationWorkValidation,
    }),
  }),

  Yup.object().shape({
    student_profile: Yup.object().shape({
      ...selfIdentificationValidation,
    }),
  }),
]
