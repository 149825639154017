import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor, AvatarProvider } from 'components/Elements'
import { DateUtils } from 'utils'

export const SubBar = ({ data = {}, withApplication, withCompany, ...props }) => {
  const { t } = useTranslation()

  const {
    user,
    posted_at,
    is_remote,
    multiple_locations,
    location_province,
    location_city,
    myApplication,
  } = data
  const { employer_profile } = user || {}

  const renderCompany = () => {
    if (employer_profile) {
      const { company_name } = employer_profile

      return (
        <>
          <Anchor
            to={`/employers/${user?.id}`}
            color={'dark'}
            className={'text-decoration-underline'}
          >
            {company_name}
          </Anchor>
        </>
      )
    }
    return null
  }

  const renderDate = () => {
    return (
      <>
        <span className={'text-muted'}>{t('posted_on')}:</span>{' '}
        {posted_at && DateUtils.format(posted_at, 'YYYY/MM/DD')}
      </>
    )
  }

  const renderLocation = () => {
    const province = location_province?.value
    const city = location_city?.value

    return province && city ? (
      <>
        {' · '}
        <span className={'text-muted'}>{t('job_location')}:</span> {`${province}, ${city}`}
      </>
    ) : null
  }

  const renderApplication = () => {
    const { created_at } = myApplication

    return (
      <>
        {' · '}
        <span className={'text-muted'}>{t('applied_on')}:</span>{' '}
        {created_at && DateUtils.format(created_at, 'YYYY/MM/DD')}
      </>
    )
  }

  return (
    <div>
      <span className={'font-size-14'}>
        {withCompany && <>{renderCompany()} · </>}
        {renderDate()}
        {is_remote || multiple_locations ? null : renderLocation()}
        {withApplication ? renderApplication() : null}
      </span>
    </div>
  )
}

SubBar.propTypes = {
  data: PropTypes.object,
  withApplication: PropTypes.bool,
}
