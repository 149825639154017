import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { FormFieldController, Combobox } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { WorkAuthorizationsFieldArray } from 'features/profile/components/FormElements'
import { MentorsFormSection } from '../MentorsFormSection'

export const BasicProfileFormSection = ({ withMentor = false }) => {
  const { t } = useTranslation()

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const {
    getProfileStatuses,
    getLocationProvinces,
    getLocationCities,
    getLocationCountries,
    getLanguages,
    getProfileObjectives,
    getProfileWillingRelocates,
    getProfileSearchStatuses,
  } = useSelectOptions()

  const watchProvince = watch('student_profile.location_province_id')

  const section1 = [
    {
      name: 'student_profile.profile_status_id',
      label: t('status'),
      options: getProfileStatuses(),
      isSearchable: false,
    },
    {
      name: 'student_profile.profile_search_status_id',
      label: t('your_job_search_status'),
      options: getProfileSearchStatuses(),
      isSearchable: false,
    },
  ]

  const onProvinceChange = value => {
    setValue('student_profile.location_province_id', value)
    setValue('student_profile.location_city_id', null)
  }

  const section2 = [
    {
      name: 'student_profile.location_province_id',
      label: t('province_location'),
      options: getLocationProvinces(),
      onChange: onProvinceChange,
    },
    {
      name: 'student_profile.location_city_id',
      label: t('current_city_in_canada'),
      options: getLocationCities({ location_province_id: watchProvince }),
      hidden: !watchProvince,
    },
    {
      name: 'student_profile.profile_willing_relocate_id',
      label: `${t('are_you_willing_relocate_to_work')}?`,
      options: getProfileWillingRelocates(),
      isSearchable: false,
    },
  ]

  const renderSelectField = ({ name, label, options, ...params }) => (
    <FormFieldController
      name={name}
      control={control}
      component={Combobox}
      id={name}
      label={label}
      placeholder={label}
      options={options}
      isDetermineValue
      normalize={option => (option !== null ? option.value : null)}
      menuPortalTarget={document.querySelector('body')}
      {...params}
    />
  )

  return (
    <>
      {withMentor && (
        <>
          <MentorsFormSection />
          <div className={'py-3'} />
        </>
      )}

      <Row>
        {section1.map((item, i) => (
          <Col key={i} md={6}>
            {renderSelectField(item)}
          </Col>
        ))}
      </Row>

      <Row>
        <Col md={12}>
          {renderSelectField({
            name: 'student_profile.profile_objectives',
            label: t('i_am_looking_for'),
            options: getProfileObjectives(),
            isSearchable: false,
            isMulti: true,
            normalize: value => value.map(item => item.value),
            isOptionDisabled: (option, selectValue) => {
              if (selectValue && selectValue.length) {
                return option.weight !== selectValue[0]?.weight
              }
              return false
            },
          })}
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          {renderSelectField({
            name: 'student_profile.location_country_id',
            label: t('county_of_origin'),
            options: getLocationCountries(),
          })}
        </Col>

        <Col md={6}>
          {renderSelectField({
            name: 'student_profile.languages',
            label: `${t('language_spoken')} (${t('select_all_that_apply')})`,
            placeholder: t('language_spoken'),
            options: getLanguages(),
            isMulti: true,
            normalize: value => value.map(item => item.value),
          })}
        </Col>
      </Row>

      <Row>
        {section2.map(
          (item, i) =>
            !item.hidden && (
              <Col key={i} md={6} lg={4}>
                {renderSelectField(item)}
              </Col>
            )
        )}
      </Row>

      <div className={'py-3'} />

      <h5>{t('work_authorizations')}</h5>
      <WorkAuthorizationsFieldArray
        control={control}
        name={'student_profile.profile_work_authorizations'}
        clearErrors={clearErrors}
        meta={{ error: errors?.student_profile?.profile_work_authorizations }}
        setValue={setValue}
      />
    </>
  )
}
