import React from 'react'
import { useSelector } from 'react-redux'
import { ROLE } from 'features/user'
import { guardHelper } from 'features/user/helpers'
import { selectUserData } from '../../store'
import { DateUtils } from 'utils'

export const useUser = () => {
  const user = useSelector(selectUserData)

  const isStudent = () => {
    return user.role === ROLE.STUDENT
  }

  const isEmployer = () => {
    return user.role === ROLE.EMPLOYER
  }

  const isMentor = () => {
    return isStudent() && user?.student_profile?.is_mentor
  }

  const getName = () => {
    return user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : null
  }

  const isProfileComplete = () => {
    return guardHelper.isProfileCompleteAllowed(user)
  }

  const isLastActivityMonthAgo = () => {
    return DateUtils.isPeriodPassed(user?.last_activity, 1, 'months')
  }

  return {
    user,
    isStudent,
    isEmployer,
    getName,
    isProfileComplete,
    isMentor,
    isLastActivityMonthAgo,
  }
}
