import axios from 'axios'

class JobRecommendationsAPI {
  getMyJobRecommendations = async params => {
    return axios.get(`api/job-recommendations`, {
      params,
    })
  }
}

export const jobRecommendationsAPI = new JobRecommendationsAPI()
