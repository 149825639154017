import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import 'assets/scss/theme.scss'
import * as serviceWorker from './serviceWorker'
import './i18n'
import 'libs/axios'
import { API } from 'services'
import { StoreProvider } from 'providers'
import { appAnalyticsService } from 'services'

// Init app analytics
appAnalyticsService.init()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StoreProvider>
    <BrowserRouter>
      <Suspense fallback="">
        <App />
      </Suspense>
    </BrowserRouter>
  </StoreProvider>
)

serviceWorker.unregister()
