import React from 'react'

import { useFetch } from 'hooks'
import { jobsAPI } from 'features/job/api'
import { RequestUtils } from 'utils'

export const useMyJobsFetch = () => {
  const { data, fetch, loading, error } = useFetch()

  const onFetch = params => {
    let requestParams = {}

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    fetch(jobsAPI.getMyJobs, requestParams)
  }

  return {
    data,
    loading,
    error,
    onFetch,
  }
}
