import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'
import { SearchFormSection } from 'features/filter/components/FormSections'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const CandidatesAdvancedFilterFormSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const {
    getIndustries,
    getTargetPositions,
    getLanguages,
    getSchools,
    getProfileWillingRelocates,
    getProfileObjectives,
    getBooleanOptions,
    getProfileSearchStatuses,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={name}
        control={control}
        component={Combobox}
        id={name}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        // menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      <Row>
        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_search_statuses',
            label: t('job_search_status'),
            options: getProfileSearchStatuses(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'industries',
            label: t('target_industry'),
            options: getIndustries(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'target_positions',
            label: t('target_positions'),
            options: getTargetPositions(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'languages',
            label: t('languages'),
            options: getLanguages(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'schools',
            label: t('schools'),
            options: getSchools(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_willing_relocate',
            label: t('willing_to_relocate'),
            options: getProfileWillingRelocates(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_objectives',
            label: t('looking_for'),
            options: getProfileObjectives(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'has_resume',
            label: t('have_resume'),
            options: getBooleanOptions(),
          })}
        </Col>
      </Row>

      <Row>
        <Col lg={8} xl={6}>
          <SearchFormSection
            field={{
              name: 'profile_certifications',
              placeholder: t('certifications'),
            }}
            keywordsMinLength={2}
          />
        </Col>
      </Row>
    </>
  )
}

CandidatesAdvancedFilterFormSection.propTypes = {}
