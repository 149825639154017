import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { FormFieldController, PasswordInputFiled } from 'components/FormElements'
import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { useFormSubmit } from 'hooks'

const passwordMinLength = 8

export const PasswordForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      await onFormSubmit(data)
      reset()
    } catch (error) {}
  }
  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFieldController
            name={'current_password'}
            control={control}
            component={PasswordInputFiled}
            id={'current_password'}
            label={t('current_password')}
            placeholder={t('current_password')}
            translateParams={{ count: passwordMinLength }}
          />

          <FormFieldController
            name={'password'}
            control={control}
            component={PasswordInputFiled}
            id={'password'}
            label={t('new_password')}
            placeholder={t('new_password')}
            translateParams={{ count: passwordMinLength }}
          />

          <FormFieldController
            name={'password_confirmation'}
            control={control}
            component={PasswordInputFiled}
            id={'password_confirmation'}
            label={t('confirm_new_password')}
            placeholder={t('confirm_new_password')}
            translateParams={{ count: passwordMinLength }}
          />

          <div className="mt-3 pt-3">
            <div className="button-items">
              <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                {t('change_password')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

PasswordForm.propTypes = {
  onSubmit: PropTypes.func,
}
