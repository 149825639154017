import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import { AvatarProvider } from 'components/Elements'
import s from './General.module.scss'

export const General = () => {
  const { t } = useTranslation()

  const { user, getName } = useUser()

  return (
    <>
      <Card>
        <CardBody>
          <div className={s.block}>
            <div className={s.avatarWrap}>
              <AvatarProvider avatar={user?.avatar} size={'md'} />
            </div>
            <div>
              <div>
                <h4 className={'mb-0'}>{getName()}</h4>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

General.propTypes = {}
