import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'

import s from './GeneralJobCard.module.scss'
import { TitleBlock } from './TitleBlock'
import { InfoBlock } from './InfoBlock'
import { PropertiesBlock } from 'features/job/components/Job/JobDetails/InfoBlocks'
import { CardActionBar } from '../Card'

export const GeneralJobCard = ({
  data,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  saveControlProps,
  className = 'mb-3',
  withApplication = false,
  withCompany = true,
  highlight = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Card
      className={classNames(s.root, {
        [className]: className,
        ['bg-soft bg-warning']: highlight,
      })}
    >
      <CardBody>
        <div className={s.topBar}>
          <div className={s.titleBlock}>
            <TitleBlock data={data} config={{ withApplication, withCompany }} />
          </div>

          {actionBar && (
            <div className={s.actionBar}>
              {actionBarSection ? (
                actionBarSection
              ) : (
                <CardActionBar job={data} {...actionBarProps} saveControlProps={saveControlProps} />
              )}
            </div>
          )}
        </div>

        <div className={'mt-2'}>
          <PropertiesBlock data={{ ...data }} />
        </div>

        {data?.about_this_role && (
          <div className={'mt-2'}>
            <InfoBlock data={data} />
          </div>
        )}
      </CardBody>
    </Card>
  )
}

GeneralJobCard.propTypes = {
  data: PropTypes.object,
  actionBar: PropTypes.bool,
  actionBarSection: PropTypes.node,
  actionBarProps: PropTypes.object,
  saveControlProps: PropTypes.object,
  className: PropTypes.string,
  withApplication: PropTypes.bool,
  withCompany: PropTypes.bool,
  highlight: PropTypes.bool,
}
