import React from 'react'
import PropTypes from 'prop-types'
import RDatePicker from 'react-datepicker'
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import classNames from 'classnames'

import { FormField } from 'components/FormElements'
import { TextMaskUtils } from 'utils'

export const DatePicker = ({
  id,
  label,
  placeholder,
  fieldRef = null,
  normalize,
  disabled,
  value,
  meta = {},
  translateParams,
  onChange,
  wrapClassName,
  hintText,
  dateFormat = 'dd/MM/yyyy',
  timeFormat = 'HH:mm',
  mask = TextMaskUtils.dateInputMask,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const handleChange = date => {
    date = normalize ? normalize(date) : date

    if (onChange) {
      onChange(date)
    }
  }

  return (
    <>
      <FormField
        id={id}
        label={label}
        meta={meta}
        hintText={hintText}
        translateParams={translateParams}
        className={wrapClassName}
        render={() => (
          <>
            <RDatePicker
              customInput={
                mask ? <MaskedInput mask={mask} keepCharPositions={true} guide={true} /> : null
              }
              selected={value ? moment(value).toDate() : value}
              onChange={handleChange}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              id={id}
              placeholderText={placeholder}
              disabled={disabled}
              autoComplete="off"
              onBlur={props.onBlur}
              calendarStartDay={1}
              className={classNames('form-control', {
                'is-invalid': showError,
              })}
              {...props}
            />
          </>
        )}
      />
    </>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  fieldRef: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  wrapClassName: PropTypes.string,
  hintText: PropTypes.string,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  mask: PropTypes.any,
}
