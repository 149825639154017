import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ReceivedInvites } from 'features/job-invite/routes/invites'

export const JobInvitesRoot = () => {
  return (
    <Routes>
      <Route index element={<ReceivedInvites />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

JobInvitesRoot.propTypes = {}
