import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'

export const WizardSteps = ({ step, steps = [], onStepClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="wizard">
        <div className="steps">
          <ul>
            {steps.map(({ label }, i) => (
              <NavItem key={i} className={classNames('wizard__item', { current: step === i })}>
                <NavLink
                  className={classNames('wizard__link', { classNames: step === i })}
                  onClick={() => onStepClick && onStepClick(i)}
                  disabled={step <= i}
                >
                  <div className={'d-flex align-items-center'}>
                    <div>
                      <span className="number">{i + 1}</span>
                    </div>
                    <div>{label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

WizardSteps.propTypes = {
  step: PropTypes.number,
  steps: PropTypes.array,
}
