import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { useUser } from 'features/user'
import { PageContent, PageHeader } from 'components/Layouts'
import { StudentProfileEdit, EmployerProfileEdit } from 'features/profile/components/ProfileEdit'

export const ProfileEdit = () => {
  const { t } = useTranslation()

  const { isStudent } = useUser()

  return (
    <>
      <MegaTagTitle title={'edit_profile'} />

      <PageContent>
        <PageHeader back />
        <>{isStudent() ? <StudentProfileEdit /> : <EmployerProfileEdit />}</>
      </PageContent>
    </>
  )
}

ProfileEdit.propTypes = {}
