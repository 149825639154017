import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Active } from '../active'
import { Drafts } from '../drafts'
import { Closed } from '../closed'
import { MyJobsListViewLayout, MyJobsLayout } from 'features/job/components/Layouts'

export const MyRoot = () => {
  return (
    <Routes>
      <Route element={<MyJobsLayout />}>
        <Route index element={<Navigate to={'active'} replace={true} />} />

        <Route element={<MyJobsListViewLayout />}>
          <Route path="active" element={<Active />} />
          <Route path="drafts" element={<Drafts />} />
          <Route path="closed" element={<Closed />} />
        </Route>

        <Route path={'*'} element={<Navigate to={'active'} replace={true} />} />
      </Route>
    </Routes>
  )
}

MyRoot.propTypes = {}
