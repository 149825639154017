import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { jobPanelJob } from 'features/job/store'
import { DataShower } from 'components/Elements'
import { PageContent } from 'components/Layouts'

export const JobPanelLayoutComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(jobPanelJob.getData({ id }))

    return () => {
      dispatch(jobPanelJob.cleanState({}))
    }
  }, [])

  const isFetched = !!props.data

  return (
    <>
      {isFetched ? (
        <Outlet />
      ) : (
        <PageContent>
          <DataShower
            isFetched={!!props.data}
            isFailed={!!props.error}
            isLoading={props.loading}
            error={props.error}
          >
            {null}
          </DataShower>
        </PageContent>
      )}
    </>
  )
}

JobPanelLayoutComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.job.panel.job
  return {
    data,
    loading,
    error,
  }
}

export const JobPanelLayout = connect(mapStateToProps)(JobPanelLayoutComponent)
