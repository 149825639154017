import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Employer } from '../employer'

export const EmployerRoot = () => {
  return (
    <Routes>
      <Route index element={<Employer />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

EmployerRoot.propTypes = {}
