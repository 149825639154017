import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DataShower } from 'components/Elements'
import { mentorData } from 'features/mentor/store'
import { CandidateDetailsContent } from 'features/candidate/components/Candidate/CandidateDetails'

const MentorDetailsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(mentorData.getData({ id }))

    return () => {
      dispatch(mentorData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFetched={!!props.data}
        isFailed={!!props.error}
        error={props.error}
      >
        <CandidateDetailsContent
          candidate={props.data}
          asMentor
          fieldsConfig={{
            contacts: false,
            objectives: false,
            relocation: false,
            workAuthorizations: false,
          }}
        />
      </DataShower>
    </>
  )
}

MentorDetailsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.mentor.data
  return {
    data,
    loading,
    error,
  }
}

export const MentorDetailsContainer = connect(mapStateToProps)(MentorDetailsContainerComponent)
