import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './StatusBar.module.scss'
import { TagBadge } from 'components/Elements'

export const StatusBar = ({ data = {}, asMentor = false, ...props }) => {
  const { t } = useTranslation()

  const { student_profile } = data

  const { profile_willing_relocate, profile_work_authorizations, industries, languages } =
    student_profile || {}

  const config = {
    relocation: !asMentor && profile_willing_relocate,
    workAuthorizations:
      !asMentor && profile_work_authorizations && !!profile_work_authorizations.length,
    industries: asMentor && industries && !!industries.length,
    languages: asMentor && languages && !!languages.length,
  }

  const renderTag = value => (
    <div className={s.tagBlock}>
      <TagBadge size={'xs'}>{value}</TagBadge>
    </div>
  )

  return (
    <div className={'d-flex flex-wrap gap-1'}>
      {config.relocation && renderTag(`${t('relocate')}: ${profile_willing_relocate?.value}`)}

      {config.workAuthorizations &&
        profile_work_authorizations.map((item, i) => (
          <React.Fragment key={i}>{renderTag(item?.value)}</React.Fragment>
        ))}

      {config.industries &&
        industries
          .slice(0, 2)
          .map((item, i) => <React.Fragment key={i}>{renderTag(item?.value)}</React.Fragment>)}

      {config.languages &&
        languages
          .filter(item => item.weight !== 0)
          .map((item, i) => <React.Fragment key={i}>{renderTag(item?.value)}</React.Fragment>)}
    </div>
  )
}

StatusBar.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  jobInvite: PropTypes.object,
  asMentor: PropTypes.bool,
}
