import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { GeneralCandidatesContainer } from 'features/candidate/components/Candidates/GeneralCandidates'

export const Candidates = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'candidates'} />

      <PageContent>
        <GeneralCandidatesContainer />
      </PageContent>
    </>
  )
}

Candidates.propTypes = {}
