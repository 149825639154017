import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, CardBody, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ROLE } from 'features/user'
import { RegisterForm } from './RegisterForm'
import { Alert } from 'components/Elements'
import { authRegisterPA } from '../../../store'

export const RegisterContainer = ({ role, isMentor = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [success, setSuccess] = useState(false)

  const onSubmit = async values => {
    setSuccess(false)

    const { student_profile, ...restValues } = values

    let params = {
      ...restValues,
      role,
      phone: values.phone.replace(/[^0-9]/g, ''),
    }

    if (student_profile) {
      params = {
        ...params,
        student_profile: {
          ...student_profile,
          is_mentor: isMentor,
        },
      }
    }

    await dispatch(authRegisterPA.request(params))
    setSuccess(true)
  }

  return (
    <>
      <Card className="overflow-hidden">
        <div>
          <Row>
            <Col>
              <div className="text-center p-4">
                <h3 className={'mb-0'}>
                  {role === ROLE.EMPLOYER
                    ? t('employer_registration')
                    : isMentor
                    ? t('mentor_registration')
                    : t('candidate_registration')}
                </h3>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          {success && <Alert>{t('confirmation-email-sent')}!</Alert>}

          <RegisterForm onSubmit={onSubmit} role={role} isMentor={isMentor} />
        </CardBody>
      </Card>
      <div className="text-center">
        <p>
          {t('already_have_account')}?{' '}
          <Link to="/login" className="fw-medium text-primary">
            {t('login')}
          </Link>
        </p>
      </div>
    </>
  )
}
