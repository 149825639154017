import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { profileAPI } from 'features/profile/api'
import i18n from 'i18n'
import { userData } from '../data/slice'
import { userLoader } from './slice'

export function* getData() {
  try {
    const includes = ['subscription']

    const response = yield call(profileAPI.getProfile, { includes })

    const { data } = response

    yield put(userLoader.getDataSuccess())

    yield put(userData.setData(data?.data))

    return data?.data
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(userLoader.getDataError(message))

    throw error
  }
}

export function* watchGetData() {
  yield takeEvery(userLoader.getData, getData)
}

export function* loaderSaga() {
  yield all([fork(watchGetData)])
}
