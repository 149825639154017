import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import {
  BasicProfileFormSection,
  EducationWorkFormSection,
  SelfIdentificationFormSection,
  AvatarFormSection,
} from 'features/profile/components/FormSections/StudentProfileFormSections'
import { GeneralFormSection } from 'features/profile/components/FormSections'
import { useUser } from 'features/user'
import { DateUtils } from 'utils'
import { useFormSubmit } from 'hooks'
import { formHelper } from 'features/profile/helpers/form'

const { studentProfile } = formHelper

export const EditForm = props => {
  const { t } = useTranslation()

  const { user, isMentor } = useUser()

  const defaultValues = {
    ...studentProfile.mapCandidateProfileDefaultValues(user),
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: { isMentor: isMentor() },
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      const { email, phone, avatar, student_profile, ...restValues } = data
      const {
        profile_work_authorizations,
        school_year,
        student_works,
        student_projects,
        schedule_link,
        is_shared_mentor,
        ...restStudentProfileValues
      } = student_profile

      let studentProfileParams = {
        ...restStudentProfileValues,
        profile_work_authorizations: studentProfile.mapStudentWorkAuthorizationsSubmitValues(
          profile_work_authorizations
        ),
        student_works: studentProfile.mapStudentWorksSubmitValues(student_works),
        student_projects: studentProfile.mapStudentProjectsSubmitValues(student_projects),
        school_year: school_year ? DateUtils.format(school_year, 'YYYY') : null,
      }

      if (isMentor()) {
        studentProfileParams = {
          ...studentProfileParams,
          schedule_link,
          is_shared_mentor,
        }
      }

      let values = {
        ...restValues,
        phone: phone.replace(/[^0-9]/g, ''),
        student_profile: {
          ...studentProfileParams,
        },
      }

      if (isMentor()) {
        if (avatar) {
          if (avatar instanceof File) {
            values.avatar = avatar
          }
        } else {
          values.avatar = ''
        }
      }

      await onFormSubmit(values)
    } catch (error) {}
  }
  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          {isMentor() && <AvatarFormSection />}

          <GeneralFormSection />

          <h4 className={'text-center mt-4'}>{t('basic_profile')}</h4>
          <BasicProfileFormSection withMentor={isMentor()} />

          <h4 className={'text-center mt-4 pt-4'}>{t('work_and_education_details')}</h4>
          <div className={'pt-2'}>
            <EducationWorkFormSection isMentor={isMentor()} />
          </div>

          <h4 className={'text-center mt-4 pt-4 mb-0'}>
            {t('self_identified_demographic_information')}
          </h4>
          <p className={'font-size-14 text-muted'}>{t('self_identified.disclaimer')}</p>
          <SelfIdentificationFormSection />

          <div className="mt-3">
            <div className="button-items">
              <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

EditForm.propTypes = {
  onSubmit: PropTypes.func,
}
