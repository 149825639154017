import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { jobMySpecificList } from 'features/job/store'
import { JobsTable } from './JobsTable'
import { NoDataTextMessage, DataShower, OverlayBlockLoading, Pagination } from 'components/Elements'
import { JOB_STATUS } from 'features/job/consts/general'

export const SpecificJobsContainerComponent = ({ status, ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobMySpecificList.cleanState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [status, props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 20,
      filters: {
        statuses: [status],
      },
      sort:
        status === JOB_STATUS.ACTIVE
          ? [
              {
                id: 'posted_on',
                desc: true,
              },
            ]
          : [{ id: 'created_at', desc: true }],
    }

    dispatch(jobMySpecificList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobMySpecificList.setPage(item.selected + 1))
  }

  const getTableKeys = () => {
    const keys = [{ key: 'title' }, { key: 'pay-range' }, { key: 'action', hidden: false }]

    return keys.filter(item => !item.hidden).map(item => item.key)
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <DataShower
        isLoading={props.loading && !props.list.length}
        isFailed={!!props.error}
        error={props.error}
        isFetched={!!props.list.length}
      >
        <OverlayBlockLoading isLoading={isLoading}>
          <JobsTable keys={getTableKeys()} items={props.list} onPostSuccess={fetchList} />

          <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
        </OverlayBlockLoading>
      </DataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_jobs_yet')}</NoDataTextMessage>
      )}
    </>
  )
}

SpecificJobsContainerComponent.propTypes = {
  status: PropTypes.string,
}

const mapStateToProps = state => {
  const { list, loading, error, meta, page } = state.job.my.specific.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const SpecificJobsContainer = connect(mapStateToProps)(SpecificJobsContainerComponent)
