import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { GeneralJobsContainer } from 'features/job/components/Jobs/GeneralJobsContainer'

export const Jobs = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'jobs'} />

      <GeneralJobsContainer />
    </>
  )
}

Jobs.propTypes = {}
