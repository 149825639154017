import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './AttachmentsBlock.module.scss'
import { Anchor } from 'components/Elements'
import { FileUtils } from 'utils'

export const AttachmentsBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const resume = data?.student_profile?.resume

  const onDownload = e => {
    e.preventDefault()

    FileUtils.downloadFileByLink(resume?.url)
  }

  return resume ? (
    <div className={'d-flex mt-1 justify-content-end'}>
      <Anchor to={''} className={`${s.link} text-primary fw-medium`} onClick={onDownload}>
        {t('download_resume')}
      </Anchor>
    </div>
  ) : null
}

AttachmentsBlock.propTypes = {
  data: PropTypes.object,
}
