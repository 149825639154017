import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAsyncDispatch, useNotify } from 'hooks'
import { mentorScheduleLinkGetPA } from 'features/mentor/store'
import { Button } from 'components/Elements'

export const GetScheduleLinkControl = ({ id, className = '', onSuccess }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async () => {
    try {
      const data = await onAsyncDispatch(mentorScheduleLinkGetPA.request, { id })

      onSuccess && onSuccess({ ...data })

      if (data?.data?.schedule_link) {
        window.open(data?.data?.schedule_link, '_blank')
      }
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <Button
      className={className}
      onClick={onSubmit}
      disabled={loading}
      loading={loading}
      size={'sm'}
      width={'w-sm'}
    >
      {t('book_meeting')}
    </Button>
  )
}

GetScheduleLinkControl.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
}
