import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../../api'
import {
  JOB_STATUS_UPDATE_REQUEST,
  JOB_STATUS_UPDATE_SUCCESS,
  JOB_STATUS_UPDATE_FAILED,
} from './actionTypes'

export const jobStatusUpdatePA = createPromiseAction(
  JOB_STATUS_UPDATE_REQUEST,
  JOB_STATUS_UPDATE_SUCCESS,
  JOB_STATUS_UPDATE_FAILED
)()

function* update(action) {
  try {
    const { id, status } = action.payload

    const params = {
      status,
    }

    const response = yield call(jobAPI.updateJob, id, params)
    const { data } = response

    yield put(jobStatusUpdatePA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(jobStatusUpdatePA.request, update)
}
