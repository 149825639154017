import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { General } from './General'
import { Basic } from './Basic'
import { Education } from './Education'
import { Work } from './Work'
import { SelfIdentification } from './SelfIdentification'

export const StudentProfileDetails = () => {
  const { t } = useTranslation()

  return (
    <>
      <General />

      <Basic />

      <Work />

      <Education />

      <SelfIdentification />
    </>
  )
}

StudentProfileDetails.propTypes = {}
