import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Header } from './Header'
import { Questions } from '../Questions'
import { NoDataTextMessage } from 'components/Elements'

export const Sections = ({ items, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex flex-column gap-5'}>
      {items.map(item => {
        const { id, title, questions } = item

        return (
          <div key={id} className={'d-flex flex-column flex-grow-1'}>
            <div className={'d-flex flex-grow-1 gap-3'}>
              <Header title={title} />
            </div>

            <>
              {questions && !!questions.length ? (
                <Questions items={questions} />
              ) : (
                <NoDataTextMessage variant={'empty'} />
              )}
            </>
          </div>
        )
      })}
    </div>
  )
}

Sections.propTypes = {
  items: PropTypes.array,
}
