import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'

export const TabsNav = ({
  onChange,
  className,
  options,
  active,
  variant = 'default',
  ...props
}) => {
  const onClick = item => {
    if (item.value !== active) {
      onChange(item.value, item)
    }
  }

  return (
    <Nav
      className={classNames('nav-tabs', {
        [className]: className,
        ['nav-tabs-custom nav-tabs']: variant === 'nav',
      })}
      {...props}
    >
      {options.map(item => (
        <NavItem key={item.value}>
          <NavLink
            className={classNames({
              active: item.value === active,
            })}
            onClick={() => onClick(item)}
          >
            {item.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

TabsNav.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
  active: PropTypes.any,
  onChange: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'nav']),
}
