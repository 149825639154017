import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './InfoBlock.module.scss'
import { TagBadge } from 'components/Elements'

export const InfoBlock = ({ data = {}, asMentor = false, ...props }) => {
  const { t } = useTranslation()

  const { student_profile } = data

  const { skills, target_positions } = student_profile || {}

  const config = {
    skills: !asMentor && skills && !!skills.length,
    target_positions: asMentor && target_positions && !!target_positions.length,
  }

  const renderTags = items => (
    <div className={'d-flex flex-wrap gap-1'}>
      {items.map((item, i) => (
        <TagBadge key={i} size={'md'}>
          {item?.value}
        </TagBadge>
      ))}
    </div>
  )

  return config.skills || config.target_positions ? (
    <div className={'mt-2'}>
      {config.skills && renderTags(skills)}

      {config.target_positions && (
        <div className={'d-flex flex-wrap gap-1'}>{renderTags(target_positions)}</div>
      )}
    </div>
  ) : null
}

InfoBlock.propTypes = {
  data: PropTypes.object,
  asMentor: PropTypes.bool,
}
