import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { ReceivedInvitesContainer } from 'features/job-invite/components/Invites/ReceivedInvites'
import { MyJobRecommendationsContainer } from 'features/job-invite/components/JobRecommendations/MyJobRecommendations'

export const ReceivedInvites = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'invites'} />

      <PageContent>
        <h4 className="font-size-18">{t('invites')}</h4>
        <ReceivedInvitesContainer />

        <div className={'mt-4'}>
          <h4 className="font-size-18">{t('recommendations')}</h4>
          <MyJobRecommendationsContainer />
        </div>
      </PageContent>
    </>
  )
}

ReceivedInvites.propTypes = {}
