import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { PasswordContainer } from 'features/profile/components/Settings'

export const ProfileSettings = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'profile_settings'} />

      <PageContent>
        <PageHeader back />

        <Row>
          <Col lg={6}>
            <PasswordContainer />
          </Col>
        </Row>
      </PageContent>
    </>
  )
}

ProfileSettings.propTypes = {}
