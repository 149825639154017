import { getAuthRoutes } from 'routes/configs/auth'
import { getPrivateRoutes } from 'routes/configs/private'
import { getPublicRoutes } from 'routes/configs/public'

export const getRootRoutes = (isLoggedIn, user) => {
  return [
    ...getPrivateRoutes(isLoggedIn, user), // order is necessary (for index route go to private route)
    ...getAuthRoutes(isLoggedIn),
    ...getPublicRoutes(),
  ]
}
