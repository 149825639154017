import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { Anchor } from 'components/Elements'
import { DateUtils } from 'utils'

export const TitleBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { job, created_at } = data

  const { user } = job
  const { company_name } = user?.employer_profile ?? {}

  return (
    <div className={'d-flex'}>
      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap gap-1'}>
          <p className={'mb-0'}>
            <Trans
              i18nKey="invites.job-recommendations.message"
              values={{ job: job?.title, companyName: company_name }}
              components={{
                link1: (
                  <Anchor
                    to={`/jobs/${job?.id}`}
                    color={'dark'}
                    className={'text-decoration-underline fw-medium'}
                  />
                ),
                link2: (
                  <Anchor
                    to={`/employers/${user?.id}`}
                    color={'dark'}
                    className={'text-decoration-underline fw-medium'}
                  />
                ),
              }}
            />
          </p>
        </div>
        <div>
          {
            <p className={'font-size-14 mb-0 fw-medium'}>
              {t('recommended_on')}
              {': '}
              {DateUtils.format(created_at, 'YYYY/MM/DD')}
            </p>
          }
        </div>
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
}
