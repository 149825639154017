import * as Yup from 'yup'
import { ValidationsUtils } from 'utils'
import { FILE_MAX_SIZE } from 'consts/core'

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('field.error.invalid').required('field.error.required'),
  phone: Yup.string()
    .required('field.error.required')
    .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat),
  resume: Yup.mixed()
    .nullable()
    .test('maxSize', 'file.max_size.error', value =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    ),
})
