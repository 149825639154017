import { combineReducers } from 'redux'
import data from './data/slice'
import loader from './loader/slice'
import sync from './sync/slice'

const userReducer = combineReducers({
  data,
  loader,
  sync,
})

export default userReducer
