import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { AvatarProvider, TagBadge } from 'components/Elements'

const defaultConfig = {
  relocation: true,
  workAuthorizations: true,
}

export const TitleBlock = ({ data = {}, config = {}, ...props }) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const { id, first_name, last_name, avatar, student_profile } = data

  const {
    profile_willing_relocate,
    profile_work_authorizations,
    profile_status,
    profile_search_status,
  } = student_profile || {}

  const getName = () => {
    return `${first_name} ${last_name}`
  }

  return (
    <div className={'d-flex'}>
      <div>
        <AvatarProvider avatar={avatar} className={'me-2'} />
      </div>

      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap gap-1 align-items-center'}>
          <h5 className={'mb-0'}>{getName()}</h5>
          <div className={'d-flex flex-wrap gap-1'}>
            {config.relocation && profile_willing_relocate && (
              <div className={s.tagBlock}>
                <TagBadge size={'xs'}>{`${t('relocate')}: ${
                  profile_willing_relocate?.value
                }`}</TagBadge>
              </div>
            )}

            {config.workAuthorizations &&
              profile_work_authorizations &&
              !!profile_work_authorizations.length &&
              profile_work_authorizations.map((item, i) => (
                <div key={i} className={s.tagBlock}>
                  <TagBadge size={'xs'}>{item?.value}</TagBadge>
                </div>
              ))}
          </div>
        </div>
        {(profile_status || profile_search_status) && (
          <div>
            <p className={'font-size-14 mb-0 fw-medium'}>
              {profile_status && profile_status?.public_value}

              {profile_search_status ? (
                <>
                  {profile_status && ' · '}
                  {profile_search_status?.value}
                </>
              ) : null}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
}
