import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { PrivateLayout } from 'components/Layouts'

export const PrivateGuard = ({ children, ...props }) => {
  const location = useLocation()

  const { isLoggedIn } = props

  if (!isLoggedIn) {
    return <Navigate to={'/login'} state={{ from: location }} />
  }

  return <PrivateLayout />
}

PrivateGuard.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.bool,
}
