import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './InfoBlock.module.scss'
import { ReadMoreExpand } from 'components/Elements'

export const InfoBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { about_this_role } = data

  return (
    <div className={s.about}>
      {about_this_role ? (
        <ReadMoreExpand maxHeight={64} lineHeight={16}>
          {about_this_role}
        </ReadMoreExpand>
      ) : null}
    </div>
  )
}

InfoBlock.propTypes = {
  data: PropTypes.object,
}
