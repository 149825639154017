import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { useUser } from 'features/user'
import { FileUtils } from 'utils'
import { Attachment, NoDataTextMessage } from 'components/Elements'

export const Attachments = ({ data }) => {
  const { t } = useTranslation()

  const { user } = useUser()

  const resume = user?.student_profile?.resume

  const onDownload = () => {
    FileUtils.downloadFileByLink(resume?.url)
  }

  return (
    <>
      <h5>{t('resume')}</h5>
      {resume ? (
        <Row>
          <Col md={6} lg={4}>
            <Attachment
              name={resume?.file_name}
              size={resume?.size}
              download
              onDownload={onDownload}
            />
          </Col>
        </Row>
      ) : (
        <NoDataTextMessage>{t('no_resume')}</NoDataTextMessage>
      )}
    </>
  )
}

Attachments.propTypes = {}
