import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'
import { currencyHelper } from 'helpers'

const { FREE, PER_JOB, PREMIUM, ENTERPRISE } = BILLING_TARIFF_ALIAS

const currencySymbol = currencyHelper.general.getGeneralCurrency()?.symbol

export const Tariff = ({ tariff = {}, current }) => {
  const { t } = useTranslation()

  const { name, alias, price } = tariff

  const renderPrice = () => {
    let result = null

    if (alias === FREE) {
      result = name
    } else if (alias === PER_JOB) {
      result = (
        <>
          {currencySymbol}
          {price}
          <span className={'text-muted fw-light'}>{`/${t('job')}`}</span>
        </>
      )
    } else if (alias === PREMIUM) {
      result = (
        <>
          {currencySymbol}
          {price}
          <span className={'text-muted fw-light'}>{`/${t('month')}`}</span>
        </>
      )
    } else if (alias === ENTERPRISE) {
      result = t('contact_sales')
    }

    return result
  }

  return (
    <div className={'text-center text-nowrap'}>
      <div className={'mb-4'}>
        <h5>{name}</h5>
      </div>

      <h4 className={'mb-3'}>{renderPrice()}</h4>

      {current && (
        <div className={'d-flex text-success fw-normal align-items-center justify-content-center'}>
          <i className={'bx bxs-check-circle font-size-20 me-1'} />
          {t('subscribed')}
        </div>
      )}
    </div>
  )
}

Tariff.propTypes = {
  tariff: PropTypes.object,
  current: PropTypes.bool,
}
