import axios from 'axios'

class ProfileCompanyLogoAPI {
  uploadLogo = async data => {
    const formData = new FormData()
    formData.append('company_logo', data.avatar)

    return axios.post('api/profile/employer/company-logo', formData)
  }

  deleteLogo = async () => {
    return axios.delete('api/profile/employer/company-logo')
  }
}

export const profileCompanyLogoAPI = new ProfileCompanyLogoAPI()
