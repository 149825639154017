import { combineReducers } from 'redux'

import applications from './applications/reducers'
import recommendations from './recommendations/reducers'

const candidatesReducer = combineReducers({
  applications,
  recommendations,
})

export default candidatesReducer
