import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LogoIcon } from 'components/Elements'
import { SidebarContent } from '../SidebarContent'
import { useUser } from 'features/user'

export const SidebarVerticalMenu = () => {
  const { t } = useTranslation()

  const { isEmployer } = useUser()

  let indexPath = isEmployer() ? '/company/profile' : '/jobs'

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to={indexPath} className="logo logo-dark">
          <span className="logo-sm">
            <LogoIcon type={'icon'} />
          </span>
          <span className="logo-lg">
            <LogoIcon />
          </span>
        </Link>
      </div>
      <SidebarContent />
      <div className="sidebar-background" />
    </div>
  )
}
