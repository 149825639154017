import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { Anchor, AvatarProvider } from 'components/Elements'
import { SubBar } from './SubBar'
import { StatusBar } from './StatusBar'

export const TitleBlock = ({
  data = {},
  config = {
    jobInvite: false,
  },
  jobInvite,
  asMentor = false,
  ...props
}) => {
  const { t } = useTranslation()

  const { id, first_name, last_name, avatar } = data

  const getName = () => {
    return `${first_name} ${last_name}`
  }

  return (
    <div className={'d-flex'}>
      <div>
        <AvatarProvider avatar={avatar} className={'me-2'} />
      </div>

      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap gap-1'}>
          <h6 className={'mb-0'}>
            <Anchor
              to={asMentor ? `/mentors/${id}` : `/candidates/${id}`}
              color={'dark'}
              className={'text-decoration-underline'}
            >
              {getName()}
            </Anchor>
          </h6>
          <StatusBar data={data} asMentor={asMentor} />
        </div>
        <SubBar data={data} withJobInvite={config?.jobInvite} jobInvite={jobInvite} />
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  jobInvite: PropTypes.object,
  asMentor: PropTypes.bool,
}
