import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert, MegaTagTitle } from 'components/Elements'
import { LoginContainer } from 'features/auth/components'
import { useQuery } from 'hooks'

export const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const query = useQuery()

  const [alert, setAlert] = useState(null)

  const setAlertMessage = data => {
    setAlert(data)
  }

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (query.get('verified') === '1') {
      setAlertMessage({
        message: 'email_verification.success',
        color: 'success',
      })

      historyReplace()
    }
  }, [])

  return (
    <>
      <MegaTagTitle title={'login'} />

      {alert?.message && <Alert>{t(alert.message)}</Alert>}

      <LoginContainer />
    </>
  )
}
