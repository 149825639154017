import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const MentorsFilterFormSection = ({ mapFieldName }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const getFieldName = name => {
    return mapFieldName ? mapFieldName(name) : name
  }

  const {
    getLocationProvinces,
    getGroupedLocations,
    getProfileStudyArea,
    getProfileExperienceYears,
    getSkills,
    getIndustries,
    getTargetPositions,
    getLanguages,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={getFieldName(name)}
        control={control}
        component={Combobox}
        id={getFieldName(name)}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      <Row>
        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'industries',
            label: t('industry'),
            options: getIndustries(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'target_positions',
            label: t('position_of_expertise'),
            options: getTargetPositions(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_study_areas',
            label: t('areas_of_study'),
            options: getProfileStudyArea(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_skills',
            label: t('skills'),
            options: getSkills(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'languages',
            label: t('languages'),
            options: getLanguages(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'location_provinces',
            label: t('provinces'),
            options: getLocationProvinces(),
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'location_cities',
            label: t('cities'),
            options: getGroupedLocations(),
            isGrouped: true,
          })}
        </Col>

        <Col sm={6} lg={4} xl={3}>
          {renderSelectField({
            name: 'profile_experience_year',
            label: t('years_of_experience'),
            options: getProfileExperienceYears(),
            isMulti: false,
            normalize: option => (option !== null ? option.value : null),
            isClearable: true,
          })}
        </Col>
      </Row>
    </>
  )
}

MentorsFilterFormSection.propTypes = {
  mapFieldName: PropTypes.func,
}
