import React from 'react'
import { UserAlertsManager } from 'features/user/components'

export const PanelAlertsManager = () => {
  return (
    <>
      <UserAlertsManager />
    </>
  )
}

PanelAlertsManager.propTypes = {}
