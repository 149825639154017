import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from 'features/user'
import { GUARD, profileCompleteGuard } from '../../guards'

export const RouteGuard = ({ children, roles, guards = [], ...props }) => {
  const location = useLocation()

  const { user, isStudent } = useUser()

  const withGuard = type => {
    return guards.some(guard => guard === type)
  }

  if (roles && user && !roles.includes(user.role)) {
    const path = isStudent() ? '/jobs' : '/company/profile'
    return <Navigate to={path} state={{ from: location }} />
  } else if (withGuard(GUARD.PROFILE_COMPLETE) && !profileCompleteGuard.condition(user)) {
    return <Navigate to={profileCompleteGuard.redirectPath} state={{ from: location }} />
  }

  return <>{children}</>
}

RouteGuard.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array,
}
