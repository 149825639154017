import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { useFieldArray, useWatch } from 'react-hook-form'
import { IconPlus } from '@tabler/icons'

import {
  FormFieldController,
  Combobox,
  TextInput,
  FieldError,
  DatePicker,
  CheckboxField,
} from 'components/FormElements'
import { ActionIcon, Button } from 'components/Elements'
import { useSelectOptions } from 'hooks'
import { TextMaskUtils } from 'utils'

export const ProjectsFieldArray = props => {
  const { t } = useTranslation()

  const error = props?.meta?.error
  const fieldName = props.name

  const showError = Boolean(error)

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: 'fieldId',
  })

  const watchValue = useWatch({
    control: props.control,
    name: fieldName,
  })

  const { getMonths } = useSelectOptions()

  const addRow = e => {
    e.preventDefault()

    if (props.clearErrors) {
      props.clearErrors(fieldName)
    }
    append({
      title: '',
      date_start_month: null,
      date_start_year: null,
      date_end_month: null,
      date_end_year: null,
      is_present: false,
    })
  }

  const removeRow = index => {
    remove(index)
  }

  const workItemIsPresent = useCallback(
    index => {
      return watchValue[index]?.is_present
    },
    [watchValue]
  )

  const renderAdd = () => (
    <Row>
      <Col>
        <Button
          className={classNames('', {
            'is-invalid': showError,
          })}
          onClick={addRow}
          leftIcon={<IconPlus size={16} />}
          outline
          size={'md'}
        >
          {t('add_project')}
        </Button>
        <FieldError showError={showError} error={error} />
      </Col>
    </Row>
  )

  return (
    <Col>
      {fields.map((item, index) => (
        <Row key={item.fieldId}>
          <Col>
            <h5>
              {t('project')} {index + 1}
            </h5>
            <Row>
              <Col xs={10}>
                <Row>
                  <Col lg={12}>
                    <FormFieldController
                      name={`${fieldName}.${index}.title`}
                      control={props.control}
                      component={TextInput}
                      id={`${fieldName}.${index}.title`}
                      label={t('project_title')}
                      placeholder={t('project_title')}
                    />
                  </Col>
                </Row>

                <h6>{t('from')}</h6>
                <Row>
                  <Col lg={6}>
                    <FormFieldController
                      name={`${fieldName}.${index}.date_start_month`}
                      control={props.control}
                      component={Combobox}
                      id={`${fieldName}.${index}.date_start_month`}
                      label={t('month')}
                      placeholder={t('month')}
                      options={getMonths()}
                      isDetermineValue
                      normalize={option => (option !== null ? option.value : null)}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormFieldController
                      name={`${fieldName}.${index}.date_start_year`}
                      control={props.control}
                      component={DatePicker}
                      id={`${fieldName}.${index}.date_start_year`}
                      label={t('year')}
                      placeholder={'YYYY'}
                      showYearPicker
                      dateFormat="yyyy"
                      mask={TextMaskUtils.yearInputMask}
                      maxDate={new Date()}
                    />
                  </Col>
                </Row>

                {!workItemIsPresent(index) && (
                  <>
                    <h6>{t('to')}</h6>
                    <Row>
                      <Col lg={6}>
                        <FormFieldController
                          name={`${fieldName}.${index}.date_end_month`}
                          control={props.control}
                          component={Combobox}
                          id={`${fieldName}.${index}.date_end_month`}
                          label={t('month')}
                          placeholder={t('month')}
                          options={getMonths()}
                          isDetermineValue
                          normalize={option => (option !== null ? option.value : null)}
                          menuPortalTarget={document.querySelector('body')}
                        />
                      </Col>

                      <Col lg={6}>
                        <FormFieldController
                          name={`${fieldName}.${index}.date_end_year`}
                          control={props.control}
                          component={DatePicker}
                          id={`${fieldName}.${index}.date_end_year`}
                          label={t('year')}
                          placeholder={'YYYY'}
                          showYearPicker
                          dateFormat="yyyy"
                          mask={TextMaskUtils.yearInputMask}
                          maxDate={new Date()}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <FormFieldController
                    name={`${fieldName}.${index}.is_present`}
                    control={props.control}
                    component={CheckboxField}
                    id={`${fieldName}.${index}.is_present`}
                    label={t('present')}
                  />
                </Row>
              </Col>

              <Col xs={2}>
                <div className={'d-flex'}>
                  <div className={'mb-3 form-action-button-block'}>
                    <ActionIcon name={'remove'} onClick={() => removeRow(index)} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}

      {props.limit ? fields.length < props.limit && renderAdd() : renderAdd()}
    </Col>
  )
}

ProjectsFieldArray.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
