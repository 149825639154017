import * as Yup from 'yup'

import { formHelper } from 'features/profile/helpers'

const {
  avatarValidation,
  basicProfileValidation,
  educationWorkValidation,
  selfIdentificationValidation,
} = formHelper.studentProfile

const { generalValidation } = formHelper.general

export const validationSchema = Yup.object().shape({
  ...avatarValidation,

  ...generalValidation,

  student_profile: Yup.object().shape({
    ...basicProfileValidation,
    ...educationWorkValidation,
    ...selfIdentificationValidation,
  }),
})
