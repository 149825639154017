import * as Yup from 'yup'
import { DateUtils, FormatUtils, ValidationsUtils } from 'utils'
import { FILE_MAX_SIZE } from 'consts/core'

export const avatarValidation = {
  avatar: Yup.mixed()
    .nullable()
    .test('maxSize', 'file.max_size.error', value =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    )
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
}

export const basicProfileValidation = {
  schedule_link: Yup.string()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  profile_status_id: Yup.mixed().nullable().required('field.error.required'),
  profile_search_status_id: Yup.mixed().nullable().required('field.error.required'),
  location_province_id: Yup.mixed().nullable().required('field.error.required'),
  location_city_id: Yup.mixed().nullable().required('field.error.required'),
  location_country_id: Yup.mixed().nullable().required('field.error.required'),
  languages: Yup.mixed().nullable().required('field.error.required'),
  profile_work_authorizations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.mixed().nullable().required('field.error.required'),
      })
    )
    .min(1, 'field.error.required'),
  profile_objectives: Yup.mixed().nullable().required('field.error.required'),
  profile_willing_relocate_id: Yup.mixed().nullable().required('field.error.required'),
}

export const educationWorkValidation = {
  school_id: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  school_year: Yup.date()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  profile_study_program_id: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  profile_study_year_id: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  profile_study_area_id: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  skills: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  profile_experience_year_id: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  industries: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  target_positions: Yup.mixed()
    .nullable()
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.required('field.error.required') : schema
    ),
  student_works: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required('field.error.required'),
        // company: Yup.string().nullable(),
        location_country_id: Yup.mixed().nullable().required('field.error.required'),
        date_start_month: Yup.mixed().nullable().required('field.error.required'),
        date_start_year: Yup.date().nullable().required('field.error.required'),
        date_end_month: Yup.mixed()
          .nullable()
          .when('is_present', {
            is: is_present => !is_present,
            then: Yup.mixed().nullable().required('field.error.required'),
          }),
        date_end_year: Yup.date()
          .nullable()
          .when('is_present', {
            is: is_present => !is_present,
            then: Yup.date().nullable().required('field.error.required'),
          }),
      })
    )
    .when('$isMentor', (isMentor, schema) =>
      isMentor ? schema.min(1, 'field.error.required') : schema
    ),
  student_projects: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('field.error.required'),
      date_start_month: Yup.mixed().nullable().required('field.error.required'),
      date_start_year: Yup.date().nullable().required('field.error.required'),
      date_end_month: Yup.mixed()
        .nullable()
        .when('is_present', {
          is: is_present => !is_present,
          then: Yup.mixed().nullable().required('field.error.required'),
        }),
      date_end_year: Yup.date()
        .nullable()
        .when('is_present', {
          is: is_present => !is_present,
          then: Yup.date().nullable().required('field.error.required'),
        }),
    })
  ),
}

export const selfIdentificationValidation = {
  // profile_self_identifications: Yup.mixed().nullable().required('field.error.required'),
  // gender_id: Yup.mixed().nullable().required('field.error.required'),
  // ethnic_id: Yup.mixed().nullable().required('field.error.required'),
}

export const getStudentWorksDefaultValues = works => {
  if (works) {
    return works.map(({ location_country, date_start_year, date_end_year, ...restValue }) => ({
      ...restValue,
      location_country_id: location_country?.id || null,
      date_start_year: date_start_year ? DateUtils.yearToDate(date_start_year) : null,
      date_end_year: date_end_year ? DateUtils.yearToDate(date_end_year) : null,
    }))
  }

  return []
}

export const mapStudentWorksSubmitValues = works => {
  return works.map(
    ({ is_present, date_start_year, date_end_month, date_end_year, ...restValue }) => ({
      ...restValue,
      is_present,
      date_start_year: DateUtils.format(date_start_year, 'YYYY'),
      date_end_month: is_present ? null : date_end_month,
      date_end_year: is_present ? null : DateUtils.format(date_end_year, 'YYYY'),
    })
  )
}

export const getStudentProjectsDefaultValues = projects => {
  if (projects) {
    return projects.map(({ date_start_year, date_end_year, ...restValue }) => ({
      ...restValue,
      date_start_year: date_start_year ? DateUtils.yearToDate(date_start_year) : null,
      date_end_year: date_end_year ? DateUtils.yearToDate(date_end_year) : null,
    }))
  }

  return []
}

export const mapStudentProjectsSubmitValues = projects => {
  return projects.map(
    ({ is_present, date_start_year, date_end_month, date_end_year, ...restValue }) => ({
      ...restValue,
      is_present,
      date_start_year: DateUtils.format(date_start_year, 'YYYY'),
      date_end_month: is_present ? null : date_end_month,
      date_end_year: is_present ? null : DateUtils.format(date_end_year, 'YYYY'),
    })
  )
}

export const mapStudentWorkAuthorizationsSubmitValues = value => {
  return value.map(({ expiry_date, ...restValue }) => ({
    ...restValue,
    expiry_date: DateUtils.formatDateToServerFormat(expiry_date),
  }))
}

export const mapCandidateProfileDefaultValues = (user, params = { excludeBasicData: false }) => {
  const { student_profile, email, phone, avatar, first_name, last_name } = user

  const basicData = {
    first_name: first_name || '',
    last_name: last_name || '',
    email: email || '',
    phone: FormatUtils.formatPhone(phone) || '',
  }

  const selectDefaultValue = field => field?.id || null
  const multiSelectDefaultValue = value =>
    value && !!value.length ? value.map(item => item.id) : null

  const {
    profile_status,
    profile_search_status,
    location_province,
    location_city,
    location_country,
    languages,
    profile_work_authorizations,
    profile_objectives,
    profile_willing_relocate,
    school,
    school_year,
    profile_study_program,
    profile_study_year,
    profile_study_area,
    skills,
    profile_experience_year,
    industries,
    target_positions,
    student_works,
    student_projects,
    profile_certifications,
    profile_self_identifications,
    gender,
    ethnic,
    schedule_link,
    is_shared_mentor,
  } = student_profile

  const { excludeBasicData } = params

  const initialData = excludeBasicData ? {} : basicData

  return {
    ...initialData,
    avatar: avatar?.thumb || null,
    student_profile: {
      profile_status_id: selectDefaultValue(profile_status),
      profile_search_status_id: selectDefaultValue(profile_search_status),
      location_province_id: selectDefaultValue(location_province),
      location_city_id: selectDefaultValue(location_city),
      location_country_id: selectDefaultValue(location_country),
      languages: multiSelectDefaultValue(languages),
      profile_work_authorizations,
      profile_objectives: multiSelectDefaultValue(profile_objectives),
      profile_willing_relocate_id: selectDefaultValue(profile_willing_relocate),

      school_id: selectDefaultValue(school),
      school_year: school_year ? DateUtils.yearToDate(school_year) : null,
      profile_study_program_id: selectDefaultValue(profile_study_program),
      profile_study_year_id: selectDefaultValue(profile_study_year),
      profile_study_area_id: selectDefaultValue(profile_study_area),
      skills: multiSelectDefaultValue(skills),
      profile_experience_year_id: selectDefaultValue(profile_experience_year),
      industries: multiSelectDefaultValue(industries),
      target_positions: multiSelectDefaultValue(target_positions),
      student_works: getStudentWorksDefaultValues(student_works),
      student_projects: getStudentProjectsDefaultValues(student_projects),
      profile_certifications: profile_certifications || null,

      profile_self_identifications: multiSelectDefaultValue(profile_self_identifications),
      gender_id: selectDefaultValue(gender),
      ethnic_id: selectDefaultValue(ethnic),
      schedule_link,
      is_shared_mentor,
    },
  }
}
