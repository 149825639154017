import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { jobJobCandidatesApplicationsList } from 'features/job/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'
import { usePanelJob } from 'features/job/hooks'

const SpecificApplicationsContainerComponent = ({ status = null, queryParams = {}, ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { job } = usePanelJob()

  useEffect(() => {
    return () => {
      dispatch(jobJobCandidatesApplicationsList.resetDataState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page, queryParams])

  const fetchList = () => {
    let params = {
      page: props.page,
      sort: [{ id: 'application_date', desc: true }],
      ...queryParams,
    }

    dispatch(jobJobCandidatesApplicationsList.getList({ id: job?.id, params }))
  }

  const onPageChange = item => {
    dispatch(jobJobCandidatesApplicationsList.setPage(item.selected + 1))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid items={props.list} withApplication />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_applications_yet')}</NoDataTextMessage>
      )}
    </>
  )
}

SpecificApplicationsContainerComponent.propTypes = {
  queryParams: PropTypes.object,
}

const mapStateToProps = state => {
  const { list, loading, error, meta, page } = state.job.job.candidates.applications.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const SpecificApplicationsContainer = connect(mapStateToProps)(
  SpecificApplicationsContainerComponent
)
