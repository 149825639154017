import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { jobData } from 'features/job/store'
import { useJob } from 'features/job/hooks'
import { CloseControl } from 'features/job/components/JobActionControls'

export const Close = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { job } = useJob()

  const onSuccess = data => {
    dispatch(jobData.updateData({ ...data }))
  }

  return (
    <>
      <CloseControl job={job} onSuccess={onSuccess} className={'font-size-18 fw-medium'} />
    </>
  )
}

Close.propTypes = {}
