import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import s from './Basic.module.scss'
import { NavLink } from '../NavLink'
import { useUserSubscription } from 'features/user/hooks'
import { BILLING_SUBSCRIPTION_PERMISSION } from 'features/billing/consts/subscription'
import { Icon } from 'components/Elements'

export const BasicMenu = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const { isPermissionAllow } = useUserSubscription()

  const candidatesInvitesBlocked = !isPermissionAllow(BILLING_SUBSCRIPTION_PERMISSION.JOB_INVITE)

  const employerNavs = [
    {
      title: t('company'),
      menu: [
        {
          path: '/company/profile',
          label: t('company_profile'),
        },
        {
          path: '/billing/subscription',
          label: t('subscription'),
        },
      ],
    },
    {
      title: t('candidates'),
      menu: [
        {
          path: '/candidates',
          label: t('browse_candidates'),
          activeCondition: () => pathname === '/candidates',
        },
        {
          path: '/candidates/invites',
          label: (
            <>
              {t('candidate_invites')}
              {candidatesInvitesBlocked && (
                <span>
                  {' '}
                  <Icon name={'blocked'} className={'font-size-22'} />
                </span>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: t('my_jobs'),
      menu: [
        {
          path: '/jobs/my/active',
          label: t('active'),
        },
        {
          path: '/jobs/my/drafts',
          label: t('draft'),
        },
        {
          path: '/jobs/my/closed',
          label: t('closed'),
        },
      ],
    },
  ]

  return (
    <>
      {employerNavs.map(({ title, menu }, i) => (
        <div className={s.navBlock} key={i}>
          <div className={s.navBlock__title}>{title}</div>
          <div className={s.navBlock__menu}>
            {menu
              .filter(item => !item.hidden)
              .map((item, index) => (
                <NavLink key={index} {...item} />
              ))}
          </div>
        </div>
      ))}
    </>
  )
}
