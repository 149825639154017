import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { TabsNav } from 'components/Elements'

const TABS_KEY = {
  ALL: 'all',
  RECOMMENDED: 'recommended',
}

const { ALL, RECOMMENDED } = TABS_KEY

export const Tabs = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const basePath = `/jobs`

  const TABS_CONFIG = {
    [ALL]: {
      label: t('newest'),
      value: ALL,
      path: basePath,
    },
    [RECOMMENDED]: {
      label: t('recommended_jobs'),
      value: RECOMMENDED,
      path: `${basePath}/recommended`,
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [])

  const onTabChange = value => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (pathName === item.path) {
        return item.value
      }
    }

    return ''
  }, [location])

  return <TabsNav variant={'nav'} options={tabs} active={tabsValue} onChange={onTabChange} />
}

Tabs.propTypes = {}
