import React from 'react'

export const useJobPermissions = () => {
  const isPostingAllow = job => {
    if (job) {
      return job?.featured
    }
    return false
  }

  const isRecommendationAllow = job => {
    if (job) {
      return job?.featured
    }
    return false
  }

  return {
    isPostingAllow,
    isRecommendationAllow,
  }
}
