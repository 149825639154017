import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { authAPI } from '../../api'
import {
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
} from './actionTypes'

export const authResetPasswordPA = createPromiseAction(
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILED
)()

function* worker(action) {
  try {
    const response = yield call(authAPI.resetPassword, action.payload)
    const { data } = response

    yield put(authResetPasswordPA.success({ ...data }))
    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* resetPasswordSaga() {
  yield takeEvery(authResetPasswordPA.request, worker)
}
