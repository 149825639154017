import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './InfoBlock.module.scss'
import { ReadMoreExpand } from 'components/Elements'

export const InfoBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { message } = data

  return message ? (
    <div className={'mt-2'}>
      <div className={s.info}>
        <ReadMoreExpand maxHeight={64} lineHeight={16}>
          {message}
        </ReadMoreExpand>
      </div>
    </div>
  ) : null
}

InfoBlock.propTypes = {
  data: PropTypes.object,
}
