import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { EmployerRoot } from '../employer'
import { Employers } from '../employers'

export const EmployersRoot = () => {
  return (
    <Routes>
      <Route index element={<Employers />} />

      <Route path={':id/*'} element={<EmployerRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

EmployersRoot.propTypes = {}
