import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { profileCompanyBannerAPI } from 'features/profile/api'
import { userData } from 'features/user/store'
import {
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_REQUEST,
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_SUCCESS,
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_FAILED,
} from './actionTypes'

export const profileEmployerCompanyBannerUploadPA = createPromiseAction(
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_REQUEST,
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_SUCCESS,
  PROFILE_EMPLOYER_COMPANY_BANNER_UPLOAD_FAILED
)()

function* upload(action) {
  try {
    const response = yield call(profileCompanyBannerAPI.uploadBanner, action.payload)
    const { data } = response

    yield put(profileEmployerCompanyBannerUploadPA.success({}))

    yield put(userData.updateEmployerProfile({ company_banner: data?.data }))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* uploadSaga() {
  yield takeEvery(profileEmployerCompanyBannerUploadPA.request, upload)
}
