import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import s from './Icon.module.scss'

import loveLetter from 'assets/images/illustrations/love-letter.png'
import briefcase from 'assets/images/illustrations/briefcase.png'
import electricLightBulb from 'assets/images/illustrations/electric-light-bulb.png'
import handshake from 'assets/images/illustrations/handshake.png'
import globeShowingAmericas from 'assets/images/illustrations/globe-showing-americas.png'

export const Icon = ({ name }) => {
  switch (name) {
    case 'love-letter':
      return <img src={loveLetter} alt={'icon'} height={30} />

    case 'briefcase':
      return <img src={briefcase} alt={'icon'} height={26} />

    case 'electric-light-bulb':
      return <img src={electricLightBulb} alt={'icon'} height={26} />

    case 'handshake':
      return <img src={handshake} alt={'icon'} height={32} />

    case 'globe-showing-americas':
      return <img src={globeShowingAmericas} alt={'icon'} height={30} />

    default:
      return null
  }
}

Icon.propTypes = {
  name: PropTypes.oneOf([
    'love-letter',
    'briefcase',
    'electric-light-bulb',
    'handshake',
    'globe-showing-americas',
  ]),
}
