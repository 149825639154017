import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPencil } from '@tabler/icons'
import { useSelector } from 'react-redux'

import { Anchor } from 'components/Elements'

export const Candidates = () => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.job.data)

  return (
    <Anchor to={`/jobs/${data?.id}/candidates`} className="font-size-18 fw-medium text-capitalize">
      {t('browse_candidates')}
    </Anchor>
  )
}

Candidates.propTypes = {}
