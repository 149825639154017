import React from 'react'

import { useUser, useUserSubscription } from 'features/user/hooks'
import { useJobPermissions } from 'features/job/hooks'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'
import {
  BILLING_SUBSCRIPTION_PERMISSION,
  BILLING_SUBSCRIPTION_FREE_MONTH_POSTED_JOBS_COUNT,
} from 'features/billing/consts/subscription'

const { FREE } = BILLING_TARIFF_ALIAS

export const useUserPermissions = () => {
  const { user } = useUser()

  const { isPostingAllow, isRecommendationAllow } = useJobPermissions()

  const { alias: subscriptionPlanAlias, isPermissionAllow, credits } = useUserSubscription()

  const isSubscriptionJobPostingAllow = () => {
    if (subscriptionPlanAlias === FREE) {
      return user?.month_posted_jobs_count < BILLING_SUBSCRIPTION_FREE_MONTH_POSTED_JOBS_COUNT
    }
    return true
  }

  const isPaidJobPostingAllow = () => {
    // if has credits for job posting
    return !!credits
  }

  const isJobPostingAllow = (job = null) => {
    let jobAllow = false

    if (job) {
      jobAllow = isPostingAllow(job)
    }

    return jobAllow || isSubscriptionJobPostingAllow() || isPaidJobPostingAllow()
  }

  const isJobRecommendationAllow = (job = null) => {
    let jobAllow = false

    if (job) {
      jobAllow = isRecommendationAllow(job)
    }

    return jobAllow || isPermissionAllow(BILLING_SUBSCRIPTION_PERMISSION.JOB_RECOMMENDATION)
  }

  return {
    isSubscriptionJobPostingAllow,
    isJobPostingAllow,
    isJobRecommendationAllow,
    isPaidJobPostingAllow,
  }
}
