import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { TextInput, FormFieldController, PhoneInput } from 'components/FormElements'
import { useFormSubmit } from 'hooks'
import { Attachments } from './Attachments'

export const JobApplicationForm = ({ initialValues, ...props }) => {
  const { t } = useTranslation()

  const defaultValues = {
    email: '',
    phone: '',
    resume: null,
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      const { phone, resume, ...restValues } = data

      let values = {
        phone: phone.replace(/[^0-9]/g, ''),
        ...restValues,
      }

      if (resume) {
        if (resume instanceof File) {
          values.resume = resume
        }
      } else {
        values.resume = ''
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFieldController
            name={'email'}
            control={control}
            component={TextInput}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
          />

          <FormFieldController
            name={'phone'}
            control={control}
            component={PhoneInput}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
          />

          <Attachments />

          <div className="mt-3 pt-3">
            <div className="text-center">
              <Button type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
                {t('confirm_and_proceed')}
              </Button>
            </div>

            <div className="mt-3 mb-3 text-center">{t('job_apply.create.note')}</div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

JobApplicationForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
}
