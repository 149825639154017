import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Subscription } from 'features/billing/routes/subscription'

export const BillingRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={'/billing/subscription'} replace={true} />} />

      <Route path={'subscription'} element={<Subscription />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

BillingRoot.propTypes = {}
