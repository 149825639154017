import React from 'react'
import { useDispatch } from 'react-redux'

import { useUserSubscription, useUserPermissions } from 'features/user/hooks'
import { useJobPermissions } from 'features/job/hooks'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'
import { userData, userSync } from 'features/user/store'

export const useJobPosting = () => {
  const dispatch = useDispatch()

  const { alias } = useUserSubscription()

  const { isPaidJobPostingAllow } = useUserPermissions()

  const { isPostingAllow: isJobPostingAllow } = useJobPermissions()

  const isPaidPosting = job => {
    return !isJobPostingAllow(job) && alias === BILLING_TARIFF_ALIAS.FREE && isPaidJobPostingAllow()
  }

  const onPostingSuccess = job => {
    // if is paid for credits
    if (isPaidPosting(job)) {
      dispatch(userData.decreaseSubscriptionCredits(1))

      if (job) {
        // fetch user data for updating statistics
        // need for update month posted jobs count (if posted monthly free job)
        dispatch(userSync.getData({}))
      }
    } else if (!isJobPostingAllow(job)) {
      dispatch(userData.increaseMonthPostedJobs(1))
    }
  }

  return {
    isPaidPosting,
    onPostingSuccess,
  }
}
