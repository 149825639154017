import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const JobFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getJobTypes } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={name}
        control={control}
        component={Combobox}
        id={name}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      {renderSelectField({
        name: 'job_types',
        label: t('types_of_jobs'),
        options: getJobTypes(),
      })}
    </>
  )
}

JobFormSection.propTypes = {}
