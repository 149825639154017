import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, CardBody, Card } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authResetPasswordPA } from '../../../store'

import { ResetPasswordForm } from './ResetPasswordForm'
import { Alert } from 'components/Elements'
import { useQuery } from 'hooks'

export const ResetPasswordContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const query = useQuery()
  const { token } = useParams()

  const [success, setSuccess] = useState(null)

  const onSubmit = async values => {
    setSuccess(null)

    const params = {
      ...values,
      token: token || '',
      email: query.get('email') || '',
    }

    const { message } = await dispatch(authResetPasswordPA.request(params))

    setSuccess(message || t('success'))
  }

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col lg={8} xl={6}>
        <Card className="overflow-hidden">
          <div>
            <Row>
              <Col>
                <div className="text-center p-4">
                  <h3 className={'mb-0'}>{t('reset_password')}</h3>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            {success && <Alert>{success}</Alert>}

            <ResetPasswordForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
        <div className="text-center">
          <p>
            {t('back_to')}{' '}
            <Link to="/login" className="fw-medium text-primary">
              {t('login')}
            </Link>
          </p>
        </div>
      </Col>
    </Row>
  )
}
