import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'

import { jobJobsSpecificList } from 'features/job/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { JobsGrid } from 'features/job/components/Jobs/Grids'
import { GeneralJobsFilter } from 'features/job/components/Jobs/Filters'

const SavedJobsContainerComponent = ({ queryParams, withFilter = true, ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobJobsSpecificList.resetDataState({}))
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 20,
      ...queryParams,
    }

    dispatch(jobJobsSpecificList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobJobsSpecificList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(jobJobsSpecificList.changeFilter({ ...values }))
  }

  const onJobSavedSuccess = data => {
    dispatch(jobJobsSpecificList.updateListItem({ ...data }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      {withFilter && (
        <div className={'mb-2'}>
          <GeneralJobsFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
        </div>
      )}

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <JobsGrid items={props.list} saveControlProps={{ onSuccess: onJobSavedSuccess }} />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_jobs')}</NoDataTextMessage>
      )}
    </>
  )
}

SavedJobsContainerComponent.propTypes = {
  queryParams: PropTypes.object,
  withFilter: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.job.jobs.specific.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const SpecificJobsContainer = connect(mapStateToProps)(SavedJobsContainerComponent)
