import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useDispatch } from 'react-redux'

import { PasswordForm } from './PasswordForm'
import { profilePasswordUpdatePA } from '../../../store'
import { useNotify } from 'hooks'

export const PasswordContainer = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    await dispatch(profilePasswordUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('password_change_successfully'),
    })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t('password')}</CardTitle>
        </CardHeader>
        <CardBody>
          <PasswordForm onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  )
}

PasswordContainer.propTypes = {}
