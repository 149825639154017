import { combineReducers } from 'redux'

import my from './my/reducers'
import edit from './edit/reducers'
import data from './data/slice'
import jobs from './jobs/reducers'
import candidates from './candidates/reducers'
import panel from './panel/reducers'
import job from './job/reducers'

const jobReducer = combineReducers({
  my,
  edit,
  data,
  jobs,
  candidates,
  panel,
  job,
})

export default jobReducer
