import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const jobInviteJobRecommendationsMyListSlice = createSlice({
  name: 'jobInviteJobRecommendationsMyList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setPage
 * @property {function} updateListItem
 * @property {function} cleanState
 */

export const jobInviteJobRecommendationsMyList = jobInviteJobRecommendationsMyListSlice.actions

export default jobInviteJobRecommendationsMyListSlice.reducer
