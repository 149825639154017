import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor } from 'components/Elements'
import { useJob } from 'features/job/hooks'

export const ApplicationsBlock = () => {
  const { t } = useTranslation()

  const { job } = useJob()

  return (
    <div className={'d-flex gap-1 flex-wrap justify-content-between'}>
      <p className={'mb-0 font-size-18 fw-medium'}>{t('applications')}</p>

      <Anchor to={`/jobs/${job?.id}/candidates/applications`}>{t('view')}</Anchor>
    </div>
  )
}

ApplicationsBlock.propTypes = {}
