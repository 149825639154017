import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { useFieldArray, useWatch } from 'react-hook-form'
import { IconPlus } from '@tabler/icons'

import { FormFieldController, Combobox, FieldError, DatePicker } from 'components/FormElements'
import { ActionIcon, Button } from 'components/Elements'
import { useSelectOptions } from 'hooks'

export const WorkAuthorizationsFieldArray = props => {
  const { t } = useTranslation()

  const error = props?.meta?.error
  const fieldName = props.name

  const showError = Boolean(error)

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: 'fieldId',
  })

  const watchValue = useWatch({
    control: props.control,
    name: fieldName,
  })

  const { getProfileWorkAuthorizations } = useSelectOptions()

  const addRow = e => {
    e.preventDefault()

    if (props.clearErrors) {
      props.clearErrors(fieldName)
    }
    append({
      id: null,
      expiry_date: null,
    })
  }

  const removeRow = index => {
    remove(index)
  }

  const renderAdd = () => (
    <Row>
      <Col>
        <Button
          className={classNames('', {
            'is-invalid': showError,
          })}
          onClick={addRow}
          leftIcon={<IconPlus size={16} />}
          outline
          size={'md'}
        >
          {t('add_work_authorization')}
        </Button>
        <FieldError showError={showError} error={error} />
      </Col>
    </Row>
  )

  const authorizationsSelectedOptions = useMemo(() => {
    let options = []
    watchValue.forEach(({ id }) => {
      const result = getProfileWorkAuthorizations().filter(item => item.value === id)
      options = [...options, ...result]
    })

    return options
  }, [watchValue])

  const isSingleMode = useMemo(() => {
    if (authorizationsSelectedOptions && !!authorizationsSelectedOptions.length) {
      return authorizationsSelectedOptions[0].weight === 2
    }
    return false
  }, [authorizationsSelectedOptions])

  const limit = useMemo(() => {
    return isSingleMode ? 1 : props.limit
  }, [props.limit, isSingleMode])

  const onAuthorizationChange = (option, index) => {
    // if "don't have permit" -> set single item value
    if (option?.weight === 2) {
      props.setValue(fieldName, [{ id: option.value, expiry_date: null }])
    } else {
      props.setValue(`${fieldName}.${index}.id`, option.value)
    }
  }

  return (
    <Col>
      {fields.map((item, index) => (
        <Row key={item.fieldId}>
          <Col>
            <Row>
              <Col xs={10} lg={12}>
                <Row>
                  <Col lg={4}>
                    <FormFieldController
                      name={`${fieldName}.${index}.id`}
                      control={props.control}
                      component={Combobox}
                      id={`${fieldName}.${index}.id`}
                      label={t('work_authorization')}
                      placeholder={t('work_authorization')}
                      options={getProfileWorkAuthorizations()}
                      isDetermineValue
                      // normalize={option => (option !== null ? option.value : null)}
                      menuPortalTarget={document.querySelector('body')}
                      isOptionDisabled={(option, selectValue) => {
                        if (selectValue && selectValue.length) {
                          return option.weight !== selectValue[0]?.weight
                        }
                        return false
                      }}
                      normalize={null}
                      onChange={option => onAuthorizationChange(option, index)}
                    />
                  </Col>

                  {!isSingleMode && (
                    <Col lg={4}>
                      <FormFieldController
                        name={`${fieldName}.${index}.expiry_date`}
                        control={props.control}
                        component={DatePicker}
                        id={`${fieldName}.${index}.expiry_date`}
                        label={t('expire_date_permit')}
                        placeholder={'DD/MM/YYYY'}
                      />
                    </Col>
                  )}

                  <Col lg={4} className={'d-none d-lg-block'}>
                    <div className={'d-flex'}>
                      <div className={'mb-3 form-action-button-block'}>
                        <ActionIcon name={'remove'} onClick={() => removeRow(index)} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={2} className={'d-lg-none'}>
                <div className={'d-flex'}>
                  <div className={'mb-3 form-action-button-block'}>
                    <ActionIcon name={'remove'} onClick={() => removeRow(index)} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}

      {limit ? fields.length < limit && renderAdd() : renderAdd()}
    </Col>
  )
}

WorkAuthorizationsFieldArray.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
  setValue: PropTypes.func,
}
