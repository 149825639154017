import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Badge } from '../Badge'
import { Mark } from '../Mark'
import { FormatUtils } from 'utils'
import { useUser } from 'features/user/hooks'

export const InvitesBadge = ({ type = 'badge' }) => {
  const { user } = useUser()

  const count = user?.unread_invites_count || 0

  const format = useMemo(() => {
    return FormatUtils.truncateNumber(count, 999)
  }, [count])

  return count ? <>{type === 'badge' ? <Badge>{format}</Badge> : <Mark />}</> : null
}

InvitesBadge.propTypes = {
  type: PropTypes.oneOf(['badge', 'mark']),
}
