import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import { SpecificJobsContainer } from 'features/job/components/MyJobs'
import { JOB_STATUS } from 'features/job/consts/general'

export const Drafts = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'drafts'} />

      <Row>
        <Col>
          <SpecificJobsContainer status={JOB_STATUS.DRAFT} />
        </Col>
      </Row>
    </>
  )
}

Drafts.propTypes = {}
