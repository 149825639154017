import { all } from 'redux-saga/effects'

import { listSaga } from './list/saga'
import { savedSaga } from './saved/sagas'
import { appliedSaga } from './applied/sagas'
import { specificSaga } from './specific/sagas'

export function* jobsSaga() {
  yield all([listSaga(), savedSaga(), appliedSaga(), specificSaga()])
}
