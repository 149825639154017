import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { jobInviteInvitesReceivedList } from 'features/job-invite/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'

const ReceivedInvitesContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobInviteInvitesReceivedList.cleanState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      sort: [{ id: 'created_at', desc: true }],
    }

    dispatch(jobInviteInvitesReceivedList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobInviteInvitesReceivedList.setPage(item.selected + 1))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid items={props.list} />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_invites_yet')}</NoDataTextMessage>
      )}
    </>
  )
}

ReceivedInvitesContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page } = state.jobInvite.invites.received.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const ReceivedInvitesContainer = connect(mapStateToProps)(ReceivedInvitesContainerComponent)
