import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const JobsLayout = () => {
  const { t } = useTranslation()

  return (
    <>
      <Outlet />
    </>
  )
}

JobsLayout.propTypes = {}
