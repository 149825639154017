import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { useAsyncDispatch, useNotify } from 'hooks'
import { AttachmentManagerControl } from 'components/Controls'
import { useUser } from 'features/user'
import { profileStudentResumeUploadPA, profileStudentResumeRemovePA } from 'features/profile/store'
import { FileUtils } from 'utils'

export const StudentAttachmentsManager = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const { user } = useUser()

  const resume = user?.student_profile?.resume

  const onUpload = async file => {
    try {
      await onAsyncDispatch(profileStudentResumeUploadPA.request, { resume: file })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const onRemove = async () => {
    try {
      await onAsyncDispatch(profileStudentResumeRemovePA.request)
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const onDownload = file => {
    FileUtils.downloadFileByLink(file?.url)
  }

  return (
    <>
      <h5>{t('resume')}</h5>
      <Row>
        <Col>
          <AttachmentManagerControl
            value={resume}
            accept={
              'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
            }
            loading={loading}
            onRemove={onRemove}
            onUpload={onUpload}
            onDownload={onDownload}
            buttonLabel={t('upload_resume')}
          />
        </Col>
      </Row>
    </>
  )
}
