import { all } from 'redux-saga/effects'

import { createSaga } from './create/saga'
import { mySaga } from './my/sagas'
import { editSaga } from './edit/sagas'
import { statusSaga } from './status/sagas'
import { dataSaga } from './data/saga'
import { jobsSaga } from './jobs/sagas'
import { savedSaga } from './saved/sagas'
import { candidatesSaga } from './candidates/sagas'
import { panelSaga } from './panel/sagas'
import { inviteSaga } from './invite/sagas'
import { applicationSaga } from './application/sagas'
import { jobSaga as currentJobSaga } from './job/sagas'

export function* jobSaga() {
  yield all([
    createSaga(),
    mySaga(),
    editSaga(),
    statusSaga(),
    dataSaga(),
    jobsSaga(),
    savedSaga(),
    candidatesSaga(),
    panelSaga(),
    inviteSaga(),
    applicationSaga(),
    currentJobSaga(),
  ])
}
