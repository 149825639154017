import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import { RegisterContainer } from 'features/auth/components'
import { ROLE } from 'features/user'

export const RegisterMentor = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'mentor_registration'} />

      <Row className="align-items-center justify-content-center h-100">
        <Col lg={7}>
          <RegisterContainer role={ROLE.STUDENT} isMentor />
        </Col>
      </Row>
    </>
  )
}
