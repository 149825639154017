import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Elements'

export const FileButtonControl = ({
  onChange,
  label,
  accept,
  multiple = false,
  buttonProps = {},
}) => {
  const { t } = useTranslation()

  const inputRef = useRef(null)

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onInputChange = event => {
    if (!event.target.files) return

    const file = event.target.files[0]

    onChange && onChange(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  label = label || t('upload_file')

  return (
    <>
      <input
        type="file"
        onChange={onInputChange}
        ref={inputRef}
        accept={accept}
        className={'d-none'}
      />
      <Button onClick={handleClick} outline size={'md'} {...buttonProps}>
        {label}
      </Button>
    </>
  )
}

FileButtonControl.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.node,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  buttonProps: PropTypes.object,
}
