import axios from 'axios'

class ImmigrateCategoriesAPI {
  getCategories = async params => {
    return axios.get(`api/immigrate-categories`, {
      params,
    })
  }
}

export const immigrateCategoriesAPI = new ImmigrateCategoriesAPI()
