import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Row, Col, Label } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import { Button, Alert, Icon } from 'components/Elements'
import { validationSchema } from './validation'
import { Combobox, FormFieldController, TextInput, CheckboxField } from 'components/FormElements'
import { useSelectOptions, useFormSubmit } from 'hooks'
import { PayRangeFormSection } from './PayRangeFormSection'
import { LocationFormSection } from './LocationFormSection'
import { CertificationsFormSection } from './CertificationsFormSection'
import { JOB_STATUS } from 'features/job/consts/general'
import { statusHelper } from 'features/job/helpers'
// import { useJobPermissions } from 'features/job/hooks'
import { PaidJobBadge } from 'features/job/components/Elements'

export const JobForm = ({ initialValues, isEdit, isPostingAllow = true, ...props }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [jobStatus, setJobStatus] = useState(JOB_STATUS.ACTIVE)

  const defaultValues = {
    title: '',
    is_remote: false,
    multiple_locations: false,
    location_province_id: null,
    location_city_id: null,
    profile_experience_year_id: null,
    pay_range_min: '',
    pay_range_max: '',
    job_type_id: null,
    about_this_role: '',
    profile_study_program_id: null,
    profile_study_area_id: null,
    skills: null,
    languages: null,
    profile_certifications: null,
    job_url: '',
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async (data, status) => {
    setJobStatus(status)

    try {
      const { is_remote, multiple_locations, location_province_id, location_city_id, ...restData } =
        data

      const withLocation = isSpecificLocation(is_remote, multiple_locations)

      const values = {
        is_remote,
        multiple_locations,
        location_province_id: withLocation ? location_province_id : null,
        location_city_id: withLocation ? location_city_id : null,
        ...restData,
        status,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  // // detect watch('type) -> allow depend on type value
  // const { isPostingAllow: isJobPostingPermissionAllow } = useJobPermissions()

  const postingBlocked = useMemo(() => {
    let result = !isPostingAllow

    if (result && isEdit) {
      result = initialValues?.status !== JOB_STATUS.ACTIVE
    }

    return result
  }, [isPostingAllow, isEdit, initialValues?.status])

  const {
    getProfileExperienceYears,
    getJobTypes,
    getProfileStudyPrograms,
    getProfileStudyArea,
    getSkills,
    getLanguages,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <FormFieldController
      name={name}
      control={control}
      component={Combobox}
      id={name}
      label={label}
      placeholder={label}
      options={options}
      isDetermineValue
      normalize={option => (option !== null ? option.value : null)}
      {...params}
    />
  )

  const watchIsRemote = watch('is_remote')
  const watchMultipleLocations = watch('multiple_locations')

  const isSpecificLocation = (isRemote, isMultiple) => !(isRemote || isMultiple)

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <Row>
          <Col sm={8}>
            <form>
              {isEdit && (
                <>
                  {initialValues?.featured && (
                    <div className={'mb-3'}>
                      <PaidJobBadge size={'xs'} />
                    </div>
                  )}

                  <Label className={'mb-3'}>
                    {t('status')}: {statusHelper.general.getStatus(initialValues?.status)?.label}
                  </Label>
                </>
              )}

              <FormFieldController
                name={'title'}
                control={control}
                component={TextInput}
                id={'title'}
                label={t('job_title')}
                placeholder={t('job-title-field-placeholder')}
              />

              <Row>
                <Col md={4}>
                  <FormFieldController
                    name={'is_remote'}
                    control={control}
                    component={CheckboxField}
                    id={'is_remote'}
                    label={t('remote')}
                  />
                </Col>

                <Col md={8}>
                  <FormFieldController
                    name={'multiple_locations'}
                    control={control}
                    component={CheckboxField}
                    id={'multiple_locations'}
                    label={t('multiple_locations')}
                  />
                </Col>
              </Row>

              {isSpecificLocation(watchIsRemote, watchMultipleLocations) && <LocationFormSection />}

              <Row>
                <Col lg={6}>
                  {renderSelectField({
                    name: 'profile_experience_year_id',
                    label: t('experience_level'),
                    options: getProfileExperienceYears(),
                    isSearchable: false,
                  })}
                </Col>
              </Row>

              <PayRangeFormSection />

              <Row>
                <Col lg={6}>
                  {renderSelectField({
                    name: 'job_type_id',
                    label: t('type'),
                    options: getJobTypes(),
                    isSearchable: false,
                  })}
                </Col>
              </Row>

              <FormFieldController
                name={'about_this_role'}
                control={control}
                component={TextInput}
                id={'about_this_role'}
                label={t('about_this_role')}
                placeholder={t('about_this_role')}
                type={'textarea'}
              />

              <Row>
                <Col lg={6}>
                  {renderSelectField({
                    name: 'profile_study_program_id',
                    label: t('minimum_education_requirement'),
                    options: getProfileStudyPrograms(),
                  })}
                </Col>
                <Col lg={6}>
                  {renderSelectField({
                    name: 'profile_study_area_id',
                    label: t('preferred_area_of_study'),
                    options: getProfileStudyArea({ extended: true }),
                  })}
                </Col>
              </Row>

              {renderSelectField({
                name: 'skills',
                label: t('must-have_skills'),
                options: getSkills(),
                isMulti: true,
                normalize: value => value.map(item => item.value),
              })}

              {renderSelectField({
                name: 'languages',
                label: t('required_languages'),
                options: getLanguages(),
                isMulti: true,
                normalize: value => value.map(item => item.value),
              })}

              <div className={'mb-3'}>
                <CertificationsFormSection />
              </div>

              <FormFieldController
                name={'job_url'}
                control={control}
                component={TextInput}
                id={'job_url'}
                label={t('original_job_url')}
                placeholder={t('original_job_url')}
              />

              <div className="mt-3 pt-3">
                <div className="button-items">
                  <Button
                    className={'text-camelcase'}
                    disabled={isSubmitting}
                    loading={isSubmitting && jobStatus === JOB_STATUS.ACTIVE}
                    onClick={() => {
                      if (postingBlocked) {
                        navigate('/billing/subscription')
                      } else {
                        return handleSubmit(data => onSubmit(data, JOB_STATUS.ACTIVE))()
                      }
                    }}
                    rightIcon={postingBlocked ? <Icon name={'blocked'} /> : null}
                  >
                    {isEdit && initialValues?.status === JOB_STATUS.ACTIVE
                      ? t('save')
                      : t('post_job')}
                  </Button>

                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting && jobStatus === JOB_STATUS.DRAFT}
                    onClick={() => handleSubmit(data => onSubmit(data, JOB_STATUS.DRAFT))()}
                    outline
                  >
                    {t('save_to_draft')}
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </FormProvider>
    </>
  )
}

JobForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  isPostingAllow: PropTypes.bool,
}
