import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button, Modal } from 'components/Elements'
import { useNotify, usePopup, useSelectOptions, useAsyncDispatch } from 'hooks'
import { profileStudentUpdatePA } from 'features/profile/store'
import { useUser } from 'features/user/hooks'
import { userData } from 'features/user/store'
import { DateUtils } from 'utils'

export const CandidateJobSearchStatus = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { showNotification } = useNotify()

  const { visible, open, close } = usePopup()

  const { user } = useUser()

  const { getProfileSearchStatuses } = useSelectOptions()

  const updateLastActivityRef = useRef(true)

  const updateLastActivity = () => {
    dispatch(userData.updateData({ last_activity: DateUtils.formatDateToServerFormat(new Date()) }))
  }

  useEffect(() => {
    open()
  }, [])

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onAction = async values => {
    const { id } = values

    const { profile_search_status } = user?.student_profile

    if (profile_search_status && profile_search_status?.id !== id) {
      try {
        await onAsyncDispatch(profileStudentUpdatePA.request, {
          student_profile: { profile_search_status_id: id },
        })

        updateLastActivityRef.current = false

        showNotification({
          type: 'success',
          message: t('saved_successfully'),
        })

        close()
      } catch (error) {
        showNotification({
          type: 'error',
          message: error?.message || t('error'),
        })
      }
    } else {
      close()
    }
  }

  const onClosed = () => {
    if (updateLastActivityRef.current) {
      updateLastActivity()
    }
  }

  return (
    <>
      <Modal size={'lg'} visible={visible} centered onClose={close} onClosed={onClosed}>
        <p className={'font-size-22 text-center'}>{t('job_search_status-confirmation-text')}</p>

        <div className="mt-4 d-flex justify-content-between">
          {getProfileSearchStatuses().map((item, i) => (
            <Button
              disabled={loading}
              key={i}
              outline={i !== 0}
              color={i !== 0 ? 'light' : 'primary'}
              onClick={() => onAction(item)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      </Modal>
    </>
  )
}

CandidateJobSearchStatus.propTypes = {}
