import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col>
            <div className="text-sm-end d-none d-sm-block">
              <Link to={'/privacy'} className={'me-3 text-secondary'} target={'_blank'}>
                {t('privacy_policy')}
              </Link>
              <Link to={'/terms'} className={'text-secondary'} target={'_blank'}>
                {t('terms_of_use')}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
