import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { IconChevronLeft } from '@tabler/icons'

import { WizardSteps, Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import {
  EducationWorkFormSection,
  SelfIdentificationFormSection,
} from 'features/profile/components/FormSections/StudentProfileFormSections'
import { useUser } from 'features/user'
import { ValidationsUtils, DateUtils } from 'utils'
import { formHelper } from 'features/profile/helpers/form'
import { Layout } from './Layout'
import { BasicProfileSections } from './Sections'

const { studentProfile } = formHelper

export const WizardForm = props => {
  const { t } = useTranslation()

  const { user, isMentor } = useUser()

  const steps = [
    {
      label: t('basic_profile'),
      content: <BasicProfileSections />,
    },
    {
      label: t('work_and_education_details'),
      content: <EducationWorkFormSection isMentor={isMentor()} />,
    },
    {
      label: t('self_identified_demographic_information'),
      subtitle: <p className={'font-size-14 text-muted'}>{t('self_identified.disclaimer')}</p>,
      content: <SelfIdentificationFormSection />,
    },
  ]

  const defaultValues = {
    ...studentProfile.mapCandidateProfileDefaultValues(user, { excludeBasicData: true }),
  }

  const [step, setStep] = useState(0)

  const currentValidationSchema = validationSchema[step]

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
    context: { isMentor: isMentor() },
  })

  const {
    handleSubmit,
    trigger,
    setError,
    formState: { isSubmitting },
  } = methods

  const onStepClick = value => {
    setStep(value)
  }

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) setStep(prevState => prevState + 1)
  }

  const handleBack = () => {
    setStep(prevState => prevState - 1)
  }

  function getStepContent(step) {
    return steps[step]
  }

  const [alertError, setAlertError] = useState(null)

  const onSubmit = async data => {
    setAlertError(null)
    try {
      const { avatar, student_profile, ...restValues } = data
      const {
        profile_work_authorizations,
        school_year,
        student_works,
        student_projects,
        schedule_link,
        is_shared_mentor,
        ...restStudentProfileValues
      } = student_profile

      let studentProfileParams = {
        ...restStudentProfileValues,
        profile_work_authorizations: studentProfile.mapStudentWorkAuthorizationsSubmitValues(
          profile_work_authorizations
        ),
        student_works: studentProfile.mapStudentWorksSubmitValues(student_works),
        student_projects: studentProfile.mapStudentProjectsSubmitValues(student_projects),
        school_year: school_year ? DateUtils.format(school_year, 'YYYY') : null,
      }

      if (isMentor()) {
        studentProfileParams = {
          ...studentProfileParams,
          schedule_link,
          is_shared_mentor,
        }
      }

      let values = {
        ...restValues,
        student_profile: {
          ...studentProfileParams,
        },
      }

      if (isMentor()) {
        if (avatar) {
          if (avatar instanceof File) {
            values.avatar = avatar
          }
        } else {
          values.avatar = ''
        }
      }

      await props.onSubmit(values)
    } catch (error) {
      setAlertError(error)
      ValidationsUtils.setServerSideErrors(error?.errors, setError)
    }
  }
  return (
    <FormProvider {...methods}>
      <Layout step={step} submittable={!isMentor()} onSubmit={onSubmit}>
        <WizardSteps step={step} steps={steps} onStepClick={onStepClick} />

        <h4 className={'text-center mt-4'}>{getStepContent(step)?.label}</h4>

        {getStepContent(step)?.subtitle ? getStepContent(step)?.subtitle : null}

        {alertError && <Alert type={'error'}>{alertError?.message || t('error')}</Alert>}

        <form>
          {getStepContent(step)?.content}

          <div className="mt-3">
            <div className={'d-flex justify-content-end'}>
              <div className="button-items">
                {step !== 0 && (
                  <Button
                    disabled={isSubmitting}
                    outline
                    color="light"
                    leftIcon={<IconChevronLeft />}
                    onClick={handleBack}
                  >
                    {t('back')}
                  </Button>
                )}
                {step !== steps.length - 1 ? (
                  <Button onClick={handleNext}>{t('next')}</Button>
                ) : (
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('complete')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </FormProvider>
  )
}

WizardForm.propTypes = {
  onSubmit: PropTypes.func,
}
