import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { GeneralJobCard } from 'features/job/components/Jobs/Cards'

export const Grid = ({ items, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      {items.map((item, i) => (
        <Row key={i}>
          <Col>
            <GeneralJobCard data={item} actionBarProps={{ config: { save: true } }} {...props} />
          </Col>
        </Row>
      ))}
    </>
  )
}

Grid.propTypes = {
  items: PropTypes.array,
}
