import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import s from './SelfIdentification.module.scss'
import { SelfIdentificationBlock } from 'features/student/components/Profile/InfoBlocks'

export const SelfIdentification = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  return (
    <>
      <Card>
        <CardBody>
          <SelfIdentificationBlock data={user?.student_profile} />
        </CardBody>
      </Card>
    </>
  )
}

SelfIdentification.propTypes = {}
