import axios from 'axios'

class UserAPI {
  getUser = async (id, params) => {
    return axios.get(`api/users/${id}`, {
      params,
    })
  }

  getPublicUser = async (id, params) => {
    return axios.get(`api/users/public/${id}`, {
      params,
    })
  }

  getScheduleLink = async (id, params) => {
    return axios.get(`api/users/${id}/schedule-link`, {
      params,
    })
  }
}

export const userAPI = new UserAPI()
