import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, CardBody, Card } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { LoginForm } from './LoginForm'
import { authLoginPA, authVerifyEmailPA } from '../../../store'

export const LoginContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = async values => {
    await dispatch(authLoginPA.request(values))

    if (location?.state?.from) {
      navigate({ ...location?.state?.from })
    }
  }

  const onVerifyEmail = async email => {
    await dispatch(authVerifyEmailPA.request({ email }))
  }

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col lg={8} xl={6}>
        <Card className="overflow-hidden">
          <div>
            <Row>
              <Col>
                <div className="text-center p-4">
                  <h3 className={'mb-0'}>{t('login')}</h3>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            <LoginForm onSubmit={onSubmit} onVerifyEmail={onVerifyEmail} />
          </CardBody>
        </Card>
        <div className="text-center">
          <p className={'mb-0'}>{t('dont_have_account')}?</p>
          <p>
            <Link to="/register/student" className="fw-medium text-primary">
              {`${t('candidate')} ${t('registration')}`}
            </Link>
            {' or '}
            <Link to="/register/employer" className="fw-medium text-primary">
              {`${t('employer')} ${t('registration')}`}
            </Link>
          </p>
        </div>
      </Col>
    </Row>
  )
}
