import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { SubscriptionDetails } from 'features/billing/components/Subscription'

export const Subscription = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'subscription'} />

      <PageContent>
        <SubscriptionDetails />
      </PageContent>
    </>
  )
}

Subscription.propTypes = {}
