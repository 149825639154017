import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useUser } from 'features/user'
import { JOB_STATUS } from 'features/job/consts/general'
import { Edit, Post, Save, Candidates, Apply, Close } from './Actions'

const { ACTIVE, DRAFT, CLOSED } = JOB_STATUS

export const Header = () => {
  const { t } = useTranslation()

  const { isEmployer, isStudent } = useUser()

  const { data: job } = useSelector(state => state.job.data)

  const status = job?.status

  return (
    <>
      <div className={'d-flex flex-wrap gap-4'}>
        {isEmployer() && (
          <>
            {[ACTIVE].includes(status) && (
              <>
                <Candidates />
                <div className="vr" />
              </>
            )}

            <Edit />

            {[DRAFT, CLOSED].includes(status) && (
              <>
                <div className="vr" />
                <Post />
              </>
            )}

            {[ACTIVE, DRAFT].includes(status) && (
              <>
                <div className="vr" />
                <Close />
              </>
            )}
          </>
        )}

        {isStudent() && (
          <>
            <Apply />
            <div className="vr" />
            <Save />
          </>
        )}
      </div>
    </>
  )
}

Header.propTypes = {}
