import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { CompanyFormSection } from './CompanyFormSection'
import { JobFormSection } from './JobFormSection'
import { LocationFormSection } from './LocationFormSection'

export const FilterForm = props => {
  const { t } = useTranslation()

  return (
    <>
      <form>
        <Row>
          <Col lg={8} xl={4}>
            <CompanyFormSection />
          </Col>

          <Col lg={4} xl={4}>
            <JobFormSection />
          </Col>
        </Row>

        <LocationFormSection />
      </form>
    </>
  )
}

FilterForm.propTypes = {}
