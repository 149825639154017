import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROLE } from 'features/user'
import { GUARD } from '../../guards'
import { ProfileComplete, Profile, ProfileEdit, ProfileSettings } from 'features/profile/routes'
import { CompanyRoot } from 'features/company/routes'
import { JobsRoot } from 'features/job/routes'
import { CandidatesRoot } from 'features/candidate/routes'
import { EmployersRoot } from 'features/employer/routes'
import { JobInvitesRoot } from 'features/job-invite/routes'
import { BillingRoot } from 'features/billing/routes'
import { MentorsRoot } from 'features/mentor/routes'
import { ImmigrateRoot } from 'features/immigrate/routes'

const generalRoutes = [
  {
    path: 'profile',
    element: <Profile />,
    roles: [ROLE.STUDENT, ROLE.EMPLOYER],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'profile/edit',
    element: <ProfileEdit />,
    roles: [ROLE.STUDENT, ROLE.EMPLOYER],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'profile/settings',
    element: <ProfileSettings />,
    roles: [ROLE.STUDENT, ROLE.EMPLOYER],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'jobs/*',
    element: <JobsRoot />,
    roles: [ROLE.STUDENT, ROLE.EMPLOYER],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'candidates/*',
    element: <CandidatesRoot />,
    roles: [ROLE.EMPLOYER],
    guards: [],
  },
  {
    path: 'invites/*',
    element: <JobInvitesRoot />,
    roles: [ROLE.STUDENT],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'company/*',
    element: <CompanyRoot />,
    roles: [ROLE.EMPLOYER],
    guards: [],
  },
  {
    path: 'employers/*',
    element: <EmployersRoot />,
    roles: [ROLE.STUDENT],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'billing/*',
    element: <BillingRoot />,
    roles: [ROLE.EMPLOYER],
    guards: [],
  },
  {
    path: 'mentors/*',
    element: <MentorsRoot />,
    roles: [ROLE.STUDENT],
    guards: [],
  },
  {
    path: 'immigrate/*',
    element: <ImmigrateRoot />,
    roles: [ROLE.STUDENT],
    guards: [],
  },
]

const profileCompleteRoutes = [
  {
    index: true,
    element: <ProfileComplete />,
    roles: [ROLE.STUDENT],
    guards: [],
  },
  {
    path: '*',
    element: <Navigate to={`/`} replace />,
    roles: [ROLE.STUDENT],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}

export const getProfileCompleteRoutes = () => {
  return profileCompleteRoutes
}
