import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ROLE } from 'features/user'
import { RouteGuard } from 'routes'
import { Job } from '../job'
import { JobEdit } from '../edit'
import { CandidatesRoot } from '../candidates'
import { JobPanelLayout } from 'features/job/components/Layouts'

export const JobRoot = () => {
  return (
    <Routes>
      <Route index element={<Job />} />
      <Route
        path="edit"
        element={
          <RouteGuard roles={[ROLE.EMPLOYER]}>
            <JobEdit />
          </RouteGuard>
        }
      />
      <Route element={<JobPanelLayout />}>
        <Route
          path="candidates/*"
          element={
            <RouteGuard roles={[ROLE.EMPLOYER]}>
              <CandidatesRoot />
            </RouteGuard>
          }
        />
      </Route>
      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />;
    </Routes>
  )
}

JobRoot.propTypes = {}
