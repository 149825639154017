import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { FormFieldController, Combobox, TextInput, DatePicker } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { TextMaskUtils } from 'utils'
import { WorksFieldArray, ProjectsFieldArray } from 'features/profile/components/FormElements'
import { StudentAttachmentsManager } from 'features/student/components/StudentAttachmentsManager'
import { CertificationsFormSection } from './CertificationsFormSection'

export const EducationWorkFormSection = ({ isMentor = false }) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const {
    getSchools,
    getProfileStudyPrograms,
    getProfileStudyYears,
    getProfileStudyArea,
    getSkills,
    getIndustries,
    getTargetPositions,
    getProfileExperienceYears,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <FormFieldController
      name={name}
      control={control}
      component={Combobox}
      id={name}
      label={label}
      placeholder={label}
      options={options}
      isDetermineValue
      normalize={option => (option !== null ? option.value : null)}
      menuPortalTarget={document.querySelector('body')}
      isClearable
      {...params}
    />
  )

  const targetIndustryLabel = isMentor
    ? t('industry')
    : `${t('target_industry')} (${t('i_want_work_in')})`
  const targetIndustryPlaceholder = isMentor ? t('industry') : t('target_industry')
  const targetPositionLabel = isMentor ? t('position_of_expertise') : t('target_position')

  return (
    <>
      <StudentAttachmentsManager />

      <div className={'py-3'} />

      <h5>{t('work_details')}</h5>
      <Row>
        <Col lg={12}>
          {renderSelectField({
            name: 'student_profile.skills',
            label: `${t('my_top_skills')} (${t('max')} 5)`,
            options: getSkills(),
            placeholder: t('my_top_skills'),
            isMulti: true,
            normalize: value => value.map(item => item.value),
            isOptionDisabled: (option, selectValue) => selectValue && selectValue.length >= 5,
          })}
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.profile_experience_year_id',
            label: t('total_years_of_work_experience'),
            options: getProfileExperienceYears(),
            placeholder: t('years_of_work_experience'),
          })}
        </Col>
        <Col lg={8}>
          {renderSelectField({
            name: 'student_profile.industries',
            label: targetIndustryLabel,
            options: getIndustries(),
            placeholder: targetIndustryPlaceholder,
            isMulti: true,
            normalize: value => value.map(item => item.value),
          })}
        </Col>
      </Row>

      <Row>
        <Col>
          {renderSelectField({
            name: 'student_profile.target_positions',
            label: `${targetPositionLabel} (${t('max')} 3)`,
            options: getTargetPositions(),
            placeholder: targetPositionLabel,
            isMulti: true,
            normalize: value => value.map(item => item.value),
            isOptionDisabled: (option, selectValue) => selectValue && selectValue.length >= 3,
          })}
        </Col>
      </Row>

      <div className={'py-3'} />

      <h5>{t('work_experience')}</h5>
      <WorksFieldArray
        name={'student_profile.student_works'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.student_profile?.student_works }}
      />

      <div className={'py-3'} />

      <h5>{`${t('project_and_volunteer')} (${t('school')} ${t('or')} ${t('others')})`}</h5>
      <ProjectsFieldArray
        name={'student_profile.student_projects'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.student_profile?.student_projects }}
      />

      <div className={'py-3'} />

      <Row>
        <Col>
          <CertificationsFormSection />
        </Col>
      </Row>

      <div className={'py-3'} />

      <h5>{t('education_details')}</h5>
      <Row>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.school_id',
            label: t('education_institution'),
            options: getSchools(),
          })}
        </Col>
        <Col lg={2}>
          <FormFieldController
            name={'student_profile.school_year'}
            control={control}
            component={DatePicker}
            id={'student_profile.school_year'}
            label={t('graduate_year')}
            placeholder={'YYYY'}
            showYearPicker
            dateFormat="yyyy"
            mask={TextMaskUtils.yearInputMask}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.profile_study_program_id',
            label: t('study_program'),
            options: getProfileStudyPrograms(),
          })}
        </Col>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.profile_study_year_id',
            label: t('year_of_study'),
            options: getProfileStudyYears(),
          })}
        </Col>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.profile_study_area_id',
            label: t('area_of_study'),
            options: getProfileStudyArea(),
          })}
        </Col>
      </Row>
    </>
  )
}
