import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Header } from './Header'
import { Tariffs } from './Tariffs'

export const SubscriptionDetails = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={'mt-4'}>
        <Header />
      </div>

      <div className={'mt-4 pt-4'}>
        <Tariffs />
      </div>
    </>
  )
}

SubscriptionDetails.propTypes = {}
