import { combineReducers } from 'redux'

import invites from './invites/reducers'
import jobRecommendations from './job-recommendations/reducers'

const jobInviteReducer = combineReducers({
  invites,
  jobRecommendations,
})

export default jobInviteReducer
