import { combineReducers } from 'redux'

import received from './received/reducers'
import sent from './sent/reducers'

const invitesReducer = combineReducers({
  received,
  sent,
})

export default invitesReducer
