import React from 'react'

import { useFetch } from 'hooks'
import { usersAPI } from 'features/users/api'
import { RequestUtils } from 'utils'

export const useEmployersFetch = () => {
  const { data, fetch, loading, error } = useFetch()

  const onFetch = params => {
    let requestParams = {
      includes: ['avatar', 'employerProfile'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    fetch(usersAPI.getEmployers, requestParams)
  }

  return {
    data,
    loading,
    error,
    onFetch,
  }
}
