import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'

import s from './GeneralCandidateCard.module.scss'
import { PropertiesBlock } from 'features/candidate/components/Candidate/CandidateDetails/InfoBlocks'
import { TitleBlock } from './TitleBlock'
import { InfoBlock } from './InfoBlock'
import { CardActionBar } from '../Card'
import { ApplicationBlock } from './ApplicationBlock'

export const GeneralCandidateCard = ({
  data,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  className = 'mb-3',
  jobInviteControlProps,
  withApplication = false,
  withJobInvite = false,
  jobInvite,
  asMentor = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Card
      className={classNames(s.root, {
        [className]: className,
      })}
    >
      <CardBody>
        <div className={s.topBar}>
          <div className={s.titleBlock}>
            <TitleBlock
              data={data}
              config={{ jobInvite: withJobInvite }}
              jobInvite={jobInvite}
              asMentor={asMentor}
            />
          </div>

          {actionBar && (
            <div className={s.actionBar}>
              {actionBarSection ? (
                actionBarSection
              ) : (
                <CardActionBar
                  candidate={data}
                  {...actionBarProps}
                  jobInviteControlProps={jobInviteControlProps}
                />
              )}
            </div>
          )}
        </div>

        <div className={'mt-2'}>
          <PropertiesBlock
            data={{ ...data?.student_profile }}
            withApplication={withApplication}
            application={data?.sent_job_application}
          />
        </div>

        {withApplication && (
          <div className={'mt-2 mb-2 pb-1'}>
            <ApplicationBlock data={data?.sent_job_application} />
          </div>
        )}

        <InfoBlock data={{ ...data }} asMentor={asMentor} />
      </CardBody>
    </Card>
  )
}

GeneralCandidateCard.propTypes = {
  data: PropTypes.object,
  actionBar: PropTypes.bool,
  actionBarSection: PropTypes.node,
  actionBarProps: PropTypes.object,
  className: PropTypes.string,
  jobInviteControlProps: PropTypes.object,
  withApplication: PropTypes.bool,
  withJobInvite: PropTypes.bool,
  jobInvite: PropTypes.object,
  asMentor: PropTypes.bool,
}
