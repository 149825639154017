import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUserSubscription } from 'features/user/hooks'
import { BILLING_SUBSCRIPTION_PERMISSION } from 'features/billing/consts/subscription'
import { CandidateRoot } from '../candidate'
import { Candidates, InvitesRoot } from '../candidates'

export const CandidatesRoot = () => {
  const { isPermissionAllow } = useUserSubscription()

  return (
    <Routes>
      <Route index element={<Candidates />} />

      <Route
        path={'invites/*'}
        element={
          <>
            {!isPermissionAllow(BILLING_SUBSCRIPTION_PERMISSION.JOB_INVITE) ? (
              <Navigate to={'/billing/subscription'} replace={true} />
            ) : (
              <InvitesRoot />
            )}
          </>
        }
      />

      <Route path={':id/*'} element={<CandidateRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

CandidatesRoot.propTypes = {}
