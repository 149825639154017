import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { profileAPI } from '../../../api'
import { userData } from 'features/user/store'
import {
  PROFILE_EMPLOYER_UPDATE_REQUEST,
  PROFILE_EMPLOYER_UPDATE_SUCCESS,
  PROFILE_EMPLOYER_UPDATE_FAILED,
} from './actionTypes'

export const profileEmployerUpdatePA = createPromiseAction(
  PROFILE_EMPLOYER_UPDATE_REQUEST,
  PROFILE_EMPLOYER_UPDATE_SUCCESS,
  PROFILE_EMPLOYER_UPDATE_FAILED
)()

function* update(action) {
  try {
    const response = yield call(profileAPI.updateEmployerProfile, action.payload)
    const { data } = response

    yield put(profileEmployerUpdatePA.success({}))

    yield put(userData.updateData(data?.data))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(profileEmployerUpdatePA.request, update)
}
