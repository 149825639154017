import { all } from 'redux-saga/effects'

import { studentSaga } from './student/sagas'
import { employerSaga } from './employer/sagas'
import { passwordSaga } from './password/sagas'
import { avatarSaga } from './avatar/sagas'

export function* profileSaga() {
  yield all([studentSaga(), employerSaga(), passwordSaga(), avatarSaga()])
}
