import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { immigrateFAQEntriesList } from 'features/immigrate/store'
import { NoDataTextMessage, DataShower } from 'components/Elements'
import { Sections } from './Section'

const FAQEntriesContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(immigrateFAQEntriesList.cleanState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      pagination: false,
      // sort: [{ id: 'created_at', desc: true }],
    }

    dispatch(immigrateFAQEntriesList.getList({ params }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <h4 className="font-size-18">{t('faqs')}</h4>

      <DataShower isLoading={isLoading} isFetched isFailed={!!props.error} error={props.error}>
        <Card>
          <CardBody>
            <Sections items={props.list} />

            {!props.list.length && !props.loading && !props.error && (
              <NoDataTextMessage variant={'empty'} />
            )}
          </CardBody>
        </Card>
      </DataShower>
    </>
  )
}

FAQEntriesContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page } = state.immigrate.faq.entries.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const FAQEntriesContainer = connect(mapStateToProps)(FAQEntriesContainerComponent)
