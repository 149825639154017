import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor, Icon } from 'components/Elements'
import { useJob } from 'features/job/hooks'
import { useUserPermissions } from 'features/user/hooks'

export const RecommendationsBlock = () => {
  const { t } = useTranslation()

  const { job } = useJob()

  const { isJobRecommendationAllow } = useUserPermissions()

  const allow = isJobRecommendationAllow(job)

  return (
    <div className={'d-flex gap-1 flex-wrap justify-content-between'}>
      <p className={'mb-0 font-size-18 fw-medium'}>{t('recommended_candidates')}</p>

      {allow ? (
        <Anchor to={`/jobs/${job?.id}/candidates/recommendations`}>{t('view')}</Anchor>
      ) : (
        <div className={'d-flex align-items-center'}>
          <Icon name={'blocked'} className={'font-size-22'} />
        </div>
      )}
    </div>
  )
}

RecommendationsBlock.propTypes = {}
