import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from 'features/user'

export const PanelSwitch = ({ roles, ...props }) => {
  const location = useLocation()

  const { isStudent, isProfileComplete } = useUser()

  let path = isStudent() ? '/jobs' : '/company/profile'

  if (isStudent() && !isProfileComplete()) {
    path = '/profile/complete'
  }

  return <Navigate to={path} state={{ from: location }} />
}

PanelSwitch.propTypes = {}
