import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
}

const userSyncSlice = createSlice({
  name: 'userSync',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
    },
    getDataSuccess(state) {
      state.loading = false
    },
    getDataError(state, action) {
      state.loading = false
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getData
 * @property {function} getDataSuccess
 * @property {function} getDataError
 * @property {function} cleanState
 */

export const userSync = userSyncSlice.actions

export default userSyncSlice.reducer
