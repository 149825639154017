import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'
import { BILLING_SUBSCRIPTION_PERMISSION } from 'features/billing/consts/subscription'

const { FREE, PREMIUM, ENTERPRISE } = BILLING_TARIFF_ALIAS
const { JOB_INVITE, JOB_RECOMMENDATION, CANDIDATES_ADVANCED_SEARCH_FILTER } =
  BILLING_SUBSCRIPTION_PERMISSION

export const getSubscriptionPermissions = plan => {
  if (plan === FREE) {
    return []
  }

  if (plan === PREMIUM) {
    return [JOB_INVITE, JOB_RECOMMENDATION, CANDIDATES_ADVANCED_SEARCH_FILTER]
  }

  if (plan === ENTERPRISE) {
    return [JOB_INVITE, JOB_RECOMMENDATION, CANDIDATES_ADVANCED_SEARCH_FILTER]
  }

  return []
}
