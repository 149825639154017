export const MENTORS_FILTERS_INITIAL_VALUES = {
  industries: null,
  target_positions: null,
  profile_study_areas: null,
  profile_skills: null,
  languages: null,
  location_provinces: null,
  location_cities: null,
  profile_experience_year: null,
}
