import React from 'react'
import { useTranslation } from 'react-i18next'
import { EXTERNAL_LINK } from 'consts/core'

export const ScheduleLinkNote = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={'mb-3'}>
        <p className={'mb-0'}>{t('create_schedule_link_free')}:</p>

        <a
          href={EXTERNAL_LINK.SCHEDULE_LINK_EXAMPLE}
          target={'_blank'}
          rel={'noreferrer'}
          className={'text-primary'}
        >
          {EXTERNAL_LINK.SCHEDULE_LINK_EXAMPLE}
        </a>
      </div>
    </>
  )
}
