import axios from 'axios'

class ImmigrateCategoryAPI {
  getCategory = async (id, params) => {
    return axios.get(`api/immigrate-categories/${id}`, {
      params,
    })
  }
}

export const immigrateCategoryAPI = new ImmigrateCategoryAPI()
