import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../../api'
import {
  JOB_INVITE_SUBMIT_REQUEST,
  JOB_INVITE_SUBMIT_SUCCESS,
  JOB_INVITE_SUBMIT_FAILED,
} from './actionTypes'

export const jobInviteSubmitPA = createPromiseAction(
  JOB_INVITE_SUBMIT_REQUEST,
  JOB_INVITE_SUBMIT_SUCCESS,
  JOB_INVITE_SUBMIT_FAILED
)()

function* submit(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(jobAPI.createInvite, id, params)

    const { data } = response

    yield put(jobInviteSubmitPA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(jobInviteSubmitPA.request, submit)
}
