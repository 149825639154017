import axios from 'axios'

class ProfileCompanyBannerAPI {
  uploadBanner = async data => {
    const formData = new FormData()
    formData.append('company_banner', data.company_banner)

    return axios.post('api/profile/employer/company-banner', formData)
  }

  deleteBanner = async () => {
    return axios.delete('api/profile/employer/company-banner')
  }
}

export const profileCompanyBannerAPI = new ProfileCompanyBannerAPI()
