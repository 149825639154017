import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

import { Attachment } from 'components/Elements'
import { FileButtonControl } from '../FileButtonControl'
import { FileUtils } from 'utils'

export const FilePickerControl = ({ value, onChange, onDownload, accept, buttonProps = {} }) => {
  const { t } = useTranslation()

  const onPick = file => {
    onChange(file)
  }

  const isLocaleFile = value && value instanceof File

  const file = useMemo(() => {
    if (value) {
      if (isLocaleFile) {
        return {
          name: value?.name,
          size: FileUtils.formatBytes(value?.size),
        }
      } else {
        return {
          name: value?.file_name,
          size: value?.size,
        }
      }
    }

    return null
  }, [value])

  const handleRemove = () => {
    onChange(null)
  }

  return (
    <>
      {file && (
        <Row>
          <Col md={6}>
            <Attachment
              name={file?.name}
              size={file?.size}
              remove
              download={!isLocaleFile}
              onDownload={() => onDownload(value)}
              onRemove={handleRemove}
            />
          </Col>
        </Row>
      )}

      {!file && <FileButtonControl accept={accept} onChange={onPick} {...buttonProps} />}
    </>
  )
}

FilePickerControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.node,
  accept: PropTypes.string,
  buttonProps: PropTypes.object,
  onDownload: PropTypes.func,
}
