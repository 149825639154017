import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SaveControl } from './Controls'

export const CardActionBar = ({ config = {}, job = {}, saveControlProps, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-2'}>
      {config.save && <SaveControl job={job} {...saveControlProps} />}
    </div>
  )
}

CardActionBar.propTypes = {
  config: PropTypes.object,
  job: PropTypes.object,
  saveControlProps: PropTypes.object,
}
