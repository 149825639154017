import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ButtonLink, LogoIcon } from 'components/Elements'
import { ProfileMenu } from './ProfileMenu'
import { usePanelLayout } from 'hooks'
import { useUser } from 'features/user'

export const Header = ({ onDrawerOpen }) => {
  const { t } = useTranslation()

  const { config } = usePanelLayout()
  const { headerRightOptions } = config
  const { isEmployer } = useUser()

  let indexPath = isEmployer() ? '/company/profile' : '/jobs'

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to={indexPath} className="logo logo-dark">
              <span className="logo-sm">
                <LogoIcon type={'icon'} />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={onDrawerOpen}
            className="btn btn-sm px-3 font-size-16 header-item d-lg-none d-md-block"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
        <div className="d-flex align-items-center">
          {headerRightOptions.visible && (
            <div className={'me-4 pe-4'}>{headerRightOptions.render()}</div>
          )}

          {isEmployer() && (
            <div className={'me-sm-4 pe-sm-4'}>
              <ButtonLink to={'/jobs/create'} className={'text-capitalize'}>
                {t('post_a_job')}
              </ButtonLink>
            </div>
          )}

          <ProfileMenu />
        </div>
      </div>
    </header>
  )
}
