import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { TariffsTable } from './TariffsTable'
import { useUserSubscription } from 'features/user/hooks'
import { EXTERNAL_LINK } from 'consts/core'

export const Tariffs = () => {
  const { t } = useTranslation()

  const { alias } = useUserSubscription()

  return (
    <Card>
      <CardBody>
        <TariffsTable currentSubscription={alias} />

        <div className={'mt-3'}>
          <a
            href={EXTERNAL_LINK.BOOKING_CALL}
            target="_blank"
            rel="noreferrer"
            className={'btn btn-primary btn-lg btn-rounded text-capitalize'}
            role={'button'}
          >
            {t('book_call')}
          </a>
        </div>
      </CardBody>
    </Card>
  )
}

Tariffs.propTypes = {}
