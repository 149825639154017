import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useFirstMountState } from 'react-use'

import { Header } from './Header'

export const Layout = ({ children, step, onSubmit, submittable }) => {
  const { t } = useTranslation()

  const isFirstMount = useFirstMountState()

  const cardRef = useRef()

  useEffect(() => {
    if (!isFirstMount && cardRef?.current) {
      // cardRef?.current.scrollIntoView({ behavior: 'instant', top: 0 })
    }
  }, [isFirstMount, step, cardRef])

  return (
    <Card className="overflow-hidden" innerRef={cardRef}>
      <CardHeader className={'bg-white'}>
        <Header step={step} onSubmit={onSubmit} submittable={submittable} />
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  )
}

Layout.propTypes = {}
