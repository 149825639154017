import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  loading: false,
  error: null,
}

const billingTariffsListSlice = createSlice({
  name: 'billingTariffsList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} cleanState
 */

export const billingTariffsList = billingTariffsListSlice.actions

export default billingTariffsListSlice.reducer
