import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { useEmployer } from 'features/employer/hooks'
import {
  GeneralBlock,
  BasicBlock,
  CTABlock,
  BannerBlock,
} from 'features/company/components/Profile/ProfileDetails/InfoBlocks'

export const EmployerDetailsContent = () => {
  const { t } = useTranslation()

  const { employer } = useEmployer()

  const { employer_profile } = employer

  return (
    <>
      <Row>
        <Col lg={6} xxl={4} className={'d-flex'}>
          <GeneralBlock data={employer_profile} />
        </Col>

        <Col lg={6} xxl={8} className={'d-flex'}>
          <BasicBlock data={employer_profile} />
        </Col>
      </Row>

      <>
        {employer_profile?.company_cta_text && (
          <Row>
            <Col>
              <CTABlock data={employer_profile} />
            </Col>
          </Row>
        )}

        {employer_profile?.company_banner && (
          <Row className={'mb-3'}>
            <Col>
              <BannerBlock data={employer_profile?.company_banner} />
            </Col>
          </Row>
        )}
      </>
    </>
  )
}

EmployerDetailsContent.propTypes = {}
