import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import { Drawer, LogoIcon } from 'components/Elements'
import { SidebarContent } from '../SidebarContent'
import { useUser } from 'features/user'

export const SidebarDrawer = ({ visible, onClose, ...props }) => {
  const location = useLocation()

  const { isEmployer } = useUser()

  let indexPath = isEmployer() ? '/company/profile' : '/jobs'

  useEffect(() => {
    if (visible) {
      onClose()
    }
  }, [location])

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      placement={'left'}
      className={'sidebar-drawer'}
      header={false}
    >
      <div className={'sidebar-drawer-header'}>
        <div className="navbar-brand-box">
          <Link to={indexPath} className="logo logo-dark">
            <LogoIcon />
          </Link>
        </div>
      </div>

      <div className={'sidebar-drawer-content'}>
        <SidebarContent />
      </div>
    </Drawer>
  )
}

SidebarDrawer.propTypes = {
  visible: PropTypes.bool,
  toggleVisible: PropTypes.func,
}
