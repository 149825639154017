import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, CardBody, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ForgotPasswordForm } from './ForgotPasswordForm'
import { Alert } from 'components/Elements'
import { authForgotPasswordPA } from '../../../store'

export const ForgotPasswordContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [success, setSuccess] = useState(null)

  const onSubmit = async values => {
    setSuccess(null)

    const { message } = await dispatch(authForgotPasswordPA.request(values))

    setSuccess(message || t('success'))
  }

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col lg={8} xl={6}>
        <Card className="overflow-hidden">
          <div>
            <Row>
              <Col>
                <div className="text-center p-4">
                  <h3 className={'mb-0'}>{t('forgot_password')}</h3>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            {success && <Alert>{success}</Alert>}

            <ForgotPasswordForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
        <div className="text-center">
          <p>
            {t('remember_it')}?{' '}
            <Link to="/login" className="fw-medium text-primary">
              {t('login')}
            </Link>
          </p>
        </div>
      </Col>
    </Row>
  )
}
