import { combineReducers } from 'redux'

import list from './list/slice'
import saved from './saved/reducers'
import applied from './applied/reducers'
import specific from './specific/reducers'

const jobsReducer = combineReducers({
  list,
  saved,
  applied,
  specific,
})

export default jobsReducer
