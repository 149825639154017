import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPencil } from '@tabler/icons'

import { MegaTagTitle, Anchor } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { ProfileDetailsContainer } from 'features/company/components/Profile/ProfileDetails'

export const CompanyProfile = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'company_profile'} />

      <PageContent>
        <PageHeader
          headerRight={
            <Anchor
              to="/company/profile/edit"
              className="font-size-18 fw-medium"
              leftIcon={<IconPencil size={20} />}
            >
              {t('edit')}
            </Anchor>
          }
        />

        <ProfileDetailsContainer />
      </PageContent>
    </>
  )
}

CompanyProfile.propTypes = {}
