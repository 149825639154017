import React from 'react'
import { Badge as RSBadge } from 'reactstrap'

export const Badge = ({ children, className }) => {
  return (
    <RSBadge pill className={'font-size-12 badge-soft-primary rounded-pill'}>
      {children}
    </RSBadge>
  )
}
