import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { AppliedJobsContainer } from 'features/job/components/Jobs/AppliedJobs'

export const JobsApplied = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'applied_jobs'} />

      <PageContent>
        <AppliedJobsContainer />
      </PageContent>
    </>
  )
}

JobsApplied.propTypes = {}
