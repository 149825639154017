import axios from 'axios'

class AuthAPI {
  login = async data => {
    return axios.post('api/auth/login', data)
  }
  register = async data => {
    return axios.post('api/auth/register', data)
  }
  logOut = async () => {
    return axios.post('api/auth/logout')
  }
  verifyEmail = async data => {
    return axios.post('api/auth/email/verification-notification', data)
  }
  forgotPassword = async data => {
    return axios.post('api/auth/forgot-password', data)
  }
  resetPassword = async data => {
    return axios.post('api/auth/reset-password', data)
  }
}

export const authAPI = new AuthAPI()
