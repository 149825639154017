import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Alert, Button } from 'components/Elements'
import { FormFieldController, TextInput } from 'components/FormElements'
import { ValidationsUtils } from 'utils'

export const ForgotPasswordForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
      })
    ),
  })

  const [alertError, setAlertError] = useState(null)

  const onSubmit = async data => {
    setAlertError(null)
    try {
      await props.onSubmit(data)
    } catch (error) {
      setAlertError(error)
      ValidationsUtils.setServerSideErrors(error?.errors, setError)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {alertError && <Alert type={'error'}>{alertError?.message || t('error')}</Alert>}

        <FormFieldController
          name={'email'}
          control={control}
          component={TextInput}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
        />

        <div className={'text-center mt-4'}>
          <Button type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
            {t('reset')}
          </Button>
        </div>
      </form>
    </>
  )
}
