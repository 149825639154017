import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

import { Header } from './Header'
import { Body } from './Body'

export const Questions = ({ items, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      <UncontrolledAccordion flush stayOpen>
        {items.map(item => {
          const { id, question, answer } = item
          const itemId = id.toString()

          return (
            <AccordionItem key={id}>
              <AccordionHeader targetId={itemId}>
                <Header title={question} />
              </AccordionHeader>
              <AccordionBody accordionId={itemId}>
                <Body value={answer} />
              </AccordionBody>
            </AccordionItem>
          )
        })}
      </UncontrolledAccordion>
    </>
  )
}

Questions.propTypes = {
  items: PropTypes.array,
}
