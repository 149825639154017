import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CompanyProfile, CompanyProfileEdit } from 'features/company/routes'

export const CompanyRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={'/'} replace={true} />} />

      <Route path="profile" element={<CompanyProfile />} />
      <Route path="profile/edit" element={<CompanyProfileEdit />} />

      <Route path={'*'} element={<Navigate to={'/company/profile'} replace={true} />} />
    </Routes>
  )
}

CompanyRoot.propTypes = {}
