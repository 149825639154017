import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Row, Col } from 'reactstrap'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { GeneralFormSection } from 'features/profile/components/FormSections'
import { useUser } from 'features/user'
import { FormatUtils } from 'utils'
import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions, useFormSubmit } from 'hooks'

export const EditForm = props => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { employer_profile, first_name, last_name, email, phone } = user

  const { profile_position } = employer_profile

  const defaultValues = {
    first_name,
    last_name,
    email,
    phone: FormatUtils.formatPhone(phone) || '',
    employer_profile: {
      profile_position_id: profile_position?.id || null,
    },
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      const { email, phone, ...restValues } = data

      let values = {
        phone: phone.replace(/[^0-9]/g, ''),
        ...restValues,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const { getProfilePositions } = useSelectOptions()

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralFormSection />

          <Row>
            <Col md={6}>
              <FormFieldController
                name={'employer_profile.profile_position_id'}
                control={control}
                component={Combobox}
                id={'employer_profile.profile_position_id'}
                label={t('position')}
                placeholder={t('position')}
                options={getProfilePositions()}
                isSearchable={false}
                isDetermineValue
                normalize={option => (option !== null ? option.value : null)}
              />
            </Col>
          </Row>

          <div className="mt-3">
            <div className="button-items">
              <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

EditForm.propTypes = {
  onSubmit: PropTypes.func,
}
