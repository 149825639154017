import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { JobApplicationForm } from '../JobApplicationForm'
import { jobApplicationSubmitPA } from 'features/job/store'
import { useNotify } from 'hooks'
import { useUser } from 'features/user/hooks'
import { FormatUtils } from 'utils'
import { PersonalInfoBlock } from './PersonalInfoBlock'

export const JobApplicationCreate = ({ onSuccess, job }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const { user } = useUser()

  const { email, phone, student_profile } = user

  const initialValues = useMemo(() => {
    return {
      email,
      phone: FormatUtils.formatPhone(phone) || '',
      resume: student_profile?.resume || null,
    }
  }, [user])

  const onSubmit = async values => {
    const { id, job_url } = job

    const params = {
      ...values,
    }

    const data = await dispatch(jobApplicationSubmitPA.request({ id, params }))

    showNotification({
      type: 'success',
      message: t('applied_successfully'),
    })

    if (job_url) {
      window.open(job_url, '_blank')
    }

    onSuccess(data)
  }

  return (
    <>
      <div className={'mb-3'}>
        <PersonalInfoBlock />
      </div>
      <JobApplicationForm initialValues={initialValues} onSubmit={onSubmit} />
    </>
  )
}

JobApplicationCreate.propTypes = {
  onSuccess: PropTypes.func,
  job: PropTypes.object,
}
