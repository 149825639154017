import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useMyJobsFetch } from 'features/job/hooks'
import { JOB_STATUS } from 'features/job/consts/general'

export const JobFormSection = ({ onJobChange }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data, loading, onFetch } = useMyJobsFetch()

  useEffect(() => {
    onFetch({
      pagination: false,
      filters: {
        statuses: [JOB_STATUS.ACTIVE],
      },
      sort: [
        {
          id: 'posted_on',
          desc: true,
        },
      ],
    })
  }, [])

  return (
    <>
      <Row>
        <Col md={12}>
          <FormFieldController
            name={'job'}
            control={control}
            component={Combobox}
            id={'job'}
            label={t('job')}
            placeholder={t('job')}
            options={data?.data}
            // isDetermineValue
            // normalize={option => (option !== null ? option.id : null)}
            isLoading={loading}
            getOptionLabel={option => option?.title}
            getOptionValue={option => option.id}
            defaultValue={null}
            shouldUnregister
            onChange={onJobChange}
          />
        </Col>
      </Row>
    </>
  )
}
