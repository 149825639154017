import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Mentor } from '../mentor'

export const MentorRoot = () => {
  return (
    <Routes>
      <Route index element={<Mentor />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

MentorRoot.propTypes = {}
