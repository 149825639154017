import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor } from 'components/Elements'
import { JOB_STATUS } from 'features/job/consts/general'
import { PostControl } from 'features/job/components/JobActionControls'

const { ACTIVE, DRAFT } = JOB_STATUS

export const JobAction = ({ data = {}, onPostSuccess }) => {
  const { t } = useTranslation()

  const { id, status } = data

  return (
    <div className={'d-flex gap-4 font-size-14 justify-content-end text-nowrap'}>
      <Anchor to={`/jobs/${id}/candidates/applications`}>{t('applications')}</Anchor>

      {status === DRAFT && (
        <Anchor to={`/jobs/${id}/edit`} className={'text-camelcase'}>
          {t('edit_details')}
        </Anchor>
      )}

      {status === DRAFT && <PostControl job={data} onSuccess={onPostSuccess} />}
    </div>
  )
}

JobAction.propTypes = {
  data: PropTypes.object,
  onPostSuccess: PropTypes.func,
}
