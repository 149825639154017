import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { FormFieldController, TextInput, CheckboxField } from 'components/FormElements'
import { ScheduleLinkNote } from './ScheduleLinkNote'

export const MentorsFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <Row>
        <Col>
          <FormFieldController
            name={'student_profile.schedule_link'}
            control={control}
            component={TextInput}
            id={'student_profile.schedule_link'}
            label={t('schedule_link')}
            placeholder={t('schedule_link')}
          />

          <ScheduleLinkNote />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormFieldController
            name={'student_profile.is_shared_mentor'}
            control={control}
            component={CheckboxField}
            id={'student_profile.is_shared_mentor'}
            label={t('is_shared_mentor_text')}
          />
        </Col>
      </Row>
    </>
  )
}
