import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'

import { jobJobsList } from 'features/job/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'
import { GeneralJobsFilter } from 'features/job/components/Jobs/Filters'

const GeneralJobsContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobJobsList.resetDataState({}))
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { user, is_remote, multiple_locations, ...restFilters } = props.filters

    let params = {
      page: props.page,
      per_page: 20,
      filters: {
        ...restFilters,
        user: user?.id || null,
        is_remote: is_remote || null,
        multiple_locations: multiple_locations || null,
      },
      sort: [
        { id: 'top', desc: true },
        { id: 'posted_on', desc: true },
      ],
    }

    dispatch(jobJobsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobJobsList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(jobJobsList.changeFilter({ ...values }))
  }

  const onJobSavedSuccess = data => {
    dispatch(jobJobsList.updateListItem({ ...data }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <div className={'mb-2'}>
        <GeneralJobsFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
      </div>

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid items={props.list} saveControlProps={{ onSuccess: onJobSavedSuccess }} />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_jobs')}</NoDataTextMessage>
      )}
    </>
  )
}

GeneralJobsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.job.jobs.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const GeneralJobsContainer = connect(mapStateToProps)(GeneralJobsContainerComponent)
