import qs from 'qs'

const paramsSerializer = params => {
  return qs.stringify(params, { encode: false })
}

const getMapRequestParams = (params, initialValues = {}) => {
  let requestParams = initialValues

  if (params.hasOwnProperty('pagination')) {
    requestParams = {
      ...requestParams,
      pagination: params.pagination,
    }
  }

  if (params.type) {
    requestParams = {
      ...requestParams,
      type: params.type,
    }
  }

  if (params.page) {
    requestParams = {
      ...requestParams,
      page: params.page,
    }
  }

  if (params.per_page) {
    requestParams = {
      ...requestParams,
      per_page: params.per_page,
    }
  }

  if (params.sort && Array.isArray(params.sort)) {
    let sort = {}

    params.sort.forEach(item => {
      const key = item.id
      const value = item.desc ? 'desc' : 'asc'

      sort = {
        ...sort,
        [key]: value,
      }
    })

    requestParams = {
      ...requestParams,
      sort: {
        ...sort,
      },
    }
  }

  if (params.filters) {
    function mapFilterParams(data) {
      let filters = {}
      Object.entries(data).forEach(entry => {
        const [key, value] = entry
        if (value !== null && value !== '') {
          // if value is object
          if (typeof value === 'object' && !Array.isArray(value)) {
            filters[key] = {
              ...mapFilterParams(value),
            }
          } else {
            filters[key] = value
          }
        }
      })

      return filters
    }

    requestParams = {
      ...requestParams,
      filters: {
        ...mapFilterParams(params.filters),
      },
    }
  }

  return requestParams
}

const buildFormData = (formData, data, rootName) => {
  let root = rootName || ''
  if (data instanceof File) {
    formData.append(root, data)
  } else if (Array.isArray(data)) {
    if (!!data.length) {
      for (let i = 0; i < data.length; i++) {
        buildFormData(formData, data[i], root + '[' + i + ']')
      }
    } else {
      formData.append(root, '')
    }
  } else if (typeof data === 'object' && data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (root === '') {
          buildFormData(formData, data[key], key)
        } else {
          buildFormData(formData, data[key], root + '[' + key + ']')
        }
      }
    }
  } else {
    if (data !== null && typeof data !== 'undefined') {
      formData.append(root, data)
    } else {
      formData.append(root, '')
    }
  }
}

export { paramsSerializer, getMapRequestParams, buildFormData }
