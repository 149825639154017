import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Anchor, ConfirmModal, Icon } from 'components/Elements'
import { useAsyncDispatch, useConfirmPopup, useNotify } from 'hooks'
import { JOB_STATUS } from 'features/job/consts/general'
import { jobStatusUpdatePA } from 'features/job/store'
import { useUserPermissions } from 'features/user/hooks'
import { useJobPosting } from 'features/job/hooks'

export const PostControl = ({ job, blockedIconClassName = '', className = '', onSuccess }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { showNotification } = useNotify()

  const { isJobPostingAllow } = useUserPermissions()
  const blocked = !isJobPostingAllow(job)

  const { onPostingSuccess } = useJobPosting()

  // Update job status
  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onPostConfirm = async () => {
    try {
      const status = JOB_STATUS.ACTIVE
      const data = await onAsyncDispatch(jobStatusUpdatePA.request, { id: job?.id, status })

      onPostingSuccess(job)

      onSuccess && onSuccess({ ...data?.data })

      showNotification({
        type: 'success',
        message: t('job_posted_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onPostConfirm })

  const handlePostJob = e => {
    e.preventDefault()

    if (blocked) {
      navigate('/billing/subscription')
    } else {
      open()
    }
  }
  // --- //

  return (
    <>
      <div>
        <Anchor
          to={''}
          disabled={loading}
          className={className}
          onClick={handlePostJob}
          rightIcon={blocked ? <Icon name={'blocked'} className={blockedIconClassName} /> : null}
        >
          {t('post_job')}
        </Anchor>
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}

PostControl.propTypes = {
  job: PropTypes.object,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  blockedIconClassName: PropTypes.string,
}
