import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { jobRecommendationsAPI } from 'features/job/api'
import { jobInviteJobRecommendationsMyList } from './slice'
import { RequestUtils } from 'utils'

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: ['job'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(jobRecommendationsAPI.getMyJobRecommendations, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        jobInviteJobRecommendationsMyList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(jobInviteJobRecommendationsMyList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobInviteJobRecommendationsMyList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(jobInviteJobRecommendationsMyList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
