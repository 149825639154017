import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authLogoutPA } from 'features/auth/store'
import { useNotify } from 'hooks'

export const useLogout = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { showNotification } = useNotify()

  const logout = async () => {
    try {
      await dispatch(authLogoutPA.request())

      navigate('/login')
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    logout,
  }
}
