import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterCard } from 'features/filter/components'
import { MENTORS_FILTERS_INITIAL_VALUES } from 'features/mentor/consts/filters'
import { FilterForm } from '../FilterForm'

export const GeneralMentorsFilter = ({ defaultValues, onFiltersChange }) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...MENTORS_FILTERS_INITIAL_VALUES,
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterCard reset={isApplied} onReset={onReset}>
        <FilterForm />
      </FilterCard>
    </FormProvider>
  )
}

GeneralMentorsFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
}
