import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { GeneralBlock, BasicBlock, CTABlock, BannerBlock } from '../InfoBlocks'
import { useUser, useUserSubscription } from 'features/user/hooks'
import { useTariffStaticOptions } from 'features/billing/hooks'

export const ProfileDetailsContainer = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { alias } = useUserSubscription()

  const { employer_profile } = user

  const { isEnterprise } = useTariffStaticOptions()

  const isEnterpriseTariff = isEnterprise(alias)

  return (
    <>
      <Row>
        <Col lg={6} xxl={4} className={'d-flex'}>
          <GeneralBlock data={employer_profile} />
        </Col>

        <Col lg={6} xxl={8} className={'d-flex'}>
          <BasicBlock data={employer_profile} />
        </Col>
      </Row>

      {isEnterpriseTariff && (
        <>
          {employer_profile?.company_cta_text && (
            <Row>
              <Col>
                <CTABlock data={employer_profile} />
              </Col>
            </Row>
          )}

          {employer_profile?.company_banner && (
            <Row className={'mb-3'}>
              <Col>
                <BannerBlock data={employer_profile?.company_banner} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}

ProfileDetailsContainer.propTypes = {}
