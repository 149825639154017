import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPencil } from '@tabler/icons'

import { MegaTagTitle, Anchor } from 'components/Elements'
import { useUser } from 'features/user'
import { PageContent, PageHeader } from 'components/Layouts'
import {
  StudentProfileDetails,
  EmployerProfileDetails,
} from 'features/profile/components/ProfileDetails'

export const Profile = () => {
  const { t } = useTranslation()

  const { isStudent } = useUser()

  return (
    <>
      <MegaTagTitle title={'profile'} />

      <PageContent>
        <PageHeader
          headerRight={
            <Anchor
              to="/profile/edit"
              className="font-size-18 fw-medium"
              leftIcon={<IconPencil size={20} />}
            >
              {t('edit')}
            </Anchor>
          }
        />
        <>{isStudent() ? <StudentProfileDetails /> : <EmployerProfileDetails />}</>
      </PageContent>
    </>
  )
}

Profile.propTypes = {}
