import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TagBadge, NoDataTextMessage, BooleanTextMessage } from 'components/Elements'

export const MentorInfo = ({ data }) => {
  const { t } = useTranslation()

  const { schedule_link, is_shared_mentor } = data

  return (
    <>
      <div className={'mb-4'}>
        <h5>{t('schedule_link')}</h5>
        {schedule_link ? (
          <p>
            <a href={schedule_link} target={'_blank'} rel="noreferrer" className={'text-primary'}>
              {schedule_link}
            </a>
          </p>
        ) : (
          <NoDataTextMessage variant={'fill'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('is_shared_mentor_text')}</h5>
        <TagBadge>
          <BooleanTextMessage value={is_shared_mentor} />
        </TagBadge>
      </div>
    </>
  )
}

MentorInfo.propTypes = {
  data: PropTypes.object,
}
