import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import { RegisterContainer, InfoContent } from 'features/auth/components'
import { ROLE } from 'features/user'
import image from 'assets/images/illustrations/event.png'
import { EXTERNAL_LINK } from 'consts/core'

export const RegisterEmployer = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'employer_registration'} />

      <Row className="align-items-center h-100">
        <Col lg={5} className={'order-lg-first order-last'}>
          <div className={'mb-4'}>
            <InfoContent
              image={image}
              title={t('employer_register_info_title')}
              text={t('employer_register_info_text')}
              action={
                <a
                  href={EXTERNAL_LINK.BOOKING_CALL}
                  target="_blank"
                  rel="noreferrer"
                  className={'btn btn-primary btn-lg btn-rounded text-capitalize'}
                  role={'button'}
                >
                  {t('book_call')}
                </a>
              }
            />
          </div>
        </Col>
        <Col lg={7}>
          <RegisterContainer role={ROLE.EMPLOYER} />
        </Col>
      </Row>
    </>
  )
}
