import axios from 'axios'

class JobInvitesAPI {
  getReceivedInvites = async params => {
    return axios.get(`api/job-invites/received`, {
      params,
    })
  }

  getSentInvites = async params => {
    return axios.get(`api/job-invites/sent`, {
      params,
    })
  }

  seeAllReceivedInvites = async () => {
    return axios.post(`api/job-invites/received/seen`)
  }
}

export const jobInvitesAPI = new JobInvitesAPI()
