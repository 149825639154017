import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUser } from 'features/user/hooks'
import { MentorRoot } from '../mentor'
import { Mentors } from '../mentors'

export const MentorsRoot = () => {
  const { isMentor } = useUser()

  return (
    <Routes>
      {!isMentor() && (
        <>
          <Route index element={<Mentors />} />

          <Route path={':id/*'} element={<MentorRoot />} />
        </>
      )}

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

MentorsRoot.propTypes = {}
