import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import { profileAPI } from 'features/profile/api'
import i18n from 'i18n'
import { userData } from '../data/slice'
import { userSync } from './slice'

function* getData() {
  try {
    const includes = ['subscription']

    const response = yield call(profileAPI.getProfile, { includes })

    const { data } = response

    yield put(userSync.getDataSuccess())

    yield put(userData.updateData(data?.data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(userSync.getDataError(message))
  }
}

function* watchGetData() {
  yield takeEvery(userSync.getData, getData)
}

export function* syncSaga() {
  yield all([fork(watchGetData)])
}
