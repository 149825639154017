import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'

import s from './NavItem.module.scss'

export const NavLink = ({ children, external = false, ...props }) => {
  return (
    <>
      {external ? (
        <a target={'_blank'} rel={'noreferrer'} {...props}>
          {children}
        </a>
      ) : (
        <Link {...props}>{children}</Link>
      )}
    </>
  )
}

export const NavItem = ({ children, item, active = false, defaultOpened = false }) => {
  const { label, Icon, path, external, rightSection } = item

  const withChildren = !!children

  const [_opened, setOpened] = useState(defaultOpened)

  const handleClick = event => {
    if (withChildren) {
      event.preventDefault()
      // onClick?.(event);
      setOpened(!_opened)
    }
  }

  return (
    <>
      <NavLink
        external={external}
        className={classNames(s.button, {
          [`${s.button_active}`]: active,
          [`${s.button_menu}`]: withChildren,
        })}
        to={withChildren ? '' : path}
        onClick={withChildren ? handleClick : null}
        href={external ? path : null}
      >
        {Icon && <div className={s.icon}>{Icon}</div>}

        <div className={s.body}>
          <span className={s.label}>{label}</span>
        </div>

        {rightSection && <span className={s.rightSection}>{rightSection}</span>}
      </NavLink>
      <Collapse isOpen={_opened}>
        <div className={s.children}>{children}</div>
      </Collapse>
    </>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.object,
  active: PropTypes.bool,
  defaultOpened: PropTypes.bool,
}
