import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPlus, IconMinus } from '@tabler/icons'

import {
  CandidatesFilterFormSection,
  CandidatesAdvancedFilterFormSection,
} from 'features/candidate/components/FormSections'
import { Anchor } from 'components/Elements'

export const FilterForm = ({ advancedFilter = false, withSearch }) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const onVisibleToggle = () => {
    setVisible(prevState => !prevState)
  }

  const onVisibleClick = e => {
    e.preventDefault()

    onVisibleToggle()
  }

  return (
    <>
      <form>
        <CandidatesFilterFormSection withSearch={withSearch} />

        {advancedFilter && (
          <>
            {visible && <CandidatesAdvancedFilterFormSection />}

            <div className={'d-flex justify-content-end'}>
              <Anchor
                to={'/'}
                leftIcon={
                  visible ? (
                    <IconMinus className={'text-primary'} />
                  ) : (
                    <IconPlus className={'text-primary'} />
                  )
                }
                className={'text-decoration-underline'}
                onClick={onVisibleClick}
                color={'black'}
              >
                {t('advanced_search_filters')}
              </Anchor>
            </div>
          </>
        )}
      </form>
    </>
  )
}

FilterForm.propTypes = {
  advancedFilter: PropTypes.bool,
  withSearch: PropTypes.bool,
}
