import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { TextInput, FormFieldController } from 'components/FormElements'
import { useFormSubmit } from 'hooks'
import { JobFormSection } from './JobFormSection'
import { jobInviteHelper } from 'features/job/helpers'

export const JobInviteForm = ({
  initialValues,
  jobSelect = false,
  candidate,
  bulk = false,
  ...props
}) => {
  const { t } = useTranslation()

  const defaultValues = {
    message: '',
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(jobSelect)),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    setValue,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      let values = {
        ...data,
      }

      const { job } = data
      if (jobSelect && job) {
        values = {
          ...values,
          job_id: job?.id,
        }
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const onJobChange = value => {
    setValue('job', value, { shouldValidate: true })

    if (value && candidate) {
      const message = jobInviteHelper.message.createDefaultMessage({ candidate, job: value })

      setValue('message', message)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          {jobSelect && <JobFormSection onJobChange={onJobChange} />}

          <FormFieldController
            name={'message'}
            control={control}
            component={TextInput}
            id={'message'}
            label={t('message')}
            placeholder={t('message')}
            type={'textarea'}
          />

          <div className="mt-3 pt-3">
            <div className="text-center">
              <Button type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
                {t('submit_invite')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

JobInviteForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  jobSelect: PropTypes.bool,
  candidate: PropTypes.object,
  bulk: PropTypes.bool,
}
