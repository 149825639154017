import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EditForm } from './EditForm'
import { profileEmployerUpdatePA } from 'features/profile/store'
import { useNotify } from 'hooks'
import { ProfileAvatarManager } from 'features/company/components/Profile/ProfileLogoManager'
import { useUserSubscription } from 'features/user/hooks'
import { useTariffStaticOptions } from 'features/billing/hooks'
import { ProfileBannerManager } from 'features/company/components/Profile/ProfileBannerManager'

export const ProfileEditContainer = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const { alias } = useUserSubscription()
  const { isEnterprise } = useTariffStaticOptions()

  const isEnterpriseTariff = isEnterprise(alias)

  const onSubmit = async values => {
    await dispatch(profileEmployerUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    navigate('/company/profile')
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className={'mb-3'}>
            <Row>
              <Col md={6}>
                <ProfileAvatarManager />
              </Col>

              {isEnterpriseTariff && (
                <Col md={6}>
                  <ProfileBannerManager />
                </Col>
              )}
            </Row>
          </div>

          <EditForm onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  )
}

ProfileEditContainer.propTypes = {}
