import React from 'react'
import { useTranslation } from 'react-i18next'

export const InfoContent = ({ image, title, text, action }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div>
        <img src={image} alt={'info'} className={'img-fluid'} />
      </div>
      <div className={'mt-4'}>
        <h3>{title}</h3>
      </div>
      <div>
        <p className={'fw-light'}>{text}</p>
      </div>
      {action && <div className={'mt-4'}>{action}</div>}
    </div>
  )
}
