import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { WizardForm } from './WizardForm'
import { profileStudentUpdatePA } from '../../../store'
import { useNotify } from 'hooks'

export const CompleteProfileContainer = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    await dispatch(profileStudentUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    navigate('/profile')
  }
  return (
    <>
      <WizardForm onSubmit={onSubmit} />
    </>
  )
}

CompleteProfileContainer.propTypes = {}
