import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { JobForm } from '../JobForm'
import { jobCreatePA } from 'features/job/store'
import { JOB_STATUS } from 'features/job/consts/general'
import { useNotify } from 'hooks'
import { useUserPermissions } from 'features/user/hooks'
import { useJobPosting } from 'features/job/hooks'

export const JobCreate = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()
  const { isJobPostingAllow } = useUserPermissions()
  const { onPostingSuccess } = useJobPosting()

  const onSubmit = async values => {
    const { status } = values

    await dispatch(jobCreatePA.request(values))

    let route
    let message

    if (status === JOB_STATUS.ACTIVE) {
      onPostingSuccess()

      message = t('job_posted_successfully')
      route = '/jobs/my/active'
    } else {
      message = t('saved_to_draft_successfully')
      route = '/jobs/my/drafts'
    }

    showNotification({
      type: 'success',
      message: message,
    })

    navigate(route)
  }

  const isPostingAllow = isJobPostingAllow()

  return (
    <>
      <Card>
        <CardBody>
          <JobForm defaultValues onSubmit={onSubmit} isPostingAllow={isPostingAllow} />
        </CardBody>
      </Card>
    </>
  )
}

JobCreate.propTypes = {}
