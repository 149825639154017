import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import s from './Education.module.scss'
import { EducationBlock } from 'features/student/components/Profile/InfoBlocks'

export const Education = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  return (
    <>
      <Card>
        <CardBody>
          <EducationBlock data={user?.student_profile} />
        </CardBody>
      </Card>
    </>
  )
}

Education.propTypes = {}
