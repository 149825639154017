import axios from 'axios'
import { RequestUtils } from 'utils'

class ProfileAPI {
  getProfile = async params => {
    return axios.get('api/profile', { params })
  }

  updateStudentProfile = async params => {
    const formData = new FormData()

    RequestUtils.buildFormData(formData, params, '')

    return axios.post('api/profile/student', formData)
  }

  updateEmployerProfile = async params => {
    return axios.post('api/profile/employer', params)
  }

  updatePassword = async params => {
    return axios.put('api/profile/password', params)
  }
}

export const profileAPI = new ProfileAPI()
