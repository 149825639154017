import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useEmployersFetch } from 'features/employer/hooks'

export const CompanyFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data, loading, onFetch } = useEmployersFetch()

  const options = data?.data

  const [fieldTriggered, setFieldTriggered] = useState(false)

  useEffect(() => {
    if (fieldTriggered && !loading && !options) {
      onFetch({
        pagination: false,
        sort: [{ id: 'company_name', desc: false }],
      })
    }
  }, [fieldTriggered, loading, !!options])

  return (
    <>
      <FormFieldController
        name={'user'}
        control={control}
        component={Combobox}
        id={'user'}
        label={t('company')}
        placeholder={t('company')}
        options={options}
        isLoading={loading}
        getOptionLabel={option => option?.employer_profile?.company_name}
        getOptionValue={option => option.id}
        isClearable
        onFocus={() => setFieldTriggered(true)}
      />
    </>
  )
}

CompanyFormSection.propTypes = {}
