import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Row, Col } from 'reactstrap'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { useUser, useUserSubscription } from 'features/user'
import { Combobox, FormFieldController, TextInput } from 'components/FormElements'
import { useSelectOptions, useFormSubmit } from 'hooks'
import { useTariffStaticOptions } from 'features/billing/hooks'
import { CTASection } from './Sections'

export const EditForm = props => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { alias } = useUserSubscription()
  const { isEnterprise } = useTariffStaticOptions()

  const isEnterpriseTariff = isEnterprise(alias)

  const { employer_profile } = user

  const {
    company_name,
    industry,
    company_website,
    company_size,
    company_cta_text,
    company_cta_button_text,
    company_cta_button_link,
  } = employer_profile

  const defaultValues = {
    employer_profile: {
      company_name,
      industry_id: industry?.id || null,
      company_website: company_website || '',
      company_size_id: company_size?.id || null,
      company_cta_text: company_cta_text || '',
      company_cta_button_text: company_cta_button_text || '',
      company_cta_button_link: company_cta_button_link || '',
    },
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: { withCTA: isEnterpriseTariff },
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      const {
        employer_profile: {
          company_cta_text,
          company_cta_button_text,
          company_cta_button_link,
          ...otherValues
        },
      } = data

      let values = {
        ...otherValues,
      }

      if (isEnterpriseTariff) {
        values = {
          ...values,
          company_cta_text,
          company_cta_button_text,
          company_cta_button_link,
        }
      }

      await onFormSubmit({ employer_profile: values })
    } catch (error) {}
  }

  const { getCompanySizes, getIndustries } = useSelectOptions()

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <FormFieldController
                name={'employer_profile.company_name'}
                control={control}
                component={TextInput}
                id={'employer_profile.company_name'}
                label={t('company_name')}
                placeholder={t('company_name')}
              />
            </Col>

            <Col md={6}>
              <FormFieldController
                name={'employer_profile.industry_id'}
                control={control}
                component={Combobox}
                id={'employer_profile.industry_id'}
                label={t('industry')}
                placeholder={t('industry')}
                options={getIndustries()}
                isDetermineValue
                normalize={option => (option !== null ? option.value : null)}
                menuPortalTarget={document.querySelector('body')}
                isClearable
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormFieldController
                name={'employer_profile.company_website'}
                control={control}
                component={TextInput}
                id={'employer_profile.company_website'}
                label={t('website')}
                placeholder={t('website')}
              />
            </Col>

            <Col md={6}>
              <FormFieldController
                name={'employer_profile.company_size_id'}
                control={control}
                component={Combobox}
                id={'employer_profile.company_size_id'}
                label={t('company_size')}
                placeholder={t('company_size')}
                options={getCompanySizes()}
                isDetermineValue
                normalize={option => (option !== null ? option.value : null)}
                menuPortalTarget={document.querySelector('body')}
                isClearable
              />
            </Col>
          </Row>

          {isEnterpriseTariff && <CTASection />}

          <div className="mt-3">
            <div className="button-items">
              <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

EditForm.propTypes = {
  onSubmit: PropTypes.func,
}
