import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUser } from 'features/user'
import { FormatUtils } from 'utils'

export const Basic = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { email, phone, employer_profile } = user

  const { profile_position } = employer_profile

  return (
    <>
      <Card>
        <CardBody>
          <div className={'mb-4'}>
            <h5>{t('email')}</h5>
            <p>{email}</p>
          </div>

          <div className={'mb-4'}>
            <h5>{t('phone')}</h5>
            <p>{FormatUtils.formatPhone(phone)}</p>
          </div>

          <div className={'mb-4'}>
            <h5>{t('position')}</h5>
            <p>{profile_position?.value}</p>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

Basic.propTypes = {}
