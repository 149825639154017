import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { jobData } from 'features/job/store'
import { useJob } from 'features/job/hooks'
import { ApplyControl } from 'features/job/components/JobActionControls'

export const Apply = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { job } = useJob()

  const onSuccess = data => {
    dispatch(jobData.updateData({ myApplication: data }))
  }

  return (
    <>
      <ApplyControl
        job={job}
        applied={!!job?.myApplication}
        onSuccess={onSuccess}
        className={'font-size-18 fw-medium'}
      />
    </>
  )
}

Apply.propTypes = {}
