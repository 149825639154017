import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    updateData(state, action) {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    updateEmployerProfile(state, action) {
      state.data = {
        ...state.data,
        employer_profile: {
          ...state.data?.employer_profile,
          ...action.payload,
        },
      }
    },
    updateStudentProfile(state, action) {
      state.data = {
        ...state.data,
        student_profile: {
          ...state.data?.student_profile,
          ...action.payload,
        },
      }
    },
    increaseMonthPostedJobs(state, action) {
      state.data = {
        ...state.data,
        month_posted_jobs_count: state.data.month_posted_jobs_count + action.payload,
      }
    },
    decreaseSubscriptionCredits(state, action) {
      const credits = state.data?.subscription?.credits || 0
      const result = credits - action.payload

      state.data = {
        ...state.data,
        subscription: {
          ...state.data?.subscription,
          credits: result < 0 ? 0 : result,
        },
      }
    },
    updateUnreadInvites(state, action) {
      state.data = {
        ...state.data,
        unread_invites_count: action.payload >= 0 ? action.payload : 0,
      }
    },
    cleanData() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} setData
 * @property {function} updateData
 * @property {function} updateEmployerProfile
 * @property {function} updateStudentProfile
 * @property {function} increaseMonthPostedJobs
 * @property {function} decreaseSubscriptionCredits
 * @property {function} updateUnreadInvites
 * @property {function} cleanState
 */

export const userData = userDataSlice.actions

export const selectUserData = state => state.user.data.data

export default userDataSlice.reducer
