import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { GetScheduleLinkControl } from 'features/mentor/components/MentorActionControls'

export const ScheduleLinkControl = ({ candidate = {}, onSuccess, ...props }) => {
  const { t } = useTranslation()

  const { id } = candidate

  return (
    <div>
      <GetScheduleLinkControl id={id} {...props} />
    </div>
  )
}

ScheduleLinkControl.propTypes = {
  candidate: PropTypes.object,
  onSuccess: PropTypes.func,
}
