import React from 'react'
import { useSelector } from 'react-redux'

export const useEmployer = () => {
  const { data: employer } = useSelector(state => state.employer.data)

  return {
    employer,
  }
}
