import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BasicProfileFormSection,
  AvatarFormSection,
} from 'features/profile/components/FormSections/StudentProfileFormSections'
import { useUser } from 'features/user'

export const BasicProfileSections = () => {
  const { t } = useTranslation()

  const { isMentor } = useUser()

  return (
    <>
      {isMentor() && <AvatarFormSection />}

      <BasicProfileFormSection withMentor={isMentor()} />
    </>
  )
}

BasicProfileSections.propTypes = {}
