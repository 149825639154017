import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from 'features/users/api'
import {
  MENTOR_SCHEDULE_LINK_GET_REQUEST,
  MENTOR_SCHEDULE_LINK_GET_SUCCESS,
  MENTOR_SCHEDULE_LINK_GET_FAILED,
} from './actionTypes'

export const mentorScheduleLinkGetPA = createPromiseAction(
  MENTOR_SCHEDULE_LINK_GET_REQUEST,
  MENTOR_SCHEDULE_LINK_GET_SUCCESS,
  MENTOR_SCHEDULE_LINK_GET_FAILED
)()

function* getData(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(userAPI.getScheduleLink, id, params)

    const { data } = response

    yield put(mentorScheduleLinkGetPA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* getSaga() {
  yield takeEvery(mentorScheduleLinkGetPA.request, getData)
}
