import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const TariffFeature = ({ value }) => {
  const { t } = useTranslation()

  const isBoolean = typeof value === 'boolean'

  const renderBoolean = () =>
    value ? (
      <i className={'bx bx-check-circle text-success font-size-24'} />
    ) : (
      <i className={'bx bx-x-circle text-danger font-size-24'} />
    )

  return <div className={'text-center'}>{isBoolean ? renderBoolean() : value}</div>
}

TariffFeature.propTypes = {
  value: PropTypes.any,
}
