import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { profileCandidateAPI } from '../../../../api'
import { userData } from 'features/user/store'
import {
  PROFILE_STUDENT_RESUME_UPLOAD_REQUEST,
  PROFILE_STUDENT_RESUME_UPLOAD_SUCCESS,
  PROFILE_STUDENT_RESUME_UPLOAD_FAILED,
} from './actionTypes'

export const profileStudentResumeUploadPA = createPromiseAction(
  PROFILE_STUDENT_RESUME_UPLOAD_REQUEST,
  PROFILE_STUDENT_RESUME_UPLOAD_SUCCESS,
  PROFILE_STUDENT_RESUME_UPLOAD_FAILED
)()

function* upload(action) {
  try {
    const response = yield call(profileCandidateAPI.uploadResume, action.payload)
    const { data } = response

    yield put(profileStudentResumeUploadPA.success({}))

    yield put(userData.updateStudentProfile({ resume: data?.data }))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* uploadSaga() {
  yield takeEvery(profileStudentResumeUploadPA.request, upload)
}
