import React from 'react'
import { Navigate } from 'react-router-dom'
import { BasicLayout, PanelLayout } from 'components/Layouts'
import { ROLE } from 'features/user'
import { PanelSwitch, RouteGuard, PrivateGuard } from 'routes'
import { getGeneralRoutes, getProfileCompleteRoutes } from './routes'

const mapChildrenRoutes = (routs, user) => [
  ...routs.map(({ path, index, element, roles, guards }, i) => {
    return {
      key: `${i}-${path}`,
      path,
      index,
      element: (
        <RouteGuard roles={roles} guards={guards} user={user}>
          {element}
        </RouteGuard>
      ),
    }
  }),
]

export const getPrivateRoutes = (isLoggedIn, user) => {
  return [
    {
      path: '/*',
      element: <PrivateGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          index: true,
          element: <PanelSwitch user={user} />,
        },
        {
          element: <PanelLayout />,
          children: [...mapChildrenRoutes(getGeneralRoutes(), user)],
        },
        {
          path: 'profile/complete/*',
          element: (
            <RouteGuard roles={[ROLE.STUDENT]} user={user}>
              <BasicLayout />
            </RouteGuard>
          ),
          children: mapChildrenRoutes(getProfileCompleteRoutes(), user),
        },
        {
          path: '*',
          element: <Navigate to={`/`} replace />,
        },
      ],
    },
  ]
}
