import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'

import { mentorMentorsList } from 'features/mentor/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'
import { GeneralMentorsFilter } from 'features/mentor/components/Mentors/Filters'

const GeneralMentorsContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(mentorMentorsList.resetDataState({}))
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    let params = {
      page: props.page,
      filters: {
        ...props.filters,
      },
      sort: [{ id: 'candidate_score', desc: true }],
    }

    dispatch(mentorMentorsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(mentorMentorsList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(mentorMentorsList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <h4 className="font-size-18">{t('mentors')}</h4>

      <div className={'mb-2'}>
        <GeneralMentorsFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
      </div>

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid items={props.list} />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('mentors_not_found')}</NoDataTextMessage>
      )}
    </>
  )
}

GeneralMentorsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.mentor.mentors.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const GeneralMentorsContainer = connect(mapStateToProps)(GeneralMentorsContainerComponent)
