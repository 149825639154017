import React from 'react'

import { useUser } from 'features/user/hooks'
import { CandidateJobSearchStatus } from './CandidateJobSearchStatus'

export const UserAlertsManager = () => {
  const { isStudent, isLastActivityMonthAgo } = useUser()

  return <>{isStudent() && isLastActivityMonthAgo() && <CandidateJobSearchStatus />}</>
}

UserAlertsManager.propTypes = {}
