import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { CandidateDetailsContainer } from 'features/candidate/components/Candidate/CandidateDetails'

export const Candidate = props => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'candidate'} />

      <PageContent>
        <PageHeader back />

        <CandidateDetailsContainer />
      </PageContent>
    </>
  )
}

Candidate.propTypes = {}
