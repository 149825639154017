import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Table } from 'reactstrap'

import s from './Info.module.scss'
import { useUser, useUserSubscription } from 'features/user'
import { SubscriptionBadge } from 'features/billing/components/Elements'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'

export const Info = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { month_posted_jobs_count } = user

  const { alias, credits } = useUserSubscription()

  return (
    <>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className={`table mb-0 table-bordered ${s.table}`}>
              <tbody>
                <tr>
                  <th scope="row">{t('subscription_plan')}:</th>
                  <td>{alias ? <SubscriptionBadge alias={alias} size={'xs'} /> : '-'}</td>
                </tr>
                <tr>
                  <th scope="row">{t('number_of_posted_jobs_per_month')}:</th>
                  <td>{month_posted_jobs_count}</td>
                </tr>
                {alias === BILLING_TARIFF_ALIAS?.FREE && (
                  <tr>
                    <th scope="row">{t('credits')}:</th>
                    <td>{credits || 0}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

Info.propTypes = {}
