import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import { PageContent } from 'components/Layouts'

export const MyJobsListViewLayout = () => {
  return (
    <>
      <PageContent>
        <Outlet />

        {/*<SubscribeBlock />*/}
      </PageContent>
    </>
  )
}

MyJobsListViewLayout.propTypes = {}
