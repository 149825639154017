import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterCard } from 'features/filter/components'
import { CANDIDATES_FILTERS_INITIAL_VALUES } from 'features/candidate/consts/filters'
import { FilterForm } from '../FilterForm'

export const GeneralCandidatesFilter = ({
  defaultValues,
  onFiltersChange,
  advancedFilter = false,
  renderRightSection,
  withSearch = false,
}) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...CANDIDATES_FILTERS_INITIAL_VALUES,
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterCard
        reset={isApplied}
        onReset={onReset}
        rightSection={renderRightSection && renderRightSection({ isApplied })}
      >
        <FilterForm advancedFilter={advancedFilter} withSearch={withSearch} />
      </FilterCard>
    </FormProvider>
  )
}

GeneralCandidatesFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
  advancedFilter: PropTypes.bool,
  renderRightSection: PropTypes.func,
  withSearch: PropTypes.bool,
}
