import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { staticAPI } from 'features/static/api'
import { billingTariffsList } from './slice'

export function* getList() {
  try {
    const response = yield call(staticAPI.getTariffs)

    const { data } = response

    yield put(billingTariffsList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(billingTariffsList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(billingTariffsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
