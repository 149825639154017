import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { useFormContext } from 'react-hook-form'

import { Logout } from './Logout'
import { Anchor } from 'components/Elements'

export const Header = ({ step, onSubmit, submittable }) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext()

  const onFormSubmit = handleSubmit(onSubmit)

  return (
    <>
      <Row className="align-items-center">
        <Col md={2}>
          <Logout />
        </Col>
        <Col md={8}>
          <div className="text-center p-4">
            <h3 className={'mb-0'}>{t('lets_create_your_profile')}</h3>
          </div>
        </Col>
        <Col md={2}>
          {submittable && step !== 0 && (
            <div className={'text-end'}>
              <Anchor
                to={``}
                className="text-primary"
                disabled={isSubmitting}
                onClick={onFormSubmit}
              >
                {t('do_it_later')}
              </Anchor>
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

Header.propTypes = {}
