import * as Yup from 'yup'

export const validationSchema = jobSelect =>
  jobSelect
    ? Yup.object().shape({ ...jobValidation, ...messageValidation })
    : Yup.object().shape({ ...messageValidation })

const messageValidation = {
  message: Yup.string().required('field.error.required'),
}

const jobValidation = {
  job: Yup.mixed().required('field.error.required'),
}
