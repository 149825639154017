import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor, ConfirmModal } from 'components/Elements'
import { useAsyncDispatch, useConfirmPopup, useNotify } from 'hooks'
import { JOB_STATUS } from 'features/job/consts/general'
import { jobStatusUpdatePA } from 'features/job/store'

export const CloseControl = ({ job, className = '', onSuccess }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onJobCloseConfirm = async () => {
    try {
      const status = JOB_STATUS.CLOSED
      const data = await onAsyncDispatch(jobStatusUpdatePA.request, { id: job?.id, status })

      onSuccess && onSuccess(data?.data)

      showNotification({
        type: 'success',
        message: t('job_closed_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onJobCloseConfirm })
  // --- //

  return (
    <>
      <div>
        <Anchor to={''} disabled={loading} className={className} onClick={open}>
          {t('close_job')}
        </Anchor>
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}

CloseControl.propTypes = {
  job: PropTypes.object,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
}
