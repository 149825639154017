import { combineReducers } from 'redux'

import layout from './layout/reducer'
import auth from 'features/auth/store/reducers'
import user from 'features/user/store/reducers'
import panel from 'features/panel/store/reducers'
import staticReducer from 'features/static/store/reducers'
// import profile from 'features/profile/store/reducers'
import job from 'features/job/store/reducers'
import candidate from 'features/candidate/store/reducers'
import employer from 'features/employer/store/reducers'
import jobInvite from 'features/job-invite/store/reducers'
import billing from 'features/billing/store/reducers'
import mentor from 'features/mentor/store/reducers'
import immigrate from 'features/immigrate/store/reducers'

const rootReducer = combineReducers({
  layout,
  auth,
  user,
  panel,
  static: staticReducer,
  job,
  candidate,
  employer,
  jobInvite,
  billing,
  mentor,
  immigrate,
})

export default rootReducer
