import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { FormFieldController, Combobox } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const SelfIdentificationFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getProfileSelfIdentifications, getProfileGenders, getProfileEthnics } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <FormFieldController
      name={name}
      control={control}
      component={Combobox}
      id={name}
      label={label}
      placeholder={label}
      options={options}
      isDetermineValue
      normalize={option => (option !== null ? option.value : null)}
      menuPortalTarget={document.querySelector('body')}
      isClearable
      {...params}
    />
  )

  return (
    <>
      <Row>
        <Col>
          {renderSelectField({
            name: 'student_profile.profile_self_identifications',
            label: `${t('which_of_this_identify_as')}?`,
            options: getProfileSelfIdentifications(),
            isMulti: true,
            normalize: value => value.map(item => item.value),
            isOptionDisabled: (option, selectValue) => {
              if (selectValue && selectValue.length) {
                return option.weight !== selectValue[0]?.weight
              }
              return false
            },
          })}
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.gender_id',
            label: t('gender'),
            options: getProfileGenders(),
          })}
        </Col>

        <Col lg={4}>
          {renderSelectField({
            name: 'student_profile.ethnic_id',
            label: t('race_ethnicity'),
            options: getProfileEthnics(),
          })}
        </Col>
      </Row>
    </>
  )
}
