import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { mentorData } from './slice'
import { userAPI } from 'features/users/api'
import { RequestUtils } from 'utils'

function* getData({ payload: { id, params = {} } }) {
  try {
    let requestParams = {
      includes: ['avatar', 'studentProfile'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(userAPI.getUser, id, requestParams)

    const { data } = response

    yield put(mentorData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(mentorData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(mentorData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
