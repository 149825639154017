import React, { useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { CertificationsField } from 'features/certification/components/FormElements'

export const CertificationsFormSection = () => {
  const { t } = useTranslation()

  const { control, getValues } = useFormContext()

  const { getProfileCertifications } = useSelectOptions()

  const defaultOptions = useMemo(() => {
    const defaultOptions = getProfileCertifications()

    const values = getValues('student_profile.profile_certifications') || []

    const customOptions = values.filter(item => item.is_default === false)

    return [...defaultOptions, ...customOptions]
  }, [getValues])

  const [options, setOptions] = useState(defaultOptions)

  const onCertificateAdd = data => {
    setOptions(prevState => [...prevState, data])
  }

  return (
    <>
      <FormFieldController
        name={'student_profile.profile_certifications'}
        control={control}
        component={CertificationsField}
        id={'student_profile.profile_certifications'}
        options={options}
        onOptionAdd={onCertificateAdd}
      />
    </>
  )
}
