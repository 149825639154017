import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Avatar, Button } from 'components/Elements'
import { FileButtonControl } from '../FileButtonControl'
import s from './AvatarPickerControl.module.scss'

export const AvatarPickerControl = ({
  value,
  onChange,
  remove = true,
  accept = 'image/png, image/jpeg',
  buttonProps = {},
  avatarVariant,
  uploadLabel,
  deleteLabel,
}) => {
  const { t } = useTranslation()

  const onPick = file => {
    onChange(file)
  }

  const isLocaleFile = value && value instanceof File

  const [image, setImage] = useState(isLocaleFile ? null : value)

  useEffect(() => {
    if (value) {
      if (isLocaleFile) {
        const reader = new FileReader()
        reader.onload = () => {
          if (reader.readyState === 2) {
            setImage(reader.result)
          }
        }
        reader.readAsDataURL(value)
      } else {
        setImage(value)
      }
    } else {
      setImage(null)
    }
  }, [value])

  const handleRemove = () => {
    onChange(null)
  }

  uploadLabel = uploadLabel || t('select_photo')
  deleteLabel = deleteLabel || t('delete_photo')

  return (
    <>
      <div className={s.block}>
        <div>
          <Avatar size={'lg'} src={image} variant={avatarVariant} />
        </div>

        <div className={s.controlsBlock}>
          <FileButtonControl
            accept={accept}
            onChange={onPick}
            label={uploadLabel}
            buttonProps={{
              size: 'sm',
              outline: false,
              ...buttonProps,
            }}
          />

          {remove && value && (
            <Button outline color={'danger'} onClick={handleRemove} size={'sm'}>
              {deleteLabel}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

AvatarPickerControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.node,
  accept: PropTypes.string,
  buttonProps: PropTypes.object,
  remove: PropTypes.bool,
  avatarVariant: PropTypes.string,
  uploadLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
}
