import React from 'react'
import { useSelector } from 'react-redux'

import { JOB_STATUS } from 'features/job/consts/general'

export const useJob = () => {
  const { data: job } = useSelector(state => state.job.data)

  const isActive = () => {
    return job?.status === JOB_STATUS.ACTIVE
  }

  const isDrafted = () => {
    return job?.status === JOB_STATUS.DRAFT
  }

  const isClosed = () => {
    return job?.status === JOB_STATUS.CLOSED
  }

  return {
    job,
    isActive,
    isDrafted,
    isClosed,
  }
}
