import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { PageContent } from 'components/Layouts'
import { Tabs } from './Tabs'

export const BrowseJobs = () => {
  const { t } = useTranslation()

  return (
    <PageContent>
      <div className={'mb-2'}>
        <Tabs />
      </div>

      <Outlet />
    </PageContent>
  )
}

BrowseJobs.propTypes = {}
