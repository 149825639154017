import React from "react";
import { BasicLayout } from "components/Layouts";
import { Terms, Privacy } from "features/legal/routes";

//config
export const getPublicRoutes = () => {
  return [
    {
      path: "/*",
      element: <BasicLayout lang={true} />,
      children: [
        {
          path: `terms`,
          element: <Terms />
        },
        {
          path: `privacy`,
          element: <Privacy />
        }
      ]
    }
  ];
};
