import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DataShower } from 'components/Elements'
import { candidateData } from 'features/candidate/store'
import { CandidateDetailsContent } from '../CandidateDetailsContent'

const CandidateDetailsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(candidateData.getData({ id }))

    return () => {
      dispatch(candidateData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFetched={!!props.data}
        isFailed={!!props.error}
        error={props.error}
      >
        <CandidateDetailsContent candidate={props.data} />
      </DataShower>
    </>
  )
}

CandidateDetailsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.candidate.data
  return {
    data,
    loading,
    error,
  }
}

export const CandidateDetailsContainer = connect(mapStateToProps)(
  CandidateDetailsContainerComponent
)
