import * as Yup from 'yup'

import { ValidationsUtils } from 'utils'
import { ROLE } from 'features/user'

const passwordMinLength = 8

export const validationSchema = role =>
  role === ROLE.STUDENT
    ? Yup.object().shape({ ...generalValidation, ...studentProfileValidation })
    : Yup.object().shape({ ...generalValidation, ...employerProfileValidation })

const generalValidation = {
  first_name: Yup.string().required('field.error.required'),
  last_name: Yup.string().required('field.error.required'),
  email: Yup.string().email('field.error.invalid').required('field.error.required'),
  phone: Yup.string()
    .required('field.error.required')
    .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat),

  password: Yup.string()
    .required('field.error.required')
    .min(passwordMinLength, 'field.error.password.length'),
  password_confirmation: Yup.string()
    .required('field.error.required')
    .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
    .min(passwordMinLength, 'field.error.password.length'),
  accept: Yup.bool().oneOf([true], 'required'),
}

const studentProfileValidation = {
  student_profile: Yup.object().shape({
    profile_status_id: Yup.mixed().required('field.error.required'),
  }),
}

const employerProfileValidation = {
  employer_profile: Yup.object().shape({
    company_name: Yup.string().required('field.error.required'),
    profile_position_id: Yup.mixed().required('field.error.required'),
  }),
}
