import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EditForm } from './EditForm'
import { profileEmployerUpdatePA } from '../../../store'
import { useNotify } from 'hooks'
import { ProfileAvatarManager } from 'features/profile/components/ProfileAvatarManager'

export const EmployerProfileEdit = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    await dispatch(profileEmployerUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    navigate('/profile')
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className={'mb-3'}>
            <ProfileAvatarManager />
          </div>

          <EditForm onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  )
}

EmployerProfileEdit.propTypes = {}
