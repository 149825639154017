import React from 'react'

import { useUser } from 'features/user/hooks'
import { subscriptionHelper } from 'features/billing/helpers'

export const useUserSubscription = () => {
  const { user } = useUser()

  const subscription = user?.subscription
  const alias = user?.subscription?.tariff?.alias || null
  const credits = user?.subscription?.credits

  const permissions = subscriptionHelper.general.getSubscriptionPermissions(alias)

  const isPermissionAllow = permission => {
    return permissions.includes(permission)
  }

  return {
    subscription,
    alias,
    credits,
    permissions,
    isPermissionAllow,
  }
}
