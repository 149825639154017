import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { SavedJobsContainer } from 'features/job/components/Jobs/SavedJobs'

export const JobsSaved = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'saved_jobs'} />

      <PageContent>
        <SavedJobsContainer />
      </PageContent>
    </>
  )
}

JobsSaved.propTypes = {}
