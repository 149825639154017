import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <div className={'text-center'}>
      <h2>{t('subscription_header')}</h2>

      <p className={'font-size-18 fw-medium text-muted'}>{t('subscription_subheader')}</p>
    </div>
  )
}

Header.propTypes = {}
