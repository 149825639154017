import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MentorsFilterFormSection } from 'features/mentor/components/FormSections'

export const FilterForm = props => {
  const { t } = useTranslation()

  return (
    <>
      <form>
        <MentorsFilterFormSection />
      </form>
    </>
  )
}

FilterForm.propTypes = {}
