import axios from 'axios'

class JobAPI {
  getJob = async (id, params) => {
    return axios.get(`api/jobs/${id}`, {
      params,
    })
  }

  updateJob = async (id, params) => {
    return axios.put(`api/jobs/${id}`, params)
  }

  addSavedJob = async id => {
    return axios.post(`api/jobs/${id}/saved`)
  }

  deleteSavedJob = async id => {
    return axios.delete(`api/jobs/${id}/saved`)
  }

  createInvite = async (id, params) => {
    return axios.post(`api/jobs/${id}/invites`, params)
  }

  bulkInvite = async (id, params) => {
    return axios.post(`api/jobs/${id}/bulk-invites`, params)
  }

  createApplication = async (id, params) => {
    const formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/jobs/${id}/applications`, formData)
  }

  getJobCandidates = async (id, params) => {
    return axios.get(`api/jobs/${id}/candidates`, {
      params,
    })
  }

  getJobRecommendations = async (id, params) => {
    return axios.get(`api/jobs/${id}/recommendations`, {
      params,
    })
  }
}

export const jobAPI = new JobAPI()
