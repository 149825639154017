import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useMyJobsFetch } from 'features/job/hooks'

export const JobFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data, loading, onFetch } = useMyJobsFetch()

  const options = data?.data

  const [fieldTriggered, setFieldTriggered] = useState(false)

  useEffect(() => {
    if (fieldTriggered && !loading && !options) {
      onFetch({
        pagination: false,
        sort: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
      })
    }
  }, [fieldTriggered, loading, !!options])

  return (
    <>
      <Row>
        <Col lg={8} xl={6}>
          <FormFieldController
            name={'job'}
            control={control}
            component={Combobox}
            id={'job'}
            label={t('job')}
            placeholder={t('job')}
            options={options}
            isLoading={loading}
            getOptionLabel={option => option?.title}
            getOptionValue={option => option.id}
            isClearable
            onFocus={() => setFieldTriggered(true)}
          />
        </Col>
      </Row>
    </>
  )
}

JobFormSection.propTypes = {}
