import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { AvatarProvider } from 'components/Elements'

export const Info = ({ avatar, name, profile }) => {
  const { t } = useTranslation()

  const { profile_status, location_city, location_province } = profile

  const renderInfo = () => {
    const position = profile_status?.value

    let location =
      location_city && location_province ? (
        <>
          {position && ' · '}
          <span
            className={'text-muted'}
          >{`${location_city?.value}, ${location_province?.value}`}</span>
        </>
      ) : null

    return position || location ? (
      <p className={'mb-0'}>
        {position}
        {location}
      </p>
    ) : null
  }
  return (
    <>
      <div className={'d-flex'}>
        <div>
          <AvatarProvider avatar={avatar} className={'me-3'} size={'md'} />
        </div>

        <div className={'d-flex flex-column justify-content-center'}>
          <div className={'d-flex'}>
            <p className={'mb-0 font-size-18 fw-medium'}>{name}</p>
          </div>

          {renderInfo()}
        </div>
      </div>
    </>
  )
}

Info.propTypes = {
  avatar: PropTypes.any,
  name: PropTypes.string,
  profile: PropTypes.object,
}
