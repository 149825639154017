import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Alert, Button, Anchor } from 'components/Elements'
import {
  FormFieldController,
  TextInput,
  PasswordInputFiled,
  CheckboxField,
  PhoneInput,
} from 'components/FormElements'
import { ValidationsUtils } from 'utils'
import { ROLE } from 'features/user'
import { validationSchema } from './validation'
import s from './RegisterForm.module.scss'
import { StudentProfileFormSection } from './StudentProfileFormSection'
import { EmployerProfileFormSection } from './EmployerProfileFormSection'

const passwordMinLength = 8

export const RegisterForm = ({ role, isMentor = false, ...props }) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      accept: false,
    },
    resolver: yupResolver(validationSchema(role)),
  })

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isSubmitting },
    reset,
  } = methods

  const [alertError, setAlertError] = useState(null)

  const onSubmit = async data => {
    setAlertError(null)
    try {
      await props.onSubmit(data)

      reset()
    } catch (error) {
      setAlertError(error)
      ValidationsUtils.setServerSideErrors(error?.errors, setError)
    }
  }

  const watchAccept = watch('accept')

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {alertError && <Alert type={'error'}>{alertError?.message || t('error')}</Alert>}

        <Row>
          <Col md={6}>
            <FormFieldController
              name={'first_name'}
              control={control}
              component={TextInput}
              id={'first_name'}
              label={t('first_name')}
              placeholder={t('first_name')}
            />
          </Col>

          <Col md={6}>
            <FormFieldController
              name={'last_name'}
              control={control}
              component={TextInput}
              id={'last_name'}
              label={t('last_name')}
              placeholder={t('last_name')}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormFieldController
              name={'email'}
              control={control}
              component={TextInput}
              id={'email'}
              label={t('email')}
              placeholder={t('email')}
            />
          </Col>

          <Col md={6}>
            <FormFieldController
              name={'phone'}
              control={control}
              component={PhoneInput}
              id={'phone'}
              label={t('phone')}
              placeholder={t('phone')}
            />
          </Col>
        </Row>

        {role === ROLE.STUDENT && <StudentProfileFormSection />}

        {role === ROLE.EMPLOYER && <EmployerProfileFormSection />}

        <Row>
          <Col md={6}>
            <FormFieldController
              name={'password'}
              control={control}
              component={PasswordInputFiled}
              id={'password'}
              label={t('password')}
              placeholder={t('password')}
              translateParams={{ count: passwordMinLength }}
            />
          </Col>

          <Col md={6}>
            <FormFieldController
              name={'password_confirmation'}
              control={control}
              component={PasswordInputFiled}
              id={'password_confirmation'}
              label={t('confirm_password')}
              placeholder={t('confirm_password')}
              translateParams={{ count: passwordMinLength }}
            />
          </Col>
        </Row>

        <div className={'text-center'}>
          <div className={'mb-0'}>
            <label htmlFor={'accept'} className={s.label}>
              <FormFieldController
                name={'accept'}
                control={control}
                component={CheckboxField}
                id={'accept'}
                wrapClass={`form-checkbox-inline ${s.checkboxControl}`}
                meta={{}}
              />
              {t('register_confirm_text')}{' '}
              {role === ROLE.EMPLOYER ? (
                <a href={'https://isempower.com/'} target={'_blank'} rel={'noreferrer'}>
                  {t('employer_guidelines')}
                </a>
              ) : (
                <a href={'https://isempower.com/'} target={'_blank'} rel={'noreferrer'}>
                  {isMentor ? t('mentor_guidelines') : t('candidate_guidelines')}
                </a>
              )}
            </label>
          </div>
          <label htmlFor={'accept'} className={s.label}>
            {t('register_confirm_text_2')}{' '}
            <Anchor to={'/terms'} target={'_blank'} rel={'noreferrer'}>
              {t('terms_of_use')}
            </Anchor>{' '}
            {t('and')}{' '}
            <Anchor to={'/privacy'} target={'_blank'} rel={'noreferrer'}>
              {t('privacy_policy')}
            </Anchor>
          </label>
        </div>

        <div className={'text-center mt-4'}>
          <Button type={'submit'} disabled={isSubmitting || !watchAccept} loading={isSubmitting}>
            {t('create_account')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
