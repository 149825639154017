import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

import { BasicLayout } from '../BasicLayout'
import { ButtonLink } from 'components/Elements'

export const AuthLayout = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const rightSection = useMemo(() => {
    if (location.pathname !== '/login') {
      return <ButtonLink to={'/login'}>{t('login')}</ButtonLink>
    }
    return null
  }, [location.pathname])

  return (
    <BasicLayout headerProps={{ rightSection }}>
      <div className="account-pages">
        <Container>
          <Outlet />
        </Container>
      </div>
    </BasicLayout>
  )
}

AuthLayout.propTypes = {}
