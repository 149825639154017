import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AvatarPickerField, FormFieldController } from 'components/FormElements'
import { FileUtils } from 'utils'
import { FILE_MAX_SIZE } from 'consts/core'

export const AvatarFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <FormFieldController
        name={'avatar'}
        control={control}
        component={AvatarPickerField}
        translateParams={{
          text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
        }}
        remove={false}
      />
    </>
  )
}
