import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './ContactsBlock.module.scss'
import { FormatUtils } from 'utils'
import { ContactLink } from 'features/profile/components/Elements'

export const ContactsBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { email, phone } = data

  return email || phone ? (
    <div className={'mt-3'}>
      <h6>{t('contact_details')}</h6>
      <div className={'d-flex flex-wrap gap-2'}>
        {email && (
          <ContactLink href={`mailto:${email}`}>
            <i className={'bx bx-envelope'} />
          </ContactLink>
        )}

        {phone && (
          <ContactLink href={`tel:${FormatUtils.formatPhone(phone)}`}>
            <i className={'bx bx-phone'} />
          </ContactLink>
        )}
      </div>
    </div>
  ) : null
}

ContactsBlock.propTypes = {
  data: PropTypes.object,
}
