import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle, Anchor } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { ProfileEditContainer } from 'features/company/components/Profile/ProfileEdit'

export const CompanyProfileEdit = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'edit_company_profile'} />

      <PageContent>
        <PageHeader back />

        <ProfileEditContainer />
      </PageContent>
    </>
  )
}

CompanyProfileEdit.propTypes = {}
