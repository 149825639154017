import axios from 'axios'

class ProfileCandidateAPI {
  uploadResume = async data => {
    const formData = new FormData()
    formData.append('resume', data.resume)

    return axios.post('api/profile/student/resume', formData)
  }

  deleteResume = async () => {
    return axios.delete('api/profile/student/resume')
  }
}

export const profileCandidateAPI = new ProfileCandidateAPI()
