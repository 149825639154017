import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
}

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setData(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    cleanData() {
      return initialState
    },
  },
})

export const authData = authDataSlice.actions

export const selectAuthData = state => state.auth.data

export default authDataSlice.reducer
