import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { EmployerDetailsContainer } from 'features/employer/components/Employer/EmployerDetails'

export const Employer = props => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'candidate'} />

      <PageContent>
        <PageHeader back />

        <EmployerDetailsContainer />
      </PageContent>
    </>
  )
}

Employer.propTypes = {}
