import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useUser } from 'features/user/hooks'
import { ApplicationsBlock, RecommendationsBlock } from './Blocks'
import { useJob } from 'features/job/hooks'

export const Sidebar = () => {
  const { t } = useTranslation()

  const { isEmployer } = useUser()

  const { isActive } = useJob()

  return (
    <div>
      {isEmployer() && (
        <>
          <div>
            <ApplicationsBlock />
          </div>

          {isActive() && (
            <div className={'mt-3'}>
              <RecommendationsBlock />
            </div>
          )}
        </>
      )}
    </div>
  )
}

Sidebar.propTypes = {}
