import React from 'react'
import { useSelector } from 'react-redux'

export const usePanelJob = () => {
  const { data: job } = useSelector(state => state.job.panel.job)

  return {
    job,
  }
}
