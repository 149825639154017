import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Candidates } from '../candidates'
// import { InvitesRoot } from '../invites'
import { ApplicationsRoot } from '../applications'
import { Recommendations } from '../recommendations'
import { useUserPermissions } from 'features/user/hooks'
import { usePanelJob } from 'features/job/hooks'

export const CandidatesRoot = () => {
  const { isJobRecommendationAllow } = useUserPermissions()

  const { job } = usePanelJob()

  return (
    <Routes>
      <Route index element={<Candidates />} />

      {/*<Route path={'invites'} element={<InvitesRoot />} />*/}

      <Route path={'applications/*'} element={<ApplicationsRoot />} />

      <Route
        path={'recommendations'}
        element={
          <>
            {isJobRecommendationAllow(job) ? (
              <Recommendations />
            ) : (
              <Navigate to={'/billing/subscription'} replace={true} />
            )}
          </>
        }
      />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

CandidatesRoot.propTypes = {}
