import ReactGA from 'react-ga4'

class GaService {
  init = ({ id, options }) => {
    ReactGA.initialize(id, options)
  }

  dispatch = (data = {}) => {
    ReactGA.event({
      transport: 'xhr',
      ...data,
    })
  }
}

export const gaService = new GaService()
