import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUser } from 'features/user'
import { LogoutLink } from './LogoutLink'
import { AvatarProvider } from 'components/Elements'

export const ProfileMenu = props => {
  const { t } = useTranslation()

  const [menu, setMenu] = useState(false)

  const { user, getName } = useUser()

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <AvatarProvider avatar={user?.avatar} size={'xs'} />

          <span className={'text-nowrap'}>
            <span className="ms-2">{getName()}</span>

            <i className="mdi mdi-chevron-down" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item" onClick={() => setMenu(false)}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {t('profile')}
          </Link>

          <Link to="/profile/settings" className="dropdown-item" onClick={() => setMenu(false)}>
            <i className="bx bx-cog font-size-16 align-middle me-1" />
            {t('settings')}
          </Link>

          <div className="dropdown-divider" />

          <LogoutLink />
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
