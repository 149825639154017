import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { SpecificJobsContainer } from 'features/job/components/Jobs/SpecificJobs'

export const JobsRecommended = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'recommended_jobs'} />

      <SpecificJobsContainer
        withFilter={false}
        queryParams={{
          sort: [
            { id: 'top', desc: true },
            { id: 'posted_on', desc: true },
          ],
          filters: {
            preset: 'recommended_jobs',
          },
        }}
      />
    </>
  )
}

JobsRecommended.propTypes = {}
