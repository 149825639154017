import * as Yup from 'yup'

import { formHelper } from 'features/profile/helpers'

const { generalValidation } = formHelper.general

export const validationSchema = Yup.object().shape({
  ...generalValidation,

  employer_profile: Yup.object().shape({
    profile_position_id: Yup.mixed().required('field.error.required'),
  }),
})
