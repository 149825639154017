import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { GeneralEmployersContainer } from 'features/employer/components/Employers/GeneralEmployers'

export const Employers = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'employers'} />

      <PageContent>
        <GeneralEmployersContainer />
      </PageContent>
    </>
  )
}

Employers.propTypes = {}
