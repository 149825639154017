import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { employerEmployerJobsList } from 'features/employer/store'
import { NoDataTextMessage, OverlayDataShower, Pagination } from 'components/Elements'
import { Grid } from './Grid'
import { useEmployer } from 'features/employer/hooks'

const GeneralJobsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { employer } = useEmployer()

  useEffect(() => {
    return () => {
      dispatch(employerEmployerJobsList.cleanState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 20,
      filters: {
        user: employer?.id,
      },
      sort: [{ id: 'posted_on', desc: true }],
    }

    dispatch(employerEmployerJobsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(employerEmployerJobsList.setPage(item.selected + 1))
  }

  const onJobSavedSuccess = data => {
    dispatch(employerEmployerJobsList.updateListItem({ ...data }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <>
      <h4 className="font-size-18">{t('view_jobs')}</h4>

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Grid
          items={props.list}
          saveControlProps={{ onSuccess: onJobSavedSuccess }}
          withCompany={false}
        />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>

      {!props.list.length && !props.loading && !props.error && (
        <NoDataTextMessage>{t('no_jobs')}</NoDataTextMessage>
      )}
    </>
  )
}

GeneralJobsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page } = state.employer.employer.jobs.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    // filters,
  }
}

export const GeneralJobsContainer = connect(mapStateToProps)(GeneralJobsContainerComponent)
