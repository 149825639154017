import { combineReducers } from 'redux'

import data from './data/slice'
import candidates from './candidates/reducers'

const candidateReducer = combineReducers({
  data,
  candidates,
})

export default candidateReducer
