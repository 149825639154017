import { analyticsConfig } from 'configs'
import { NormalizeUtils } from 'utils'
import { gaService } from '../ga'

class AppAnalyticsService {
  enabled = NormalizeUtils.stringBoolToBoolean(process.env.REACT_APP_ANALYTICS_ENABLED) || false

  isEnabled = () => {
    return this.enabled
  }

  init = () => {
    if (this.isEnabled()) {
      gaService.init(analyticsConfig.ga)
    }
  }

  dispatch = (data = {}) => {
    if (this.isEnabled()) {
      gaService.dispatch(data)
    }
  }
}

export const appAnalyticsService = new AppAnalyticsService()
