import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Invites } from '../invites'

export const InvitesRoot = () => {
  return (
    <Routes>
      <Route index element={<Invites />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

InvitesRoot.propTypes = {}
