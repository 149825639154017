import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Jobs, JobsSaved, JobsApplied, JobsRecommended } from 'features/job/routes/jobs'
import { JobRoot } from 'features/job/routes/job'
import { MyRoot } from 'features/job/routes/my'
import { JobCreate } from 'features/job/routes/create'
import { RouteGuard } from 'routes'
import { ROLE } from 'features/user'
import { JobsLayout, BrowseJobs } from 'features/job/components/Layouts'

export const JobsRoot = () => {
  return (
    <Routes>
      <Route element={<JobsLayout />}>
        <Route element={<BrowseJobs />}>
          <Route
            index
            element={
              <RouteGuard roles={[ROLE.STUDENT]}>
                <Jobs />
              </RouteGuard>
            }
          />

          <Route
            path={'recommended'}
            element={
              <RouteGuard roles={[ROLE.STUDENT]}>
                <JobsRecommended />
              </RouteGuard>
            }
          />
        </Route>

        <Route
          path={'applied'}
          element={
            <RouteGuard roles={[ROLE.STUDENT]}>
              <JobsApplied />
            </RouteGuard>
          }
        />

        <Route
          path={'saved'}
          element={
            <RouteGuard roles={[ROLE.STUDENT]}>
              <JobsSaved />
            </RouteGuard>
          }
        />

        <Route
          path={'create'}
          element={
            <RouteGuard roles={[ROLE.EMPLOYER]}>
              <JobCreate />
            </RouteGuard>
          }
        />

        <Route
          path={'my/*'}
          element={
            <RouteGuard roles={[ROLE.EMPLOYER]}>
              <MyRoot />
            </RouteGuard>
          }
        />

        <Route path={':id/*'} element={<JobRoot />} />

        <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
      </Route>
    </Routes>
  )
}

JobRoot.propTypes = {}
