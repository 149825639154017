import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../../api'
import {
  JOB_SAVED_SUBMIT_REQUEST,
  JOB_SAVED_SUBMIT_SUCCESS,
  JOB_SAVED_SUBMIT_FAILED,
} from './actionTypes'

export const jobSavedSubmitPA = createPromiseAction(
  JOB_SAVED_SUBMIT_REQUEST,
  JOB_SAVED_SUBMIT_SUCCESS,
  JOB_SAVED_SUBMIT_FAILED
)()

function* submit(action) {
  try {
    const { id, saved } = action.payload

    const response = saved
      ? yield call(jobAPI.addSavedJob, id)
      : yield call(jobAPI.deleteSavedJob, id)

    const { data } = response

    yield put(jobSavedSubmitPA.success({}))

    resolvePromiseAction(action, { saved })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(jobSavedSubmitPA.request, submit)
}
