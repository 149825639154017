import { takeLatest, fork, put, all, call } from 'redux-saga/effects'
import _ from 'lodash'

import i18n from 'i18n'
import { jobInvitesAPI } from '../../../../api'
import { jobInviteInvitesReceivedList } from './slice'
import { RequestUtils } from 'utils'
import { seeReceivedInvites } from '../see/saga'
import { userData } from 'features/user/store'

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: ['job', 'sender'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(jobInvitesAPI.getReceivedInvites, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
      data: entries,
    } = data

    if (entries.length > 0) {
      let unSeenItem = _.find(entries, { seen: false })

      if (unSeenItem) {
        const seeSuccess = yield call(seeReceivedInvites)

        if (seeSuccess) {
          yield put(userData.updateUnreadInvites(0))
        }
      }
    }

    if (current_page && last_page && current_page > last_page) {
      yield put(
        jobInviteInvitesReceivedList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(jobInviteInvitesReceivedList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobInviteInvitesReceivedList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(jobInviteInvitesReceivedList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
