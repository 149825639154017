import axios from 'axios'

class StaticAPI {
  getProfileSelects = async params => {
    return axios.get('api/profile-selects', { params })
  }

  getTariffs = async () => {
    return axios.get('api/tariffs')
  }
}

export const staticAPI = new StaticAPI()
