import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { FormFieldController, FilePickerField } from 'components/FormElements'
import { FileUtils } from 'utils'
import { FILE_MAX_SIZE } from 'consts/core'

export const Attachments = ({ ...props }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const onDownload = file => {
    FileUtils.downloadFileByLink(file?.url)
  }

  return (
    <>
      <p className={'font-size-18'}>{t('resume')}</p>
      <FormFieldController
        name={'resume'}
        control={control}
        component={FilePickerField}
        buttonProps={{ label: t('add_resume') }}
        fileWrapClassName={'w-50'}
        accept={
          'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
        }
        translateParams={{
          text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
        }}
        onDownload={onDownload}
      />
    </>
  )
}

Attachments.propTypes = {}
