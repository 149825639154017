import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useUser } from 'features/user'
import { useJob } from 'features/job/hooks'
import s from './General.module.scss'
import { DateUtils } from 'utils'
import { JOB_STATUS } from 'features/job/consts/general'
import { PropertiesBlock } from 'features/job/components/Job/JobDetails/InfoBlocks'
import { Anchor, AvatarProvider } from 'components/Elements'
import { PaidJobBadge } from 'features/job/components/Elements'

const { ACTIVE, DRAFT } = JOB_STATUS

export const General = () => {
  const { t } = useTranslation()

  const { isStudent, isEmployer } = useUser()

  const { job } = useJob()
  const {
    user,
    status,
    created_at,
    posted_at,
    is_remote,
    multiple_locations,
    location_province,
    location_city,
  } = job
  const { employer_profile } = user || {}

  const config = {
    company: isStudent(),
    paid: isEmployer(),
  }

  const renderCompany = () => {
    if (employer_profile) {
      const { company_logo, company_name } = employer_profile

      return (
        <>
          <Anchor
            to={`/employers/${user?.id}`}
            color={'dark'}
            className={'text-decoration-underline'}
          >
            {company_logo && (
              <AvatarProvider
                className={'me-2'}
                variant={'logo'}
                avatar={company_logo}
                size={'xxs'}
              />
            )}

            {company_name}
          </Anchor>
        </>
      )
    }
    return null
  }

  const renderDate = () => {
    const label = status === ACTIVE ? t('posted_on') : t('created_on')
    const date = status === ACTIVE ? posted_at : created_at

    return (
      <>
        <span className={'text-muted'}>{label}:</span>{' '}
        {date && DateUtils.format(date, 'YYYY/MM/DD')}
      </>
    )
  }

  const renderLocation = () => {
    const province = location_province?.value
    const city = location_city?.value

    return (
      <>
        {province && city ? (
          <>
            <span className={'text-muted'}>{t('job_location')}:</span> {`${province}, ${city}`}
          </>
        ) : null}
      </>
    )
  }

  const renderSubHeader = () => {
    const company = config.company ? renderCompany() : null
    const date = renderDate()
    const location = !(is_remote || multiple_locations) ? renderLocation() : null
    return (
      <span className={'font-size-14'}>
        {company && (
          <>
            {company}
            {' · '}
          </>
        )}
        {date}
        {location && (
          <>
            {' · '}
            {location}
          </>
        )}
      </span>
    )
  }

  return (
    <div className={s.block}>
      <div>
        <div className={'d-flex flex-wrap gap-3 align-items-center'}>
          <h5 className={'mb-0'}>{job.title}</h5>

          {config.paid && job?.featured && <PaidJobBadge size={'xs'} />}

          {config.company && employer_profile.company_website && (
            <a
              href={employer_profile.company_website}
              className={'text-dark'}
              target={'_blank'}
              rel="noreferrer"
            >
              {t('visit_website')}
            </a>
          )}
        </div>
        {renderSubHeader()}
      </div>
      <div className={'mt-2'}>
        <PropertiesBlock data={{ ...job }} />
      </div>
    </div>
  )
}

General.propTypes = {}
