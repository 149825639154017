import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { JobFormSection } from './JobFormSection'
import { CandidatesFilterFormSection } from 'features/candidate/components/FormSections'

export const FilterForm = props => {
  const { t } = useTranslation()

  const mapCandidateFieldName = name => `receiver_profile.${name}`

  return (
    <>
      <form>
        <JobFormSection />

        <CandidatesFilterFormSection mapFieldName={mapCandidateFieldName} />
      </form>
    </>
  )
}

FilterForm.propTypes = {}
