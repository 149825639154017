import { takeLatest, takeEvery, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { usersAPI } from 'features/users/api'
import { jobCandidatesList } from './slice'
import { RequestUtils } from 'utils'
import { authLogoutPA } from 'features/auth/store'

function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      for_job: params?.for_job,
      includes: ['studentProfile', 'avatar', 'receivedJobInvite'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(usersAPI.getStudents, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        jobCandidatesList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(jobCandidatesList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobCandidatesList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(jobCandidatesList.getList, getList)
}

function* cleanState() {
  yield put(jobCandidatesList.cleanState({}))
}

function* watchUserLogout() {
  yield takeEvery(authLogoutPA.success, cleanState)
}

export function* listSaga() {
  yield all([fork(watchGetList), fork(watchUserLogout)])
}
