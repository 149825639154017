import { combineReducers } from 'redux'

import data from './data/slice'
import mentors from './mentors/reducers'

const mentorReducer = combineReducers({
  data,
  mentors,
})

export default mentorReducer
