import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { JobCreate as JobCreateContainer } from 'features/job/components/Job'

export const JobCreate = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'post_job'} />

      <PageContent>
        <PageHeader back />

        <JobCreateContainer />
      </PageContent>
    </>
  )
}

JobCreate.propTypes = {}
