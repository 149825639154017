import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Alert, Button } from 'components/Elements'
import { FormFieldController, PasswordInputFiled } from 'components/FormElements'
import { ValidationsUtils } from 'utils'

const passwordMinLength = 8

export const ResetPasswordForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required('field.error.required')
          .min(passwordMinLength, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(passwordMinLength, 'field.error.password.length'),
      })
    ),
  })

  const [alertError, setAlertError] = useState(null)

  const onSubmit = async data => {
    setAlertError(null)
    try {
      await props.onSubmit(data)
    } catch (error) {
      setAlertError(error)
      ValidationsUtils.setServerSideErrors(error?.errors, setError)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {alertError && (
          <Alert type={'error'} mb={'sm'}>
            {alertError?.message || t('error')}
          </Alert>
        )}

        <FormFieldController
          name={'password'}
          control={control}
          component={PasswordInputFiled}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          translateParams={{ count: passwordMinLength }}
        />

        <FormFieldController
          name={'password_confirmation'}
          control={control}
          component={PasswordInputFiled}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          translateParams={{ count: passwordMinLength }}
        />

        <div className={'text-center mt-4'}>
          <Button type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
            {t('reset')}
          </Button>
        </div>
      </form>
    </>
  )
}
