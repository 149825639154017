import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'

import s from './Card.module.scss'
import { TitleBlock } from './TitleBlock'

export const GeneralEmployerCard = ({ data, className = 'mb-3', ...props }) => {
  const { t } = useTranslation()

  return (
    <Card
      className={classNames(s.root, {
        [className]: className,
      })}
    >
      <CardBody>
        <div className={s.topBar}>
          <div className={s.titleBlock}>
            <TitleBlock data={data} />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

GeneralEmployerCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}
